import { configureNotificationTypes } from '../reduxExports';

const configureNotificationInitialState: ConfigureNotificationState = {
  configureNotification: null,
  configureNotificationCount: 0,
  configureNotificationListRequestHash: null,
  configureNotificationListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'ascending',
    sortBy: 'name',
    searchTerm: ''
  },
  configureNotificationFiltersAppliedFromRedirection: false,
  configureNotificationTableLoader: false,
  individualAlarmDetails: null,

  configureNotificationHistory: null,
  configureNotificationHistoryCount: 0,
  configureNotificationHistoryListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'descending',
    sortBy: 'fireTime',
    searchTerm: ''
  },
  configureNotificationHistoryListRequestHash: null,
  configureNotificationHistoryTimeline: null,
  getLatestConfigurationOnTop: false
};

const configureNotificationReducer = (
  state: ConfigureNotificationState = configureNotificationInitialState,
  action: ConfigureNotificationAction
): ConfigureNotificationState => {
  switch (action.type) {
    case configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS:
      return {
        ...state,
        configureNotificationCount: action.configureNotificationCount,
        configureNotification: action.configureNotification,
      }
    case configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATIONS_HISTORY:
      return {
        ...state,
        configureNotificationHistoryCount: action.configureNotificationHistoryCount,
        configureNotificationHistory: action.configureNotificationHistory,
      }
    case configureNotificationTypes.SET_NOTIFICATION_LIST_REQUEST_HASH:
      return {
        ...state,
        configureNotificationListRequestHash: action.configureNotificationListRequestHash
      }
    case configureNotificationTypes.SET_NOTIFICATION_HISTORY_LIST_REQUEST_HASH:
      return {
        ...state,
        configureNotificationHistoryListRequestHash: action.configureNotificationHistoryListRequestHash
      }
    case configureNotificationTypes.SET_RULE_STATUS:
      return {
        ...state,
      }
    case configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LIST_TABLE_PROPS:
      return {
        ...state,
        configureNotificationListTableProps: action.configureNotificationListTableProps
      }
    case configureNotificationTypes.CREATE_NEW_RULE:
      return {
        ...state,
      }
    case configureNotificationTypes.EDIT_NEW_RULE:
      return {
        ...state,
      }
    case configureNotificationTypes.FETCH_RULE_FOR_EDIT:
      return {
        ...state,
      }
    case configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_LOADER:
      return {
        ...state,
        configureNotificationTableLoader: action.configureNotificationTableLoader
      }
    case configureNotificationTypes.SET_CONFIGURE_NOTIFICATION_STATUS_LOADER:
      return {
        ...state,
        configureNotificationTableLoader: action.configureNotificationTableLoader
      }
    case configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA:
      return {
        ...state,
        configureNotificationListTableProps: {
          view: 'list',
          pageSize: state.configureNotificationListTableProps?.pageSize || 15,
          pageNumber: state.configureNotificationListTableProps?.pageNumber || 1,
          order: state.configureNotificationListTableProps?.order || 'ascending',
          sortBy: state.configureNotificationListTableProps?.sortBy || 'name',
          searchTerm: ''
        },
        configureNotification : null
      }
    case configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_HISTORY_LIST_DATA:
      return{
        ...state,
        configureNotificationHistoryListTableProps:{
          view: 'list',
          pageSize: state.configureNotificationHistoryListTableProps?.pageSize || 15,
          pageNumber: state.configureNotificationHistoryListTableProps?.pageNumber || 1,
          order: state.configureNotificationHistoryListTableProps?.order || 'descending',
          sortBy: state.configureNotificationHistoryListTableProps?.sortBy || 'fireTime',
          searchTerm: ''
        },
        configureNotificationHistory : null

      }
    case configureNotificationTypes.FETCH_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE:
      return {
        ...state,
        configureNotificationHistoryTimeline: action.configureNotificationHistoryTimeline
      }
    case configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE:
      return {
        ...state,
        configureNotificationHistoryTimeline: null,
        configureNotificationHistory : null
      }
    case configureNotificationTypes.SET_LATEST_CONFIGURATION_ON_TOP:
      return {
        ...state,
        getLatestConfigurationOnTop: action.getLatestConfigurationOnTop,
     }
    default:
      return state;
  }
};

export default configureNotificationReducer;
