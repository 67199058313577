/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import { IconButton, } from '@mui/material';
import { Handle, Position } from '@xyflow/react';
import ExecutionPlan from './ExecutionPlan';
// import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import './ParameterNode.css'
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import { SidePopover } from '../../../globalUtils/globalExports';
import ScheduleExecutionSidePopover from './ScheduleExecutionSidePopover';
import { ScheduleExecution } from '../../../rmsReduxStore/ruleEngineRedux/ruleEngineType';
import { cloneDeep } from 'lodash';
import { formatDateForHistory } from '../../../globalUtils/globalHooks';
import './ScheduleTimeNode.css'
// import { Dayjs } from 'dayjs';

interface ScheduleTimeNodeProps {
  data: {
    type: 'placeholder' | 'parameter' | 'other';
    label?: string;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    delete:Function
  };
}

// interface ScheduleExecutionPopOverData {
//   executionLevel: null | string;
//   oneTimeSelectedDate?: Date | null;
//   oneTimeSelectedTime?: null | Dayjs;
//   recurringSelectedTime?: null | Dayjs,
//   // recurringRepeatNumber?: null | string,
//   // recurringRepeatInterval?: null | string,
//   recurringSelectedDays?: null | string[];
//   recurringSelectedRangeOption?: null | string;
//   recurringSelectedRangeOptionRecurrenceNumber?: null | number;
//   recurringSelectedRangeDate?: null | Date;

// }

const ScheduleTimeNode: React.FC<ScheduleTimeNodeProps> = (props) => {

  const [isScheduleTimePopOverOpen, setIsScheduleTimePopOverOpen] = useState(false);

  const handleEdit =() => {
    setIsScheduleTimePopOverOpen(true);
  }
  const handleCloseScheduleTimePopOver = () =>{
    setIsScheduleTimePopOverOpen(false);
  }
  const [scheduleTimeData,setScheduleTimeData] = useState<ScheduleExecution>({
    scheduleType: '',
    dateTime: null,
    recurrenceDays: null,
    recurrenceRange: null ,
    recurrenceCount: null ,
    endDate: null ,

  });

  useEffect(()=>{
    const data = cloneDeep(props?.data?.executionPlan.trigger);
    const currentScheduleTimeData = cloneDeep(scheduleTimeData);
    // if(data && currentScheduleTimeData){
      currentScheduleTimeData.scheduleType = data.scheduleType??'';
      currentScheduleTimeData.dateTime = data.dateTime??null;
      currentScheduleTimeData.recurrenceDays = data.recurrenceDays;
      currentScheduleTimeData.recurrenceRange = data.recurrenceRange;
      currentScheduleTimeData.recurrenceCount = data.recurrenceCount;
      currentScheduleTimeData.endDate = data.endDate;
    // }
    setScheduleTimeData(currentScheduleTimeData);

  },[props?.data?.executionPlan.trigger])

  const getRecurrenceSelectedTime = (time) => {
    const givenRecurrenceDate = new Date(time);
    if(givenRecurrenceDate && time){
      return new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }).format(givenRecurrenceDate);
    }
  }

  const order = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const  sortWeekdays = (unsortedWeekdays: string[]) =>  {
    return unsortedWeekdays.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  }

  // const autoPopulateScheduledExecution = (data : ScheduleExecution) => {
    //     setScheduleExecutionFormData({
    //       ...scheduleExecutionFormData,
    //       executionLevel: data.type,
    //       oneTimeSelectedDate: data.type === 'OneTime' ? new Date(data.dateTime??'') : null,
    //       oneTimeSelectedTime: data.type === 'OneTime' ? dayjs(data.dateTime) : null,
    //       recurringSelectedTime: data.type === 'Recurring' ?  dayjs(data.dateTime) : null,
    //       recurringSelectedRangeOptionRecurrenceNumber: data.type === 'Recurring'  && data.recurrenceCount!== null ? data.recurrenceCount : null,
    //       recurringSelectedDays: data.type === 'Recurring' ? data.recurrenceDays : null,
    //       recurringSelectedRangeDate: data.type === 'Recurring' && data.endDate!== null ? new Date(data.endDate?? '') : null
    //     })
    //   }

  // const handleUpdateScheduleExecutionData = ((data: ScheduleExecutionPopOverData) => {
  //   setScheduleExecutionFormData(data);
  // });

  // const [scheduleExecutionFormData, setScheduleExecutionFormData] = useState<ScheduleExecutionPopOverData>({
  //   executionLevel: null,
  //   oneTimeSelectedDate: null,
  //   oneTimeSelectedTime: null,
  //   recurringSelectedTime: null,
  //   // recurringRepeatNumber: null,
  //   // recurringRepeatInterval: null,
  //   recurringSelectedDays: [],
  //   recurringSelectedRangeOption: null,
  //   recurringSelectedRangeOptionRecurrenceNumber: null,
  //   recurringSelectedRangeDate: null
  // });

    return (
      <>
    <Box
      sx={{
        minWidth: 300,
        height:160,
        border: '1px solid #00BFBF', // border color
        borderRadius: '12px',
        padding: 2,
        backgroundColor: '#fff',
        borderLeft:'7px solid #00BFBF '
      }}>
          <Handle type="source" position={Position.Bottom} className='transparent-handle'
            // style={{ left: '20%', transform: 'translateX(-50%)' }} 
 />
    <Handle type="source" position={Position.Left} className='transparent-handle' id='left'  // Adjust left as needed
 />
      {/* Title with icon */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <IconWithTooltip iconClassName={'define_execution__bolt_black__icon'} /> 
       <Typography sx={{ fontWeight: '500', fontSize: '15px',marginBottom:'5px' }}>Trigger</Typography>
      </Box>

    
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography sx={{ fontSize: 14, mr: 1 }}>in</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '4px 8px',
            backgroundColor: '#f9f9f9',
                flex: 1,
            gap:'8px'
          }}
        >
        <IconWithTooltip iconClassName={'scheduleTime-sidebar'} />
    
        <Typography sx={{ fontSize: 14 }}>Schedule Time</Typography>
        </Box>
        {/* <IconButton
        size="small"
        onClick={()=>{}}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}
      >
          <CloseIcon
            onClick={()=> props?.data?.delete()}
            fontSize="small"
            sx={{ color: '#666' }} />
        </IconButton> */}
          {/* {
            props?.data?.executionPlan && props?.data?.executionPlan.trigger && props?.data?.executionPlan.trigger  && props?.data?.executionPlan.trigger.type === 'Alarm' && 
            <div className='rule__engine__if__then__else__node'>
               <div><span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan.trigger.name ?? ''}</span></div>
               &nbsp;<span >{props?.data?.executionPlan.trigger.parameterCondition}</span>&nbsp;
               <span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan.trigger.parameterValue[0]}</span>
             </div>
          } */}

      
      </Box>
      <div style={{display:'flex',gap:'6px'}}>
            <IconWithTooltip iconClassName={'rule__engine__form__schedule__execution__icon'} />
         <Typography sx={{ fontWeight: '500', fontSize: '14px',marginBottom:'5px' }}>When</Typography>
    </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '35px', backgroundColor: '#EEFFFF',justifyContent:'space-between' }}>    
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props?.data?.executionPlan ? (
            props?.data?.executionPlan.trigger.type === 'Scheduler' && scheduleTimeData && (
              <>
            <div className='schedule__time__node_content__wrapper'>
              <div className='schedule__time__node_content__wrapper'>
                <div className='schedule__time__node__subHeading__label'> {scheduleTimeData.scheduleType === 'OneTime' ? 'One Time:' : 'Recurring :'}</div>
                <div className='schedule__time__node__subHeading__label__value'>{scheduleTimeData.scheduleType === 'OneTime' ? formatDateForHistory(scheduleTimeData.dateTime) : scheduleTimeData.dateTime === null ? '' : `At ${scheduleTimeData.dateTime && getRecurrenceSelectedTime(scheduleTimeData.dateTime.toLocaleString())}`}</div>
              </div>
                {scheduleTimeData?.scheduleType === 'Recurring' && scheduleTimeData.recurrenceDays && <div className='schedule__time__node_content__wrapper'>
                <div className='schedule__time__node__subHeading__label'>On :</div><div className='schedule__time__node__subHeading__label__value'>{scheduleTimeData.recurrenceDays && sortWeekdays(scheduleTimeData.recurrenceDays).join(',')}</div>
                </div>}
            </div>
            </>
            )
          ) : (
            <span style={{ fontStyle: 'italic', color: 'gray' }}>(Add Schedule)</span>
          )}
        </div>
       <IconButton size="small" onClick={handleEdit}>
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
       </IconButton>
      </div>
      
    </Box>
    {isScheduleTimePopOverOpen && <SidePopover
          id='schedule__time__execution__plan__side__popover'
          open={isScheduleTimePopOverOpen}
          headerText={'Schedule Time'}
          containerClassName='configure__notification__detail__popover'
          handleClose={()=>{}}
          popperClassName='trigger__point__side__popover'
          headerClassName={'rule__engine__header__config'}
          horizontalLinePopover={true}
          isRuleEnginePopOver={true}
          headingIconClassName={'scheduleTime-sidebar'}
          ruleEngineSidePopoverOnclose={handleCloseScheduleTimePopOver}
          // eslint-disable-next-line react/no-children-prop
          children={
            <ScheduleExecutionSidePopover
                // handleUpdateScheduleExecutionData={handleUpdateScheduleExecutionData}
              // setScheduleExecutionSidePopoverData={setScheduleExecutionFormData}
                handleClose={handleCloseScheduleTimePopOver}
                scheduleTimeData={scheduleTimeData}
                // scheduleExecutionFormData = {scheduleExecutionFormData}
                executionPlan={props?.data?.executionPlan}
                handleUpdateExecutionPlan = {props?.data?.handleUpdateExecutionPlan}

        />
          } 
          />
        }
    
      </>
      
    );
};

export default ScheduleTimeNode;
