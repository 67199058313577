/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import { IconButton, } from '@mui/material';
import { Handle, Position } from '@xyflow/react';
import ExecutionPlan from './ExecutionPlan';
import EditIcon from '@mui/icons-material/Edit';
import './ParameterNode.css'
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import { SidePopover } from '../../../globalUtils/globalExports';
import TriggerPointSidePopover from './TriggerPointSidePopover';

interface ParameterNodeProps {
  data: {
    type: 'placeholder' | 'parameter' | 'other';
    label?: string;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    delete:Function
  };
}

const ParameterNode: React.FC<ParameterNodeProps> = (props) => {

  const [isTriggerParameterPopoverOpen, setIsTriggerParameterPopoverOpen] = useState(false);

  const handleEdit =() => {
    setIsTriggerParameterPopoverOpen(true);
  }
  const handleCloseTriggerParameterPopover = () =>{
    setIsTriggerParameterPopoverOpen(false);
  }

    return (
      <>
    <Box
      sx={{
        minWidth: 300,
        height:160,
        border: '1px solid #00BFBF',
        borderRadius: '12px',
        padding: 2,
        backgroundColor: '#fff',
        borderLeft:'7px solid #00BFBF '
      }}>
          <Handle type="source" position={Position.Bottom} className='transparent-handle'
          
 />
    <Handle type="source" position={Position.Left} className='transparent-handle' id='left' 
 />
      {/* Title with icon */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <IconWithTooltip iconClassName={'define_execution__bolt_black__icon'} /> 
       <Typography sx={{ fontWeight: '500', fontSize: '15px',marginBottom:'5px' }}>Trigger</Typography>
      </Box>

    
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography sx={{ fontSize: 14, mr: 1 }}>in</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '4px 8px',
            backgroundColor: '#f9f9f9',
           flex: 1,
            gap: '8px',
    
          }}
        >
        <IconWithTooltip iconClassName={'trigger-parameters-sidebar'} />
        <Typography sx={{ fontSize: 14,marginBottom:'1px' }}>Parameters</Typography>
        </Box>
        {/* <IconButton
        size="small"
        onClick={()=>{}}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}
      >
          <CloseIcon
            onClick={()=> props?.data?.delete()}
            fontSize="small"
            sx={{ color: '#666' }} />
        </IconButton> */}
  

      
      </Box>
      <div style={{display:'flex',gap:'6px'}}>
            <IconWithTooltip iconClassName={'rule__engine__form__schedule__execution__icon'} />
            <Typography sx={{ fontWeight: '500', fontSize: '14px',marginBottom:'5px' }}>When</Typography>
          </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '35px', backgroundColor: '#EEFFFF',justifyContent:'space-between' }}>    
      <div style={{display:'flex',alignItems:'center'}}>
        {  props?.data?.executionPlan && props?.data?.executionPlan.trigger &&  props?.data?.executionPlan.trigger.type === 'Parameter' ?
        <div className='rule__engine__if__then__else__node'>
          <div><span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan.trigger.name ?? ''}</span></div>
          &nbsp;<span style={{fontSize:'13px'}}>{props?.data?.executionPlan.trigger.parameterConditionName}</span>&nbsp;
          <span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan?.trigger?.parameterValue &&  props?.data?.executionPlan?.trigger?.parameterValue[0]}</span>
          <span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan?.trigger?.unit !== null ? props?.data?.executionPlan.trigger.unit  : ''}</span>
          &nbsp;<span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan.trigger.parameterConditionName === 'Between' || props?.data?.executionPlan.trigger.parameterConditionName === 'Not between' ? 'to' : null }</span>&nbsp;
          <span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan.trigger.parameterValue && props?.data?.executionPlan.trigger.parameterValue.length > 1  && props?.data?.executionPlan?.trigger?.parameterValue[1] }</span>
          <span className='rule__engine__define__execution__selected__parameters'>{props?.data?.executionPlan?.trigger?.parameterValue && props?.data?.executionPlan?.trigger?.parameterValue?.length > 1 &&  props?.data?.executionPlan.trigger.parameterValue[1] &&  props?.data?.executionPlan.trigger.unit !== null ? props?.data?.executionPlan.trigger.unit + ' ' : ''}</span>
          </div> : 
          
          <div style={{fontSize:'12px'}}>
          ((Parameter),(Operator),(Value))
        </div>
        }
       </div>
            
      {/* EDIT ICON */}
      {!props?.data?.executionPlan?.trigger.parameterId && <IconButton
        size="small"
        onClick={handleEdit}
      >
        <EditIcon fontSize="small" sx={{ color: '#666' }} />
       </IconButton>}
      </div>
      
    </Box>
    {isTriggerParameterPopoverOpen && <SidePopover
          id='trigger__parameter__when__side__popover'
          open={isTriggerParameterPopoverOpen}
          headerText={'When'}
          headerSecondaryText='Trigger Condition'
          containerClassName='configure__notification__detail__popover'
          handleClose={()=>{}}
          popperClassName='trigger__point__side__popover'
          headerClassName={'rule__engine__header__config'}
          horizontalLinePopover={true}
          isRuleEnginePopOver={true}
          headingIconClassName={'rule__engine__form__schedule__execution__icon'}
          ruleEngineSidePopoverOnclose={handleCloseTriggerParameterPopover}
          // eslint-disable-next-line react/no-children-prop
          children={
                <TriggerPointSidePopover 
                    handleClose={handleCloseTriggerParameterPopover} 
                    executionPlan={props?.data?.executionPlan}
                    handleUpdateExecutionPlan={props?.data?.handleUpdateExecutionPlan}
                    parameterOrAlarm={'Parameter'}
                />
          } 
          />
        }
    
      </>
      
    );
};

export default ParameterNode;
