/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import './NotificationActionStatusPopover.css'
import { CustomButtonGroup, } from '../../globalUtils/globalExports';
import { Autocomplete, Box, Checkbox, Chip, FormControlLabel, TextField, Typography } from '@mui/material';
// import countryList from 'react-select-country-list'
import './NotificationActionStatusSidePopover.css'
// import ccl from 'country-codes-list';
import { CountryCallingCode, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { cloneDeep, isEqual } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { allowedCountries, getCurrentCountryCode } from '../../globalUtils/globalHooks';
import 'react-phone-input-2/lib/material.css'
import { SmsRecipientInterface } from './CreateRule';

interface NotificationActionStatusProps {
    handleClose: Function;
    showSms : boolean;
    handleSetShowSms: Function;
    showEmail: boolean;
    handleSetShowEmail :Function;
    smsRecipients : SmsRecipientInterface[];
    handleSmsRecipientsChange : Function;
    emailRecipients : string[];
    handleEmailRecipientsChange : Function;
}

interface NotificationAction {
  smsSelected:  boolean;
  emailSelected:  boolean;
  smsRecipients :   SmsRecipientInterface[];
  emailRecipients:   string[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationActionStatusSidePopover = (function NotificationActionStatusSidePopover({handleClose,showSms,handleSetShowSms,showEmail,handleSetShowEmail,smsRecipients,handleSmsRecipientsChange,emailRecipients,handleEmailRecipientsChange} : NotificationActionStatusProps){

    const [error,setErrorEmail] = useState<string | null>(null)
    const [phoneNumber, setPhoneNumber] = useState<string|null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [countryCode,setCountryCode] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [touched, setTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const [popOverData,setPopOverData] = useState<NotificationAction|null>({
      smsSelected : false,
      emailSelected: false,
      smsRecipients : [],
      emailRecipients: []
    });
    const [initialPopoverData,setInitialPopoverData] = useState<NotificationAction|null>({
      smsSelected : false,
      emailSelected: false,
      smsRecipients : [],
      emailRecipients: []
    });
  

    useEffect(()=>{
      setPopOverData({
        smsSelected: showSms,
        emailSelected: showEmail,
        smsRecipients: smsRecipients,
        emailRecipients: emailRecipients
      })
      setInitialPopoverData({
        smsSelected: showSms,
        emailSelected: showEmail,
        smsRecipients: smsRecipients,
        emailRecipients: emailRecipients
      })

    },[])

    const isValidEmail = (email: string): boolean => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
      return emailPattern.test(email);
    };

    const handleSmsChange = () => {
        const data = cloneDeep(popOverData);
        if(data){
          data.smsSelected = !data.smsSelected
          setPopOverData(data);
        }

    }
    const handleEmailChange = () => {
        const data  =cloneDeep(popOverData)
        if(data){
          data.emailSelected = !data.emailSelected
          setPopOverData(data);
        }
    }

    const disableButton = useCallback(() => {
      if(isEqual(popOverData,initialPopoverData)){
          return true;
      }else{
        let result = true;
        if(popOverData && popOverData?.smsSelected){
          result = result && popOverData?.smsRecipients && popOverData?.smsRecipients?.length > 0;
        }
        if(popOverData && popOverData?.emailSelected){
          result = result && popOverData?.emailRecipients && popOverData?.emailRecipients?.length > 0;
        }
        return !result;
      }
    },[popOverData])

    const btnsList = useMemo(() => {
        return [
            {
                buttonText: 'Save',
                buttonId: 'add',
                btnClassName: disableButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: (): void => {
                  const data = cloneDeep(popOverData);
                  handleSetShowSms(data?.smsSelected);
                  handleSetShowEmail(data?.emailSelected);
                  handleSmsRecipientsChange(data?.smsRecipients);
                  handleEmailRecipientsChange(data?.emailRecipients);
                  handleClose();

                },
                isDisabled: disableButton(),
                buttonVariant: 'filled',
            },
            {
                buttonText: 'Cancel',
                buttonId: 'cancel',
                btnClassName: 'secondary__btn',
                handleClick: (): void => {
                    handleClose()
                },
                isDisabled: false,
                buttonVariant: 'outlined',
            },
        ];
    }, [handleSetShowSms,handleSetShowEmail,handleSmsRecipientsChange,handleEmailRecipientsChange,popOverData]);

    const handleAddValueEmail = (newValue) => {

      const trimmedValue = newValue.trim();
      if (!trimmedValue) return;

      if(trimmedValue=== undefined){
        setErrorEmail(null);
        return;
      }

      if (!isValidEmail(trimmedValue)) {
        setErrorEmail('Please enter a valid email address');
        return;
      }

      const data = cloneDeep(popOverData);
      if (!data?.emailRecipients?.includes(trimmedValue)) {
        if(data && data?.emailRecipients ){
          data.emailRecipients = [...data?.emailRecipients??[],trimmedValue]
          setPopOverData(data);
        }
      } else {
        setErrorEmail(`The email "${trimmedValue}"  already added.`);
      }
    };
    
    const handleChangeEnteredEmail = (event: React.SyntheticEvent, newValue: string[]) => {
      const lastValue = newValue?.slice(-1)?.[0]
      if(lastValue=== undefined){
        setErrorEmail(null);
        return;
      }

      if (!isValidEmail(lastValue)) {
        setErrorEmail('Please enter a valid email address');
        return;
      }
      const uniqueValues = Array.from(new Set(newValue.map((item) => item.trim())));
      const data = cloneDeep(popOverData);
      if(data && data?.emailRecipients){
        data.emailRecipients = uniqueValues;
        setPopOverData(data);
      }
      setErrorEmail(null); 
    };

  
    const handleDeleteSmsChips = (index: number) => {
      const data = cloneDeep(popOverData);
      if(data && data?.smsRecipients){
        data.smsRecipients = data?.smsRecipients && data?.smsRecipients.filter((_, i) => i !== index)
        setPopOverData(data);
      }
    };
    const handleDeleteEmailChips = (index: number) => {
      const data = cloneDeep(popOverData);
      if(data && data?.emailRecipients){
        data.emailRecipients = data?.emailRecipients && data?.emailRecipients.filter((_, i) => i !== index)
        setPopOverData(data);
      }
      setErrorEmail(null);
    };

    const onMobileNumberChange = useCallback((value: string,data:{countryCode:CountryCode,dialCode:CountryCallingCode}): void => {

      setPhoneNumber(value);
      setCountryCode(data?.dialCode);
      if (errorMessage && errorMessage?.length > 0) {
        setErrorMessage('');
      }
      setIsValidPhone(isValidPhoneNumber(`+${value}`, data.countryCode));
      if(!isValidPhoneNumber(`+${value}`, data.countryCode) && value?.slice(data?.dialCode?.length)?.length > 0) setErrorMessage('Please enter a valid mobile number.')
      setTouched(true);
    },[errorMessage])

    return (
        <>
        <div className='notification__action__side__popover__constant'>(Enter recipient's Phone number/Email Id and press enter button)</div>
        <div className='notification__action__side__popover__wrapper'>
            <FormControlLabel
              control={
              <Checkbox
                disabled={false}
                checked={popOverData?.smsSelected??false}
                onChange={handleSmsChange}
                sx={{
                  transform: 'scale(0.8)', 
                  color: 'rgba(0, 0, 0, 0.3)', 
                  '&.Mui-checked': {
                    color: '#00BFBF',
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.3)',
                  },
                }}
              />
            }
            label={<Typography sx={{ fontSize: '12px', color: 'black' }}>SMS</Typography>} 
              />
              {/* {
                popOverData?.smsSelected && <div className='notification__status__action__country__and__sms__wrapper'>
                  <div className='notification__action__row__label'>Country Code*</div>
                  <SelectDropDown 
                    selectDropDownId={'notification__action__country__code__sms__dropdown'}
                    handleSelectOptionChange={(val)=>{
                      setSelectedCountryCode(val?.countryCode);
                      setSelectedCountryPhoneCode(val?.id);
                    }} 
                    selectValue={{id: '+91',label: '+91'}}
                    isDisabled={true}
                    selectOptions={countryCodes.map((countryCode)=>{
                      return{
                        id: countryCode.countryCallingCode,
                        label: `+${countryCode.countryCallingCode}`,
                        ...countryCode
                      }
                    }) ?? []}/>
                    
                    <div className='notification__status__action__email__wrapper'>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={popOverData?.smsRecipients??[]}
                      onChange={handleChange}
                      options={[]}
                      renderTags={() => null} 
                      autoFocus={false}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='standard'
                          placeholder="Enter recipient number and press enter"
                          error={!!errorSms}
                          helperText={errorSms}
                          style={{
                            fontSize: '14px',
                            color: '#002126'
                          }}
                          onChange={(e) => {
                            const currentValue = e.target.value.trim();
                            if (errorSms && currentValue) {
                              setErrorSms(null);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddValue(params.inputProps.value);
                            }
                            if (e.key === 'Backspace' || e.key === 'Delete') {
                              e.stopPropagation()
                            }
                          }}
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'none', 
                          },
                          '& .Mui-focused fieldset': {
                            borderColor: 'none',
                            boxShadow: 'none',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#000', 
                        },
                        '& .Mui-expandec filedset':{
                          border: 'none',
                          outlineColor: 'none',
                          outline: 'none'
                        }
                      }}
                    />
                    <div className='notification__action__status__entered__email__wrapper'>
                        {popOverData?.smsRecipients && popOverData?.smsRecipients.map((sms, index) => (
                          <Chip
                            key={index}
                            label={sms}
                            onDelete={() => handleDeleteSmsChips(index)}
                            className='notification__action__recipients__chips'
                          />
                        ))}
                      </div>
                    </div>
                </div>
              } */}
               {popOverData?.smsSelected &&  <div className='notification__action__input__phone__wrapper'>
                  <PhoneInput
                      specialLabel={''}
                      country={getCurrentCountryCode()}
                      value={phoneNumber}
                      onlyCountries={allowedCountries}
                      onChange={onMobileNumberChange}
                      placeholder='Enter recipient number and press enter'
                      autoFormat={true}
                      enableSearch={true}
                      countryCodeEditable={false}
                      searchNotFound='No countries to show.'
                      searchPlaceholder='Search'
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                        placeholder: 'Enter recipient number and press enter',
                      }}
                      onEnterKeyPress={() => {
                        if (isValidPhone) {
                            const phone = phoneNumber && phoneNumber.slice(countryCode?.length)
                            const data = cloneDeep(popOverData);
                            const result = data?.smsRecipients?.some((item) => item.phoneNumber === phone) || false;
                            if(phone && result){
                              setErrorMessage(`The number "${phone}" is already added.`)
                            }
                            else{
                              phone && data?.smsRecipients.push({
                                countryCode: `+${phoneNumber.slice(0,countryCode?.length)}`,
                                phoneNumber: phone
                              });
                              setPopOverData(data);
                            }
                            setPhoneNumber(countryCode);
                        }
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      isValid={(value, country: any) =>
                        touched
                          ? isValidPhoneNumber(`+${value}`, country?.iso2)
                          : true
                      }
                    />  
                     
                {errorMessage && errorMessage?.length > 0 && <div className="rms__login__form__error__message">{errorMessage}</div>}

              <div className='notification__action__status__entered__email__wrapper'>
                        {popOverData?.smsRecipients && popOverData?.smsRecipients.map((sms, index) => (
                          <Chip
                            key={index}
                            label={sms?.countryCode+sms?.phoneNumber}
                            onDelete={() => handleDeleteSmsChips(index)}
                            className='notification__action__recipients__chips'
                          />
                        ))}
                      </div>

                </div> }


            <FormControlLabel
            control={
              <Checkbox
                disabled={false}
                checked={popOverData?.emailSelected??false}
                onChange={handleEmailChange}
                sx={{
                  transform: 'scale(0.8)', 
                  color: 'rgba(0, 0, 0, 0.3)',
                  '&.Mui-checked': {
                    color: '#00BFBF', 
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.3)', 
                  },
                }}
              />
            }
            label={<Typography sx={{ fontSize: '12px', color: 'black' }}>Email</Typography>} 
          />
          {
                popOverData?.emailSelected && <div className='notification__status__action__email__wrapper'>        
                   <Box sx={{ width: '100%' }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={popOverData?.emailRecipients??[]}
                      onChange={handleChangeEnteredEmail}
                      options={[]}
                      renderTags={() => null} 
                      autoFocus={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          placeholder='Type an email and press enter'
                          error={!!error}
                          helperText={error}
                          style={{
                            fontSize: '14px',
                            color: '#002126'
                          }}
                          onChange={(e) => {
                            const currentValue = e.target.value.trim();
                            if (error && currentValue) {
                              setErrorEmail(null);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddValueEmail(params.inputProps.value);
                            }
                            if (e.key === 'Backspace' || e.key === 'Delete') {
                              e.stopPropagation()
                            }
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'none', 
                              },
                              '& .Mui-focused fieldset': {
                                borderColor: 'none',
                                boxShadow: 'none',
                              },
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#000', 
                            },
                            '& .Mui-expandec filedset':{
                              border: 'none',
                              outlineColor: 'none',
                              outline: 'none'
                            }
                          }}
                        />
                      )}
                    />

                    <div className='notification__action__status__entered__email__wrapper'>
                        {popOverData?.emailRecipients && popOverData?.emailRecipients?.map((email, index) => (
                          <Chip
                            key={index}
                            label={email}
                            onDelete={() => handleDeleteEmailChips(index)}
                            className='notification__action__recipients__chips'
                          />
                        ))}
                      </div>
                  </Box>


                </div>
              }

            </div>


        <div className='rule__engine__if__then__else__side__popover__footer'>
                <CustomButtonGroup
                    buttonsList={btnsList}
                    buttonGroupClassName='button__group__footer' />
            </div>
        </>
    )
})

export default NotificationActionStatusSidePopover