/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './SendNotificationSidePopOver.css'
import { CustomButtonGroup, SelectDropDown, useAppDispatch, useAppSelector } from '../../../globalUtils/globalExports';
import { fetchAlarmTypeOfNotification, fetchRecipientListReferenceData, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { cloneDeep, isEqual } from 'lodash';
import ExecutionPlan, { SendNotificationAction } from './ExecutionPlan';


interface SendNotificationSidePopOverprops {
    sendNotification: null | SendNotificationAction;
    handleClose: () => void;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    ifOrElse: String;
}

const SendNotificationSidePopOver = memo(function SendNotificationSidePopOver({

    sendNotification,
    handleClose,
    executionPlan,
    handleUpdateExecutionPlan,
    ifOrElse
}: SendNotificationSidePopOverprops) {
    const dispatch = useAppDispatch();
    const { typeOfNotificationReferenceData, ruleEngineActionControlSendNotificationReferenceData } = useAppSelector(getReferenceDataReducer);
    const [popOverData, setPopOverData] = useState<SendNotificationAction>({
        type: '',
        notificationType: [],
        recipients: [],
        emailContent: '',
        recipientsName: []
    });
    const [initialPopOverData, setInitialPopOverData] = useState<SendNotificationAction>({
        type: '',
        notificationType: [],
        recipients: [],
        emailContent: '',
        recipientsName: []
    })
    // const [errorMessageNotificationMode,setErrorMessageNotificationMode] = useState<string>('');
    // const [errorMessageRecipient, setErrorMessageRecipient] = useState<string>('');
    const orgId = JSON.parse(localStorage.getItem('rms-user') || '{}').orgId
    useEffect(() => {
        dispatch(fetchAlarmTypeOfNotification(false))
        dispatch(fetchRecipientListReferenceData({ orgId: orgId }))
    }, [])

    
    const autoPopulateRemainingData = (sendNotification:SendNotificationAction) =>{
        const recipientsNames = ruleEngineActionControlSendNotificationReferenceData?.filter((refItem)=> sendNotification.recipients.includes(refItem.id))

    }

    useEffect(() => {
        if (sendNotification) {
            setPopOverData(sendNotification)
            setInitialPopOverData(sendNotification)
            autoPopulateRemainingData(sendNotification)
        }
    }, [sendNotification])


    const disableButton = useCallback(() => {
        if (isEqual(initialPopOverData, popOverData))
            return true;
        else
            return !(popOverData && popOverData.notificationType.length > 0 && popOverData.recipients.length > 0)
    }, [popOverData, setPopOverData])

    const btnsList = useMemo(() => {
        return [
            {
                buttonText: 'Save',
                buttonId: 'add',
                btnClassName: disableButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: (): void => {
                    // const data = cloneDeep(popOverData);
                    const formattedData: SendNotificationAction = {
                        type: 'Send Notification',
                        notificationType: popOverData?.notificationType,
                        recipients: popOverData?.recipients,
                        emailContent: '',
                        recipientsName : popOverData?.recipientsName,
                    }

                    const executionData = cloneDeep(executionPlan);
                    const sendNoificationIndex = executionData?.action?.find((executionDataItem) => executionDataItem.flowType === ifOrElse)?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Send Notification')
                    if (sendNoificationIndex !== undefined && sendNoificationIndex !== -1) {
                        executionData.action[ifOrElse === 'If' ? 0 : 1].list[sendNoificationIndex] = formattedData
                    } else {
                        executionData?.action?.[ifOrElse === 'If' ? 0 : 1].list?.push(formattedData)
                    }
                    handleUpdateExecutionPlan(executionData);
                    handleClose();
                },
                isDisabled: disableButton(),
                buttonVariant: 'filled',
            },
            {
                buttonText: 'Cancel',
                buttonId: 'cancel',
                btnClassName: 'secondary__btn',
                handleClick: (): void => {
                    handleClose()
                },
                isDisabled: false,//disableCancelButton(),
                buttonVariant: 'outlined',
            },
        ];
    }, [popOverData]);


    useEffect(() => {

    }, [popOverData])


    return (
        <>
            <div>
                <hr className="custom-hr" />
                <div className='rule__engine__send__notification__side__popover__wrapper'>
                    <div>
                        <div className='rule__engine__send__notification__side__popover__row__label'>
                            Notification Mode*
                        </div>
                        <div>
                            <SelectDropDown
                                selectDropDownId={'rule__engine__send__notification__mode__dropdown'}
                                handleSelectOptionChange={(val) => {

                                    setPopOverData({
                                        ...popOverData,
                                        notificationType: val?.label
                                    });
                                }}
                                selectOptions={typeOfNotificationReferenceData ?? []}
                                isMultiSelect={false}
                                selectValue={{ id: popOverData?.notificationType, label: popOverData?.notificationType }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='rule__engine__send__notification__side__popover__row__label'>
                            Recipients*
                        </div>
                        <div>
                            <SelectDropDown
                                selectDropDownId={'rule__engine__send__notification__recipient__dropdown'}
                                handleSelectOptionChange={(val) => {
                                    const data = cloneDeep(popOverData);
                                    data.recipients = val?.map((item)=> item.id);
                                    data.recipientsName = val?.map((item)=> item.label);
                                    setPopOverData(data);
                                }}
                                selectOptions={ruleEngineActionControlSendNotificationReferenceData ?? []}
                                isMultiSelect={true}
                                selectValue={ruleEngineActionControlSendNotificationReferenceData?.filter((refData) => popOverData?.recipients?.includes(refData?.id)) ?? null }
                                showMultipleOptionInside={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='rule__engine__send__notification__popover__footer'>
                <CustomButtonGroup
                    buttonsList={btnsList}
                    buttonGroupClassName='button__group__footer' />
            </div>
        </>
    )
})

export default SendNotificationSidePopOver;