export const FETCH_CONFIGURE_NOTIFICATIONS = 'FETCH_CONFIGURE_NOTIFICATIONS';
export const FETCH_CONFIGURE_NOTIFICATIONS_HISTORY = 'FETCH_CONFIGURE_NOTIFICATIONS_HISTORY'
export const SET_NOTIFICATION_LIST_REQUEST_HASH = 'SET_NOTIFICATION_LIST_REQUEST_HASH';
export const SET_RULE_STATUS = 'SET_RULE_STATUS';
export const CREATE_NEW_RULE = 'CREATE_NEW_RULE';
export const EDIT_NEW_RULE = 'EDIT_NEW_RULE';
export const FETCH_RULE_FOR_EDIT = 'FETCH_RULE_FOR_EDIT';
export const SET_CONFIGURE_NOTIFICATION_LIST_TABLE_PROPS = 'SET_CONFIGURE_NOTIFICATION_LIST_TABLE_PROPS';
export const SET_CONFIGURE_NOTIFICATION_LOADER = 'SET_CONFIGURE_NOTIFICATION_LOADER';
export const SET_CONFIGURE_NOTIFICATION_STATUS_LOADER = 'SET_CONFIGURE_NOTIFICATION_STATUS_LOADER';
export const CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA = 'CLEAR_CONFIGURE_NOTIFICATION_LIST_DATA';
export const CLEAR_CONFIGURE_NOTIFICATION_HISTORY_LIST_DATA = 'CLEAR_CONFIGURE_NOTIFICATION_HISTORY_LIST_DATA'
export const SET_NOTIFICATION_HISTORY_LIST_REQUEST_HASH = 'SET_NOTIFICATION_HISTORY_LIST_REQUEST_HASH';
export const SET_CONFIGURE_NOTIFICATION_HISTORY_LIST_TABLE_PROPS = 'SET_CONFIGURE_NOTIFICATION_HISTORY_LIST_TABLE_PROPS';
export const FETCH_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE = 'FETCH_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE';
export const CLEAR_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE = 'CLEAR_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE';
export const GET_LATEST_CONFIGURATION_ON_TOP = 'GET_LATEST_CONFIGURATION_ON_TOP'
export const SET_LATEST_CONFIGURATION_ON_TOP = 'SET_LATEST_CONFIGURATION_ON_TOP';