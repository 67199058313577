export const FETCH_RULE_ENGINE = 'FETCH_RULE_ENGINE'
export const SET_RULE_ENGINE_LOADER = 'SET_RULE_ENGINE_LOADER'
export const SET_RULE_ENGINE_LIST_REQUEST_HASH = 'SET_RULE_ENGINE_LIST_REQUEST_HASH'
export const SET_RULE_ENGINE_LIST_TABLE_PROPS = 'SET_RULE_ENGINE_LIST_TABLE_PROPS'
export const CLEAR_RULE_ENGINE_LIST_DATA = 'CLEAR_RULE_ENGINE_LIST_DATA'
export const SET_RULE_ENGINE_STATUS = 'SET_RULE_ENGINE_STATUS'

export const FETCH_RULE_ENGINE_HISTORY = 'FETCH_RULE_ENGINE_HISTORY'
export const SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH = 'SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH'
export const SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS = 'SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS'
export const CLEAR_RULE_ENGINE_HISTORY_LIST_DATA = 'CLEAR_RULE_ENGINE_HISTORY_LIST_DATA'
export const SET_RULE_ENGINE_HISTORY_STATUS = 'SET_RULE_ENGINE_HISTORY_STATUS'

export const SET_RULE_ENGINE_EXECUTION_PLAN_DATA = 'SET_RULE_ENGINE_EXECUTION_PLAN_DATA'
export const SET_RULE_ENGINE_FORM_REDUX = 'SET_RULE_ENGINE_FORM_REDUX'
export const CLEAR_RULE_ENGINE_FORM_REDUX = 'CLEAR_RULE_ENGINE_FORM_REDUX'
export const FETCH_RULE_SINGLE_RULE_ENGINE_FOR_EDIT = 'FETCH_RULE_SINGLE_RULE_ENGINE_FOR_EDIT'
export const SET_RULE_ENGINE_LAYOUT_VIEW = 'SET_RULE_ENGINE_LAYOUT_VIEW'

export const FETCH_SINGLE_EXECUTION_SUMMARY = 'FETCH_SINGLE_EXECUTION_SUMMARY';
export const SET_RULE_ENGINE_EXECUTION_SUMMARY_LIST_TABLE_PROPS ='SET_RULE_ENGINE_EXECUTION_SUMMARY_LIST_TABLE_PROPS'
export const CLEAR_RULE_ENGINE_EXECUTION_SUMMARY = 'CLEAR_RULE_ENGINE_EXECUTION_SUMMARY'

export const SET_LATEST_RULE_ON_TOP = 'SET_LATEST_RULE_ON_TOP'
