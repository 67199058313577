import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import { toast } from 'react-toastify';


// eslint-disable-next-line react/prop-types
const ExecutionPlanButton = ({ mode,executionPlanMode,disabled,ruleId }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) {
      toast.error('Rule Name missing');
     return
    }
    navigate('/define-execution-plan', { state: { mode: mode, ruleId: ruleId } });
  };

  const borderColorGreen = '#00BFBF'
  const borderColorGreen1 = '#27C380'
  const borderColorBlack = '#000';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${borderColorGreen}`, 
        borderRadius: '8px',
        padding: '4px',
        backgroundColor: '#fff',
        width: '329px',
        height:'72px',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginLeft: '4px',
        boxShadow: '0px 4px 10px rgba(73, 200, 209, 0.5)',
        color: borderColorBlack,
      }}
    >
      {/* Tick Mark Icon */}
      <div style={{alignItems:'center',marginRight:'15px'}}>

        <IconWithTooltip iconClassName={executionPlanMode !== 'Edit' ?  'define-execution-sidebar': 'execution_plan_button_edit'} />
      </div>

      {/* Text Content */}
      <Box sx={{
        flexGrow: 1,
        alignItems: 'center',
        maxHeight: '60px',
        width: '170px' ,
       overflow: 'hidden',          
    textOverflow: 'ellipsis',    
    whiteSpace: 'normal', }}>
        
    <Typography
        sx={{
          marginBottom: '4px',
          fontSize: '14px',
          color: executionPlanMode === 'Edit' ? borderColorGreen1 : borderColorBlack,
        }}
      >
        {executionPlanMode === 'Edit' ? (
          <>
            Execution Plan successfully <br />
            defined
          </>
        ) : (
          'Define Execution Plan'
        )}
      </Typography>
        {executionPlanMode !== 'Edit' && (
          <Typography sx={{fontSize:'11px',color:'#8C8C8C',marginTop:'-5px'}}>
            The execution plan is essential for managing the logic.
          </Typography>
        )}
      </Box>

      {/* Pencil Icon on the right */}
      {executionPlanMode === 'Edit' && (
        <div   onClick={handleClick}>

          <IconWithTooltip
            iconClassName={'define-execution-edit-pencil-sidebar'} />
        </div>
      )}

      {/* Define/Edit Button */}
      {executionPlanMode !== 'Edit' && (
        <Button
          variant='contained'
          // disabled= {disabled}
          sx={{
            backgroundColor: borderColorGreen,
            color: '#fff',
            textTransform: 'none',
            fontWeight: 'bold',
            borderRadius: '6px',
            width: '3px !important',
            '&:hover': {
              backgroundColor:borderColorGreen ,
            },
          }}
          onClick={handleClick}
        >
          <span style={{fontWeight:400,fontSize:'14px'}}>
          Define
          </span>
        </Button>
      )}
    </Box>
  );
};

// Add PropTypes validation
ExecutionPlanButton.propTypes = {
  mode: PropTypes.oneOf(['Edit', 'Define']).isRequired,
};

export default ExecutionPlanButton;