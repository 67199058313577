/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Handle, Position } from '@xyflow/react';
import CloseIcon from '@mui/icons-material/Close';
import { SidePopover } from '../../../globalUtils/globalExports';
import { ExecutionPlan, RemoteCommandSidePopOver } from '../../componentExports';
import './RemoteCommandNode.css'
import { RemoteCommandAction } from './ExecutionPlan';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';

// import { SidePopover, } from '../../../globalUtils/globalExports';
// import SendNotificationSidePopOver from './SendNotificationSidePopOver';


interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        executionPlan: ExecutionPlan;
        handleUpdateExecutionPlan: Function;
      ifOrElse?: String
      removeNode?: Function
      id?: string
      typeOfNode:string
      };
}


const RemoteCommandNode: React.FC<CustomNodeProps> = ({data} : CustomNodeProps) => {
  
  const [isRemoteCommandPopoverOpen, setIsRemoteCommandPopoverOpen] = useState(false);
  const [remoteCommand, setRemoteCommand] = useState<RemoteCommandAction | null>(null);


  const handleEditClick = () => {
    setIsRemoteCommandPopoverOpen(true);
  };
  
  const handleCloseRemoteCommandPopover = ()=>{
    setIsRemoteCommandPopoverOpen(false);
  }

  useEffect(()=>{
    const currentRemoteCommand = data?.executionPlan?.action?.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Remote Command') as RemoteCommandAction; //  assuming that in the if block
    // if(currentRemoteCommand!== remoteCommand){
      setRemoteCommand(currentRemoteCommand)
      // }
  },[data?.executionPlan?.action?.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Remote Command') as RemoteCommandAction])


    return (
      <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
      
    <Paper
      elevation={3}
      sx={{
        border: '1px solid #0080DC',
        borderLeft: '6px solid #0080DC',
        borderRadius: '8px',
        padding: '8px 16px',
        minWidth: '350px',
        backgroundColor: '#fff',
        position: 'relative',
        marginBottom: '16px',
        minHeight:'110px'        
      }}
     >
   <IconButton
    size="small"
    onClick={() => {
      if (data?.removeNode) {  
        data.removeNode(data?.id,data?.ifOrElse,data?.typeOfNode);   
      } 
    }}
    sx={{ position: 'absolute', top: 0, right: 0 }}
     >
    <CloseIcon fontSize="small" sx={{ color: '#666' }} />
  </IconButton>
      <Handle type="target"   className="transparent-handle"  position={Position.Left} id='left' />
      <Box display="flex" alignItems="center" marginBottom="8px">
       <IconWithTooltip iconClassName={'remote-command-sidebar-1'} />
       <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
          }}
        >
          { 'Remote Command'}
        </Typography>
      </Box>

      {/* Category and Sub Category */}
      <Box
        sx={{
          backgroundColor: '#f0f4ff',
          padding: '8px',
          borderRadius: '4px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'end'
          
        }}
      >
       <Box display="flex" flexDirection="column" alignItems="start">
        <Box display="flex" alignItems="center" mb={1}>
          <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
          <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
            Remote Command: <strong>{remoteCommand && remoteCommand?.remoteCommandType && <span>{remoteCommand?.remoteCommandType.toUpperCase()}</span>}</strong>
          </Typography>
        </Box>

           {/* {remoteCommand && remoteCommand?.url && <Box display="flex" alignItems="center">
              <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
              <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
                URL: <strong>{remoteCommand && remoteCommand?.url && <span>{remoteCommand?.url}</span>}</strong>
              </Typography>
            </Box>} */}
        </Box>
        <IconButton
          size="small"
          onClick={handleEditClick}

        >
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
        </IconButton>
      </Box>

      {/* Add Activity link */}
            </Paper>
        {data.actionButtonVisible && 
            <Typography
            onClick={() => data.addNodeToCanvas()}
           variant="body2"
           sx={{
             color: '#B0B1B6',
             marginTop: '8px',
             marginLeft: '10px',
             cursor: 'pointer',
             fontSize: '0.875rem',
           }}
         >
           + Add Activity
            </Typography>
         }
      </div>
      {isRemoteCommandPopoverOpen && <SidePopover
         id='rule__engine__execution__plan__action__control__remote__command__popover'
         open={isRemoteCommandPopoverOpen}
         headerText={`Action Control - ${data.ifOrElse === 'If' ? 'Then' : 'Else'}`}
         containerClassName='configure__notification__detail__popover'
         handleClose={()=>{}}
         headerSecondaryText={'Remote Command'}
         horizontalLinePopover={true}
         popperClassName='rule__engine__remote__command'
         headerClassName={'rule__engine__header__config'}
         isRuleEnginePopOver={true}
         subHeadingIconClassName={'remote-command-sidebar'}
         ruleEngineSidePopoverOnclose={handleCloseRemoteCommandPopover}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {
                <RemoteCommandSidePopOver 
                  remoteCommand={remoteCommand ?? null}
                  handleClose={handleCloseRemoteCommandPopover} 
                  executionPlan={data?.executionPlan}
                  handleUpdateExecutionPlan={data?.handleUpdateExecutionPlan}
                  ifOrElse={data?.ifOrElse??''}            
                  />
              }
            </>
        } 
         />
       }

      </>

  );
};

export default RemoteCommandNode;
