/* eslint-disable @typescript-eslint/no-unused-vars */
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DashboardHeader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  CustomForm, CustomButtonGroup, CustomBreadCrumbs, DashboardLoader, CustomRadioForm, FilterProp, RadioButton,
  SidePopover,
  SearchBoxType,
  TableSubHeader,
  CheckBox
} from '../../globalUtils/globalExports';
import {
  getReferenceDataReducer, getUserReducer,
   fetchAddParameterRefChargers, fetchChargersByCustomersByOrg, fetchChargerLocationReferenceData,
  getRuleEngineReducer,
  setRuleEngineFormRedux,
  getCurrentScreenAppliedFiltersCount
  
} from '../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { ICustomFormProps, IFormContentProp } from '../../globalUtils/CustomForm/CustomForm';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import '../users/UserForm/UserForm.css'
import { createBreadCrumbProps,  renderHeaderActionButtons } from '../../globalUtils/globalHooks';
import {  ExecutionPlanButton, ExecutionSummarySidePopover} from '../componentExports';
import {  Typography } from '@mui/material';
import { IRuleEngineFormRedux } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineType';
import { clearRuleEngineFormRedux, fetchSingleRuleEngine, postAlarmRule,setLatestRuleOnTop,updateAlarmRule } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineCreator';
import { Icon, IconWithTooltip } from '../../globalUtils/globalIcons';
import './RuleEngineForm.css'
import _ from 'lodash'
import { ThreeDots } from 'react-loader-spinner';
import ExecutionLogsSidePopover from './ExecutionLogsSidePopover';

export interface ScheduleExecution {
  type: string;
  dateTime: null | string;
  recurrenceDays: null |string[];
  recurrenceRange: null | string;
  recurrenceCount: null | number;
  endDate? : null | string;

}

const RuleEngineForm: FC = () => {
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRoleTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { ruleEngineFormRedux } = useAppSelector(getRuleEngineReducer);
  const { chargerListByCustomerByOrg } = useAppSelector(getUserReducer)
  const { chargerLocationReferenceData, chargerProductTypeReferenceData, chargingStationReferenceData } = useAppSelector(getReferenceDataReducer);
  const initialFormData: IRuleEngineFormRedux = {
    name: null,
    description: null,
    chargerIds: null,
    id: null,
    status: null,
    draftStatus: null,
    executionPlan: null,
    autoAssign: 'Enabled',
    imageURL: '',  
  }
  const [CreateRuleFormData, setCreateRuleFormData] = useState(initialFormData);
  const [dataList, setDataList] = useState<string[]>([])
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : 'Define')
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const [selectedChargersInitially, setSelectedChargersInitially] = useState<string[]>();
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessageName, setErrorMessageName] = useState('')
  const [errorMessageDescription, setErrorMessageDescription] = useState('')
  const [isSidePopOverExpanded,setIsSidePopOverExpanded] = useState<boolean|null>(false);

  const handleSidePopOverExpansion = ()=>{
    setIsSidePopOverExpanded((isSidePopOverExpanded)=>!isSidePopOverExpanded);
  }

  const [filters, setFilters] = useState<InnerFilterState[]>(
    [{
      key: 'customer',
      value: null
    },
    {
      key: 'chargingStationId',
      value: null
    },
    {
      key: 'productType',
      value: null
    },
    {
      key: 'location',
      value: null
    },])
  const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
  const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
  const [selectedRadioOptionForStatus, setSelectedRadioOptionForStatus] = useState(ruleEngineFormRedux?.status ? ruleEngineFormRedux?.status : 'Disabled');
  const [initialExecutionPlan, setInitialExecutionPlan] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState<string[]>();
  const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
  const [disableExecutionPlanButton, setDisableExecutionPlanButton] = useState(true)
  const [initialAutoAssignState,setInitialAutoAssignState] = useState('Disabled')
  const [isExecutionSummarySidePopoverOpen, setIsExecutionSummarySidePopoverOpen] = useState<boolean>(false);
  const [isExecutionLogsSidePopoverOpen, setIsExecutionLogsSidePopoverOpen] = useState<boolean>(false);

  const orgId = JSON.parse(localStorage.getItem('rms-user') || '{}').orgId

  const [summaryExecutionFiltersVisible,setSummaryExecutionFiltersVisible] = useState(false);
  const handleFiltersClosePopOver = (): void =>{
      setSummaryExecutionFiltersVisible(false);
    }

  useEffect(() => {
    const chargerData = chargerListByCustomerByOrg?.map((item) => {
      return {
        header: item?.customerName,
        count: item?.chargers?.length,
        data: item?.chargers?.map((charger) => charger?.visibleId)
      }
    }) || []

    const dataList = chargerData?.flatMap(({ header, count, data }) => [
      (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
      ...data
    ]).filter(item => item !== '');
    const uniqueDataList = Array.from(new Set(dataList));
    setDataList(uniqueDataList)

  }, [chargerListByCustomerByOrg])
  

  useEffectOnce(async () => {
    dispatch(fetchChargerLocationReferenceData())
    chargerListCallback?.(orgId)
    setShowLoader(true);
    dispatch(fetchAddParameterRefChargers())
    setMode(location?.state?.mode || 'Create')
    if (mode && (location?.state?.rowData?.id)) {
      
      dispatch(fetchSingleRuleEngine({}, (location?.state?.rowData?.id))).then((res) => {
        setSelectedChargers(res?.data?.data?.chargerIds)
        setChargerCheckboxStates(res?.data?.data?.chargerIds)
        dispatch(
          setRuleEngineFormRedux({
            ...res?.data?.data,

          })
        );
        setSelectedChargersInitially(res?.data?.data?.chargerIds)
        setCreateRuleFormData({
          ...CreateRuleFormData,
          executionPlan: res?.data?.data?.executionPlan,
          autoAssign: res?.data?.data?.autoAssign,
          imageURL:res?.data?.data?.imageURL,
          fileName: res?.data?.data?.fileName,
          id: res?.data?.data?.id,
          chargerIds: res?.data?.data?.chargerIds,
          status: res?.data?.data?.status
         
        })
        setInitialAutoAssignState(res?.data?.data?.autoAssign)
        setInitialExecutionPlan(res?.data?.data?.executionPlan)
        setSelectedRadioOptionForStatus(res?.data?.data?.status);
      })
    }
    setShowLoader(false)
    if (location?.state?.mode) {
      setMode(location?.state?.mode)
    }
  });

  useEffect(() => {
    const data = cloneDeep(CreateRuleFormData);
    data.executionPlan = ruleEngineFormRedux?.executionPlan;
    data.name = ruleEngineFormRedux?.name
    data.description = ruleEngineFormRedux?.description
    data.imageURL = ruleEngineFormRedux?.imageURL
    data.fileName = ruleEngineFormRedux?.fileName
    data.autoAssign = ruleEngineFormRedux?.autoAssign
    data.status = ruleEngineFormRedux?.status

    if (ruleEngineFormRedux?.chargerIds?.length >= 1) {
      setSelectedChargers(ruleEngineFormRedux?.chargerIds)
      setChargerCheckboxStates(ruleEngineFormRedux?.chargerIds)
      setChargersChipValues(ruleEngineFormRedux?.chargerIds);
    }
    setCreateRuleFormData(data);
    if (ruleEngineFormRedux && ruleEngineFormRedux?.name?.length) {
      setDisableExecutionPlanButton(false);
    } else setDisableExecutionPlanButton(true);
  }, [ruleEngineFormRedux])

  useEffect(() => {
    if (location?.state?.rowData) {
      setCreateRuleFormData({
        ...CreateRuleFormData,
        name: location?.state?.rowData?.name,
        description: location?.state?.rowData?.description,
      })
    }
  }, [])
  

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(CreateRuleFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    }
    else {
      formData[formKey] = val;
    }
    if (formKey == 'name') {
      if (val.length > 90) {
        setErrorMessageName('Max characters allowed are 90')
      }
      else setErrorMessageName('')
      formData[formKey] = formData[formKey].charAt(0).toUpperCase() + formData[formKey].slice(1);
    }
    if (formKey == 'description') {
      if (val.length > 500) {
        setErrorMessageDescription('Max characters allowed are 500')
      }
      else setErrorMessageDescription('')
      formData[formKey] = formData[formKey].charAt(0).toUpperCase() + formData[formKey].slice(1);
    }
    setCreateRuleFormData(formData);
    
    /// dispatch an event to save all the formdata inside the redux
    dispatch(setRuleEngineFormRedux(formData));
    
  }, [CreateRuleFormData]);

  function arraysAreEqual<T>(arr1?: T[], arr2?: T[]): boolean {

    if (!arr2) {
      return false;
    }
    if (!arr1) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((value, index) => value === arr2[index]);
  }

  const disableSubmitButton = useCallback(() => {
    if (mode == 'View') { return true }
    if (mode == 'Edit') { 
      if (selectedChargers && selectedChargers.length == 0) {
        return true
      }
      const result = (CreateRuleFormData?.name == location.state?.rowData?.name) &&
      (CreateRuleFormData?.description == location.state?.rowData?.description) &&
        (arraysAreEqual(selectedChargers, selectedChargersInitially)) &&
        (CreateRuleFormData?.autoAssign == initialAutoAssignState) &&
        (selectedRadioOptionForStatus == location.state?.rowData?.status) &&
        _.isEqual(initialExecutionPlan, ruleEngineFormRedux?.executionPlan)
        // && 
        // !(selectedChargers && selectedChargers?.length > 0);
    return result
    }
    
    return !((ruleEngineFormRedux?.name) && (ruleEngineFormRedux?.name?.length > 0 && ruleEngineFormRedux?.name?.length < 91) && (ruleEngineFormRedux?.description) && (ruleEngineFormRedux?.description?.length > 0 && ruleEngineFormRedux?.description?.length < 501) && (selectedChargers && selectedChargers?.length > 0) && (ruleEngineFormRedux?.executionPlan));
  }, [selectedChargers, ruleEngineFormRedux,CreateRuleFormData,selectedRadioOptionForStatus]);

  const userFormSuccessToast = (): Id => toast.success(mode === 'Edit' ? 'Rule Edited.' : 'Rule Added.');

  const saveAsDraftDisableCheck = () => {
    if (mode == 'View') { return true }
    return location?.state?.mode === 'Define' ? ((selectedRadioOptionForStatus === 'Disabled' && (disableSubmitButton() === false)) ? false : true) : ((location?.state?.rowData?.runningStatus === 'Draft' && selectedRadioOptionForStatus === 'Disabled' && (disableSubmitButton() === false)) ? false : true)
  }

  const footerButtonListDefineMode = useMemo(() => {
    return [
      {
        buttonText: 'Save as draft',
        buttonId: 'draft',
        btnClassName: !saveAsDraftDisableCheck() ? 'primary__btn' : 'primary__btn disabled',
        handleClick: (): void => {
          handleSubmit?.(mode, 'Draft')
        },
        // isDisabled: (selectedRadioOptionForStatus === 'Disabled' && (disableSubmitButton() === false)) ? false : true,
        isDisabled: saveAsDraftDisableCheck(),
        buttonVariant: 'filled',
      },
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Publish',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',

        handleClick: (): void => {
          handleSubmit?.(mode, 'Not Draft')
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/rule-engine')
        },
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, CreateRuleFormData, mode, selectedChargers, selectedRadioOptionForStatus]);

  
  const footerButtonListEditMode = useMemo(() => {
    const buttons = [
      {
        buttonText: 'Update Draft',
        buttonId: 'draft',
        btnClassName: !saveAsDraftDisableCheck() ? 'primary__btn' : 'primary__btn disabled',
        handleClick: (): void => {
          handleSubmit?.(mode, 'Draft');
        },
        isDisabled: saveAsDraftDisableCheck(),
        buttonVariant: 'filled',
      },
      {
        buttonText: location?.state?.rowData?.runningStatus === 'Draft' ? 'Publish' : 'Update',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode, 'Not Draft');
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/rule-engine');
        },
        buttonVariant: 'outlined',
      },
    ];
  
    // Filter out the first button if the condition is true
    return location?.state?.rowData?.runningStatus !== 'Draft'
      ? buttons.filter((button) => button.buttonId !== 'draft')
      : buttons;
  }, [
    disableSubmitButton,
    saveAsDraftDisableCheck,
    mode,
    location?.state?.rowData?.runningStatus,]);
  
  const footerButtonListViewMode = useMemo(() => {
    return [
    
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/rule-engine')
        },
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, CreateRuleFormData, mode, selectedChargers, selectedRadioOptionForStatus]);

  const createRuleFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-2',
      sectionHeading: '',
      formObject: [
        {
          formLabel: 'Rule Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'name');
            },
            inputValue: CreateRuleFormData?.name ?? ruleEngineFormRedux?.name,
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: mode === 'Edit' || mode === 'View',
            autoFocus: true,
            errorMessage: errorMessageName

          },
          textAreaProps: null,
        }
      ],
    },
    {
      rowCountClassName: 'grid-2',
      sectionHeading: '',
      formObject: [
        {
          formLabel: 'Description',
          isRequired: true,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'description');
            },
            isRequired: false,
            inputValue: CreateRuleFormData?.description ?? ruleEngineFormRedux?.description,
            inputFieldId: 'description',
            isDisabled: mode === 'View',
            className: 'custom-resize',
            errorMessage: errorMessageDescription
          },
        },
      ],
    },
  ];

  const createRuleFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'user__form__profile__info',
      // formArray: mode == 'View' ?createRuleFormArrayViewOnly : createRuleFormArray
      formArray: createRuleFormArray
    };
  }, [CreateRuleFormData, userRoleTypeReferenceData, mode, customerReferenceData])

  console.log(ruleEngineFormRedux,'ruleEngineFormRedux')

  const handleSubmit = useCallback(async (mode, status) => {

    const reqBody = {
      id: ruleEngineFormRedux?.id,
      name: CreateRuleFormData?.name,
      description: CreateRuleFormData?.description,
      executionPlan: CreateRuleFormData?.executionPlan,
      chargerIds: CreateRuleFormData?.chargerIds != null ? CreateRuleFormData?.chargerIds : selectedChargers,
      status: selectedRadioOptionForStatus,
      draftStatus: status,
      autoAssign: CreateRuleFormData?.autoAssign,
      fileName: CreateRuleFormData?.fileName,
      imageURL:CreateRuleFormData?.imageURL
     

    }
    let response
    if (mode === 'Edit') {
      setShowLoader(true)
      response = await dispatch(updateAlarmRule(reqBody))
    } else {
      // setShowLoader(true)
      response = await dispatch(postAlarmRule(reqBody))
    }

    if (response?.message === 'Success') {
      dispatch(clearRuleEngineFormRedux());
      userFormSuccessToast();
      await dispatch(setLatestRuleOnTop(true))
      navigate('/rule-engine')
    } else {
      setShowLoader(false)
    }
  }, [CreateRuleFormData, mode, selectedRadioOptionForStatus])

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'ruleEngineDashboard',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Create' ? CreateRuleFormData?.name ?? '' : `${mode} Rule`
        }
      ]
  })

  const setHeaderText = useCallback(() => {
    if ((mode == 'View'  || mode == 'Edit')  && CreateRuleFormData?.name) {
      return CreateRuleFormData?.name
    } else return `${mode} Rule`
  }, [mode, CreateRuleFormData])

  const chargerListCallback = useCallback((orgId, filters?, searchTerm?) => {
    let filtersToBeSent: Filters[] = []
    if (filters && filters?.length > 0) {
      filtersToBeSent = filters?.map((item) => {
        return {
          key: item.key,
          values: item.value?.map((item) => item.id) || []
        }
      })
    }
    dispatch(fetchChargersByCustomersByOrg({
      orgId: orgId,
      filters: filters ? filtersToBeSent : [],
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
    }));
  }, [CreateRuleFormData])

  const handleFilterDataChange = useCallback((val, filterkey) => {
    const filtersToUpdate = cloneDeep(filters);
    const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
    if (selectedFilter) {
      selectedFilter.value = Array.isArray(val) ? val : [val]
    }
    setFilters(filtersToUpdate);
    chargerListCallback?.(orgId, filtersToUpdate, '')
  }, [filters, chargerListCallback, setFilters, CreateRuleFormData])

  const onSearchTextChange = useCallback((val) => {
    chargerListCallback?.(orgId, filters, val)
  }, [filters, CreateRuleFormData])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'user__form_chargers-search-box',
      searchFieldName: 'user__form_chargers-search-box',
      isDisabled: mode === 'View',
      handleSearch: onSearchTextChange,
      searchBoxClassName: 'rule__engine__charger_selection'
    };
  }, [onSearchTextChange, filters, CreateRuleFormData, mode]);

  const radioButtonList = [
    { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: mode === 'View' },
  ]
  const radioButtonListForStatus = [
    { radioButtonLabel: 'Enabled', radioButtonId: 'Enabled', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Disabled', radioButtonId: 'Disabled', isDisabled: mode === 'View' },
  ]

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customer',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
          selectOptions: customerReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'customer')
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'location')?.value,
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },

      {
        filterLabel: 'Charging Station',
        filterType: 'dropdown',
        filterId: 'chargingStationId',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'chargingStationId')?.value,
          selectOptions: chargingStationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargingStationId')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Product Type',
        filterType: 'dropdown',
        filterId: 'ProductType',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'productType')?.value,
          selectOptions: chargerProductTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'productType')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
    ]
  }, [chargerLocationReferenceData, customerReferenceData, filters, mode, CreateRuleFormData])

  const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
    const formData = cloneDeep(CreateRuleFormData);

    setChargerCheckboxStates(selectedChargers)
    setSelectedChargers(selectedChargers);
    setCreateRuleFormData({
      ...formData,
      chargerIds: selectedChargers,
    
    })
    setChargersChipValues(selectedChargerChips);
    setIsSelectAllChargersChecked(isChecked)
    dispatch(setRuleEngineFormRedux({
      ...ruleEngineFormRedux,
      chargerIds: selectedChargers
      
    }));
  };
  const handleRadioOptionChange = (_, value) => {
    setSelectedRadioOption(value);
    if (value === 'AllChargers') setSelectedChargers(['ALL'])
    else { setSelectedChargers(chargerCheckboxStates) }
  };
  const handleRadioOptionChangeForStatus = (_, value) => {
    setSelectedRadioOptionForStatus(value);  
    const formData = cloneDeep(CreateRuleFormData);
    setCreateRuleFormData({
      ...formData,
      status:value
    })
    dispatch(setRuleEngineFormRedux({
      ...formData,
      status:value
    }));
  };

  const radioButtonConfiguration = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonList,
      selectedRadioBtnValue: selectedRadioOption,
      handleSelectedRadioBtnChange: handleRadioOptionChange
    };
  }, [CreateRuleFormData, mode, selectedRadioOption, chargerCheckboxStates]);

  const radioButtonConfigurationForStatus = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonListForStatus,
      selectedRadioBtnValue: selectedRadioOptionForStatus,
      handleSelectedRadioBtnChange: handleRadioOptionChangeForStatus
    };
  }, [CreateRuleFormData, mode, selectedRadioOptionForStatus]);

  
  const handleCloseExecutionSummaryPopover = () => {
    setIsExecutionSummarySidePopoverOpen(false);
    setIsSidePopOverExpanded(false);
  }

  const handleCloseExecutionLogsPopover = () => {
    setIsExecutionLogsSidePopoverOpen(false);
    setIsSidePopOverExpanded(false);
  }

  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'rule__engine__form__execution__summary__btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {
            setIsExecutionSummarySidePopoverOpen(true);
          }
        },
        {
          id: 'rule__engine__form__update__logs__btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {
            setIsExecutionLogsSidePopoverOpen(true);
          }
        },
      ]
  },)

  const headerActionExecutionSummaryBtn = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_filter_btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {
            setSummaryExecutionFiltersVisible(true);
          },
          badgeCount: getCurrentScreenAppliedFiltersCount('executionSummaryRuleEngineForm', false)
        },
      ]
  },)

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='user__form__wrap'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='user__form__wrap-header'>
          <DashboardHeader
            header={setHeaderText()}
            headerClassName='ruleEngine__form__wrap-header-custom'
          />
           {mode !== 'Define' && (
            <Icon
              iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'}
              containerClassName='org__form_header-edit'
              onIconClick={() => {
                setMode(mode === 'View' ? 'Edit' : 'View')
              }
              }
            />

          )}
          <div className="rms__table__dashboard-sub-header-wrap">
            {mode === 'View' &&
              <div className='rms__table__dashboard-sub-header-wrap-buttons'>
                <TableSubHeader headerActionBtns={headerActionBtns} />
              </div>}
            </div>
        </div>
        <div className='user__form__wrap-content'>
          <CustomForm {...createRuleFormProps} />

          <Typography sx={{ fontSize: '16px', marginTop: '5px', marginBottom: '10px', fontWeight: 500, fontFamily: 'Inter, sans-serif' }}>
            Execution Plan
          </Typography>
          {mode === 'View' ? (
       
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
       {!isImageLoaded && (
         <ThreeDots 
           height="40" 
           width="40" 
           radius="9"
           color="#01BFBF" 
           ariaLabel="three-dots-loading"
           visible={true}
         />
       )}
       <img
         src={CreateRuleFormData?.imageURL}
         alt="Execution Plan"
         style={{
           display: isImageLoaded ? 'block' : 'none',
           width: '100%',
           maxWidth: '800px',
           height: 'auto',
           borderRadius: '8px',
           scale: 0.75,
         }}
         onLoad={() => setIsImageLoaded(true)}
       />
     </div>
      ) : (
              <ExecutionPlanButton
            executionPlanMode = {CreateRuleFormData.executionPlan && CreateRuleFormData.executionPlan != null ? 'Edit' : 'Define'}
            mode={mode}
            disabled = {disableExecutionPlanButton}
            ruleId ={location?.state?.rowData?.id || location?.state?.ruleId}
          />
          )}
          
          <CustomRadioForm
            smallerFont={true}
            formContainerClass={'user__form__charger-selection'}
            header={'Charger Selection'}
            dataList={dataList || []}
            individualCheckboxStates={chargerCheckboxStates}
            selectedChipValues={chargersChipValues}
            selectAllChecked={isSelectAllChargersChecked}
            handleSelectedData={handleSelectedValues}
            filterProps={chargerFilterProps}
            radioButtonConfiguration={radioButtonConfiguration}
            searchBoxConfiguration={searchBoxConfiguration}
            disableCheckBox={mode === 'View'}
            showRadioList={selectedRadioOption === 'SelectChargers'}
            isCustom={true}
            radioButtonConfigNotRequired={true}
            removePadding={true}
          />
          <div className='user__form__wrap-footer-top'>
            <div className='user__form__wrap-footer-top-checkbox' onClick={() => { mode !== 'View' && handleFormDataChange(CreateRuleFormData?.autoAssign === 'Enabled' ? 'Disabled' : 'Enabled', 'autoAssign') }} >
              <CheckBox checked={CreateRuleFormData?.autoAssign === 'Enabled'} isDisabled={mode === 'View'} />
              Auto assign new charger to this user
            </div>
            <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={'RMS will automatically assign any new charger to this user.'} containerClassName='user__form_footer-auto-assign-info' />
          </div>

          <div style={{ marginLeft: '5px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mt: '20px', mb: '10px', fontFamily: 'Inter, sans-serif' }}>
              Enable/Disable
            </Typography>
            <RadioButton
              key={'ruleStatus' + selectedRadioOptionForStatus}
              buttonsList={radioButtonConfigurationForStatus?.buttonsList}
              handleSelectedRadioBtnChange={radioButtonConfigurationForStatus?.handleSelectedRadioBtnChange}
              selectedRadioBtnValue={radioButtonConfigurationForStatus?.selectedRadioBtnValue}
              radionBtnGrpName={radioButtonConfigurationForStatus?.radionBtnGrpName}
            />
          </div>
          
        </div>
        <div className='user__form__wrap-footer'>
         
          <CustomButtonGroup
            buttonsList={
             mode === 'View'
                ? footerButtonListViewMode
                : mode === 'Define'
                ? footerButtonListDefineMode
                : footerButtonListEditMode
            }
            buttonGroupClassName='button__group__footer'
          />
        </div>
      </div>
     
       {isExecutionSummarySidePopoverOpen && <SidePopover
        id='rule__engine__form__execution__summary__side__popover'
        open={isExecutionSummarySidePopoverOpen}
        headerText={'Execution Summary'}
          headerSecondaryText={`Rule: ${location?.state?.rowData?.name||CreateRuleFormData?.name}`}
        containerClassName='configure__notification__detail__popover'
        handleClose={()=>{}}
        headerClassName={'rule__engine__header__config'}
      
        horizontalLinePopover={true}
        // popperClassName='rule__engine__raise__a__ticket'
        popperClassName={isSidePopOverExpanded ? 'rule__engine__form__side__popover__expanded' : 'rule__engine__raise__a__ticket'}
        isRuleEnginePopOver={true}
        showExpandableIcon={true}
        ruleEngineSidePopoverOnclose={handleCloseExecutionSummaryPopover}
        isSidePopOverExpanded={isSidePopOverExpanded}
        handleSidePopOverExpansion={handleSidePopOverExpansion}
        headerActionBtns={headerActionExecutionSummaryBtn}
        // eslint-disable-next-line react/no-children-prop
        children={
          <>
            {
              <ExecutionSummarySidePopover 
                ruleId={location?.state?.rowData?.id || location?.state?.ruleId}
                summaryExecutionFiltersVisible={summaryExecutionFiltersVisible}
                handleFiltersClosePopOver={handleFiltersClosePopOver}
               />
              
            }
          </>
        }
      />
      }
       {isExecutionLogsSidePopoverOpen && <SidePopover
        id='rule__engine__form__execution__logs__side__popover'
        open={isExecutionLogsSidePopoverOpen}
        headerText={'Update Logs'}
        headerSecondaryText={`Rule: ${location?.state?.rowData?.name}`}
        containerClassName='configure__notification__detail__popover'
        handleClose={()=>{}}
        headerClassName={'rule__engine__header__config'}
        horizontalLinePopover={true}
        popperClassName={isSidePopOverExpanded ? 'rule__engine__form__side__popover__expanded' : 'rule__engine__raise__a__ticket'}
        isRuleEnginePopOver={true}
        showExpandableIcon={true}
        ruleEngineSidePopoverOnclose={handleCloseExecutionLogsPopover}
        isSidePopOverExpanded={isSidePopOverExpanded}
        handleSidePopOverExpansion={handleSidePopOverExpansion}
        // eslint-disable-next-line react/no-children-prop
        children={
          <>
            {
              <ExecutionLogsSidePopover 
                ruleId={location?.state?.rowData?.id}
               />
              
            }
          </>
        }
      />
      }
    </>
  );
};

export default RuleEngineForm;
