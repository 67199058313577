/* eslint-disable quotes */
import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    DashboardLoader,
    downloadFile,
    FilterProp,
    formatRelativeTimeForTable,
    removeAllFromReferenceData,
    renderHeaderActionButtons,
    TableDashboard,
    useAppDispatch,
    useAppSelector,
} from '../../globalUtils/globalExports';
import {
    getReportReducer,
    setReportListTableProps,
    reportTypes,
    getFilterSelectedValue,
    getReferenceDataReducer,
    getFilterReducer,
    setFilterData,
    getCurrentFiltersValues,
    fetchChargerLogsData,
    exportChargerLogsReport,
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './reports.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentChargerLogs } from '../../globalUtils/TableDashboard/TableComponents';
import { chargerLogsNavList } from './ReportConstant';
import access from '../../auth/service/AccessControl';

const ChargerLogsDashboard = memo(function ChargerLogsDashboard() {

    const dispatch = useAppDispatch();
    const { chargerLogsColumnHeaders, chargerLogsData, chargerLogsCount, reportLoader, chargerLogsListTableProps } = useAppSelector(getReportReducer);
    const { chargerVisibleIdReferenceData } = useAppSelector(getReferenceDataReducer);
    const { screenFilters } = useAppSelector(getFilterReducer)
    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const [exportingFile, setExportingFile] = useState(false);
    const [dashboardLoader, setDashboardLoader] = useState(false)
    const [selectedNav, setSelectedNav] = useState<'status' | 'settings' | 'systemInfo'>('status');
    const chargerLogsListTablePropsRef = useRef(chargerLogsListTableProps)
    const navToKeyValMap = {
        status: 'chargerLogStatusInterval',
        settings: 'chargerLogSettingInterval',
        systemInfo: 'chargerLogSystemLogInterval'
    };
    const [switchToggleValue, setSwitchToggleValue] = useState<'All' | 'Select Chargers'>('All')

    useEffect(() => {
        chargerLogsListTablePropsRef.current = chargerLogsListTableProps;
    }, [chargerLogsListTableProps])

    // TODO: Handles global search box
    const onChangeOfSearchText = useCallback(async (searchTerm) => {
        if (chargerLogsListTablePropsRef) {
            await layoutCallback(1, chargerLogsListTablePropsRef?.current?.pageSize, chargerLogsListTablePropsRef?.current?.view, chargerLogsListTablePropsRef?.current?.sortBy, chargerLogsListTablePropsRef?.current?.order, null, searchTerm)
        }
    }, [chargerLogsListTablePropsRef, chargerLogsColumnHeaders, chargerLogsData, switchToggleValue]);

    const searchBoxConfiguration = useMemo(() => {
        return {
            searchFieldId: 'charger-logs-search-box',
            searchFieldName: 'charger-logs-search-box',
            handleSearch: onChangeOfSearchText,
        };
    }, [onChangeOfSearchText]);

    // NAVBAR CONFIGURATIONS
    const handleNavBtnClick = useCallback(async (val: 'status' | 'settings' | 'systemInfo') => {
        if (chargerLogsData || chargerLogsCount) {
            const reportAction = {
                type: reportTypes.CLEAR_CHARGER_LOGS_LIST_DATA,
            }
            await dispatch(reportAction);
        }
        setSelectedNav(val);
    }, [chargerLogsData, chargerLogsCount]);

    const navBarconfigurations = useMemo(() => {
        return {
            selectedNav: selectedNav,
            navList: chargerLogsNavList?.filter((nav) => nav?.isAccessible),
            onNavClick: handleNavBtnClick
        }
    }, [selectedNav, chargerLogsData, chargerLogsCount])

    const navSpecificFilterUpdate = (filters) => {
        return filters?.filter((obj) => obj.key === 'chargerId' || obj.key === navToKeyValMap[selectedNav]) || []
    }


    // TODO: Adds action btns in header and their callbacks
    const headerActionBtns = renderHeaderActionButtons({
        actionBtns:
            [
                {
                    id: 'export_csv_btn',
                    isAccessible: access?.reports?.chargerLog?.status?.export() || access?.reports?.chargerLog?.setting?.export() || access?.reports?.chargerLog?.systemInfo?.export(),
                    headerActionBtnClick: (event): void => {
                        setExportAnchorEl(event.currentTarget);
                    }
                }
            ]
    })

    useEffect(() => {
        layoutCallback(1, chargerLogsListTablePropsRef?.current?.pageSize, chargerLogsListTablePropsRef?.current?.view, chargerLogsListTablePropsRef?.current?.sortBy, chargerLogsListTablePropsRef?.current?.order, null, chargerLogsListTablePropsRef?.current?.searchTerm)
    }, [switchToggleValue])

    const toggleSwitchConfig = {
        switchId: 'charger_logs_toggle_btns',
        switchClassName: 'charger_logs_toggle_btns',
        disableSwitchLabelName: switchToggleValue === 'All' ? 'All' : 'Select Chargers',
        handleClick: () => { setSwitchToggleValue((prev) => { return prev === 'All' ? 'Select Chargers' : 'All' }) },
        checked: switchToggleValue === 'All'
    }

    // TODO: Table Layout Callbacks
    const mobileViewConfiguration = useMemo(() => {
        return {
            headerDataConfig: {
                headerLeftDataConfig: ['name', 'type'],
                headerRightDataConfig: {
                    actionIconsComponent: false,
                    statusIconComponent: true,
                },
            },
            statusIconKey: 'status',
            contentDataConfig: [
                [],
            ],
        };
    }, []);


    const getTableRows = useCallback(() => {
        if (!chargerLogsData || !chargerLogsColumnHeaders) {
            return [];
        }
        const data = cloneDeep(chargerLogsData)
        chargerLogsColumnHeaders?.forEach((column) => {
            const { key, dataType, unit } = column;
            if (key && (dataType || unit)) {
                data?.forEach((logData) => {
                    if (logData?.[key] && dataType === 'Date' && logData?.[key] != 'NA') {
                        logData[key] = formatRelativeTimeForTable(logData[key]);
                    }
                    if (logData?.[key] !== null && logData?.[key] !== undefined && unit && logData?.[key] != 'NA') {
                        logData[key] = `${logData?.[key]} ${unit}`;
                    }
                })
            }
        })
        return data || [];
    }, [chargerLogsData, chargerLogsColumnHeaders]);

    const chargerLogsColumns = chargerLogsColumnHeaders?.map(item => {
        return ({ field: item?.key, description: item?.description, flex: 1, headerName: item?.visibleName, minWidth: (item?.visibleName?.length > 25) ? 200 : 150, pinnable: false })
    })

    const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterValue, searchTerm) => {
        const selectedChargerFilter = getFilterSelectedValue('chargerId', 'chargerLogsReport', false)
        if (!selectedChargerFilter) {
            const chargerIdToSet = (removeAllFromReferenceData(chargerVisibleIdReferenceData) as IGeneralReferenceData[])?.[0]
            await setFilterData(chargerIdToSet, 'chargerId', 'chargerLogsReport', 'SET', false)(dispatch)
        }
        const tableProps: IListTableProps = cloneDeep(chargerLogsListTablePropsRef.current)
        await dispatch(fetchChargerLogsData({
            fetchType: switchToggleValue === 'All' ? 'latest' + (selectedNav.charAt(0).toUpperCase() + selectedNav.slice(1)) : selectedNav,
            sortBy: sortField || '',
            order: sortOrder || 'ascending',
            pageSize,
            pageNumber,
            filters: filterValue ? filterValue : navSpecificFilterUpdate(getCurrentFiltersValues('chargerLogsReport', true)),//.filter((obj)=> obj.key==='chargerId' || obj.key === 'customer' || obj.key === navToKeyValMap[selectedNav]),
            searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerLogsListTablePropsRef?.current?.searchTerm
        }));
        if (tableProps) {
            tableProps.view = view || 'list'
            tableProps.sortBy = sortField || ''
            tableProps.order = sortOrder || 'ascending';
            tableProps.pageNumber = pageNumber;
            tableProps.pageSize = pageSize;
            if (searchTerm != null && searchTerm != undefined) {
                tableProps.searchTerm = searchTerm;
            }
        }
        dispatch(setReportListTableProps(tableProps, 'chargerLogs'))
    }, [chargerLogsListTablePropsRef, dispatch, selectedNav, switchToggleValue]);

    // TODO: Export Handlers
    const handleClose = (): void => {
        setExportAnchorEl(null);
    };

    const fileDownloadSuccessToast = useCallback((): Id => {
        setExportingFile(false);
        return toast.success('File downloaded.');
    }, []);

    const fileDownloadFailureToast = useCallback((): Id => {
        setExportingFile(false);
        return toast.warn('Something went wrong. Please export again.');
    }, []);

    const renderExportMenuItem = (fileType: 'csv' | 'xlsx', label: string): JSX.Element => (
        <MenuItem key={fileType}>
            <div className='export__btn-option' onClick={(e) => {
                e?.stopPropagation();
                onExportBtnClick(fileType);
            }}>
                {label}
            </div>
        </MenuItem>
    );

    const dateRangeExceededErrorToast = (message): Id => toast.error(message);

    const dateFilterRangeCheck = (daysLimit) => {
        const filterSetOnValue = getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false);//navSpecificFilterUpdate(getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false));        
        const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
        const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
        if (startDate && endDate) {
            const differenceInMilliseconds = endDate?.getTime() - startDate?.getTime();
            const noOfDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
            if (noOfDays < daysLimit) {
                return true;
            }
        }
        return false;
    }

    const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
        if (!dateFilterRangeCheck(5) && switchToggleValue != 'All') {
            const filterSetOnValue = getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false);//navSpecificFilterUpdate(getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false));        
            const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
            const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
            if (startDate && endDate) dateRangeExceededErrorToast('Date range exceeded more than 5 days!!')
            else dateRangeExceededErrorToast('Please select date range of 5 days!!')
            return;
        }

        if (exportingFile) {
            // If already exporting, prevent another export
            return;
        }
        try {
            setDashboardLoader(true)
            const response = await dispatch(exportChargerLogsReport({
                fileType: fileType,
                fetchType: switchToggleValue === 'All' ? 'latest' + (selectedNav.charAt(0).toUpperCase() + selectedNav.slice(1)) : selectedNav,
                filters: navSpecificFilterUpdate(getCurrentFiltersValues('chargerLogsReport', true))
            }));
            if (response?.status === 202 || response?.status === 200) {
                setDashboardLoader(false)
                downloadFile({ url: response?.data?.url });
                fileDownloadSuccessToast()
            } else {
                setDashboardLoader(false)
                fileDownloadFailureToast();
                setExportAnchorEl(null);
            }
        } finally {
            setDashboardLoader(false)
            // Ensure that exporting status is updated even if there's an error
            setExportingFile(false);
            setExportAnchorEl(null);
        }
    }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast, selectedNav, switchToggleValue])

    // TODO: Filter side pop up 
    const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
        const res = await setFilterData(val, filterKey, 'chargerLogsReport', 'SET', isGlobal ? isGlobal : false, true)(dispatch)
        if (res?.message === 'Action dispatched successfully') {
            const filterSetOnValue = getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false);
            const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
            const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
            if (startDate && endDate) {
                const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
                const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                if (differenceInDays > 6) {
                    dateRangeExceededErrorToast('Date range exceeded more than 5 days!!')
                    return
                }
            } else if (startDate && !endDate) {
                dateRangeExceededErrorToast('Please select date range of 5 days!!')
                return
            }
            if (chargerLogsListTablePropsRef?.current) {
                await layoutCallback(1, chargerLogsListTablePropsRef?.current?.pageSize, chargerLogsListTablePropsRef?.current?.view, chargerLogsListTablePropsRef?.current?.sortBy, chargerLogsListTablePropsRef?.current?.order, navSpecificFilterUpdate(res?.filters), chargerLogsListTablePropsRef?.current?.searchTerm)
            }
        }
    }, [chargerLogsData, dispatch, chargerVisibleIdReferenceData, screenFilters, layoutCallback])

    const chargerLogsFilterProps: FilterProp[] = useMemo(() => {
        const filterSetOnValue = getFilterSelectedValue(navToKeyValMap[selectedNav], 'chargerLogsReport', false);

        return [
            {
                filterLabel: 'Charger',
                filterType: 'dropdown',
                filterId: 'chargerId',
                filterDropdownProps: {
                    selectValue: getFilterSelectedValue('chargerId', 'chargerLogsReport', false),
                    selectOptions: removeAllFromReferenceData(chargerVisibleIdReferenceData) as IGeneralReferenceData[] || [],
                    handleSelectOptionChange: (val): void => {
                        handleFilterDataChange(val, 'chargerId', false)
                    },
                    selectDropDownId: 'charger-chargerId-filter-dropdown',
                    isMultiSelect: false
                }
            },
            {
                filterLabel: 'Interval',
                filterType: 'dateFilter',
                filterId: navToKeyValMap[selectedNav],
                dateFilterProps: {
                    datePickerId: 'charger__logs__time__stamp__set__at__date__picker',
                    datePickerClassName: 'charger__logs__time__stamp__cleared__at__date__picker',
                    selectsRange: true,
                    startDate: filterSetOnValue?.[0] ? filterSetOnValue[0] : null,
                    endDate: filterSetOnValue?.[1] ? new Date(new Date(filterSetOnValue?.[1]).getTime()) : null,
                    onChange: (val): void => {
                        handleFilterDataChange(val, navToKeyValMap[selectedNav], false)
                    },
                    // includeDateIntervals: includeDateIntervals
                }
            },
        ]
    }, [chargerVisibleIdReferenceData, screenFilters, handleFilterDataChange])


    // TODO: Redux state Clean up
    const chargerLogsCleanUpStates = useCallback(() => {
        const action = {
            type: reportTypes.CLEAR_CHARGER_LOGS_LIST_DATA,
        }
        dispatch(action);
    }, [])

    useEffect(() => {
        window.addEventListener('beforeunload', chargerLogsCleanUpStates);
        return (): void => {
            chargerLogsCleanUpStates()
            window.removeEventListener('beforeunload', chargerLogsCleanUpStates);
        }
    }, [])

    return (
        <>
            <DashboardLoader showLoader={dashboardLoader} />
            <TableDashboard
                key={'charger__logs__report' + selectedNav}
                tableId='charger__logs__table'
                tableClassName='charger__logs__table'
                header={'Charger Logs'}
                searchBoxIncluded={true}
                searchBoxConfiguration={switchToggleValue === 'All' ? searchBoxConfiguration : null}
                gridColumns={chargerLogsColumns}
                tableRows={getTableRows()}
                mobileViewConfiguration={mobileViewConfiguration}
                layoutView={'list'}
                headerActionBtns={headerActionBtns}
                listLayoutCallBack={layoutCallback}
                totalCount={chargerLogsCount}
                showSkeleton={!chargerLogsData}
                totalCountText={'charger logs'}
                showLoader={reportLoader}
                customNoDataComponent={NoDataComponentChargerLogs}
                pageNumber={chargerLogsListTableProps?.pageNumber}
                tablePageSize={chargerLogsListTableProps?.pageSize}
                filterProps={switchToggleValue !== 'All' ? chargerLogsFilterProps : []}
                handleFilterDataChange={handleFilterDataChange}
                referenceDataCallbacksFilters={['chargerVisibleId']}
                navBarconfigurations={navBarconfigurations}
                leftPinnedColumns={['visibleId', 'connectorNo']}
                excludeGlobalFilters={true}
                toggleSwitchConfig={toggleSwitchConfig}
            />
            <Menu
                id='export-option-dropdown'
                anchorEl={exportAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='export__option__dropdown'
                sx={{ top: '30px' }}
                open={Boolean(exportAnchorEl)}
                onClose={handleClose}
            >
                {renderExportMenuItem('csv', 'To CSV')}
                {renderExportMenuItem('xlsx', 'To Excel')}
            </Menu>
        </>
    );
})

export default ChargerLogsDashboard;


