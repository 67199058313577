import React, { memo } from 'react';
import { Avatar, Box, Typography, List, ListItem, Divider, Grid } from '@mui/material';

// Mock data
const logs = [
  {
    id: 1,
    avatar: 'SM',
    avatarColor: '#C54545',
    user: 'Shrey Mishra',
    action: 'created the Rule,',
    details: 'Disabled the rule and saved as draft',
    timestamp: '18 Dec 2024 12:00 PM',
  },
  {
    id: 2,
    avatar: 'H',
    avatarColor: '#6F9FC4',
    user: 'Hari',
    action: 'added',
    details: 'Charger_123456 in Charger Selection',
    timestamp: '18 Dec 2024 12:05 PM',
  },
  {
    id: 3,
    avatar: 'H',
    avatarColor: '#6F9FC4',
    user: 'Hari',
    action: 'added',
    details: 'Set Parameter Action Control in "Then" path',
    timestamp: '18 Dec 2024 12:10 PM',
  },
  {
    id: 4,
    avatar: 'JC',
    avatarColor: '#A16DC4',
    user: 'Jhalki Chauhan',
    action: 'changed the',
    details: 'Schedule Time Trigger Point from One Time: 09 Dec 2024 12:00 PM to One Time: 09 Dec 2024 1:15 PM',
    timestamp: '18 Dec 2024 12:10 PM',
  },
  {
    id: 5,
    avatar: 'JC',
    avatarColor: '#A16DC4',
    user: 'Jhalki Chauhan',
    action: 'has Enabled',
    details: 'the rule and changed the status from Draft to Publish',
    timestamp: '18 Dec 2024 12:10 PM',
  },
];

interface ExecutionSummarySidePopoverProps {
  ruleId: string;
}

const ExecutionLogsSidePopover = memo(function ExecutionSummarySidePopover({ ruleId }: ExecutionSummarySidePopoverProps) {
    console.log(ruleId)
  return (
    <Box p={2} style={{ height: 'calc(100dvh - 150px)', overflowY: 'auto',marginLeft:'-15px' }}>
      <List>
        {logs.map((log) => (
          <React.Fragment key={log.id}>
            <ListItem alignItems='flex-start' disableGutters>
              <Grid container alignItems='center'>
                {/* Avatar */}
                <Grid item xs='auto' sx={{marginRight:'5px'}}>
                  <Avatar sx={{ bgcolor: log.avatarColor, fontWeight: 'bold', width: 25, height: 25, fontSize: '0.875rem'}}>
                    {log.avatar}
                  </Avatar>
                </Grid>
                {/* Text Content */}
                <Grid item xs>
                  <Typography variant='body2' component='span' sx={{fontSize:'12px'}}>
                    <strong>{log.user}</strong> {log.action} <strong>{log.details}</strong>
                  </Typography>
                </Grid>
                {/* Timestamp */}
                <Grid item xs='auto'>
                  <Typography variant='caption' color='textSecondary' style={{ whiteSpace: 'nowrap' }}>
                    {log.timestamp}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component='li' />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
});

export default ExecutionLogsSidePopover;