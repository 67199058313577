/* eslint-disable @typescript-eslint/ban-types */
import React, {  useEffect, useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Handle, Position } from '@xyflow/react';
import CloseIcon from '@mui/icons-material/Close';
import { SidePopover, } from '../../../globalUtils/globalExports';
import SendNotificationSidePopOver from './SendNotificationSidePopOver';
import './ActionControlNode.css'
import ExecutionPlan, { SendNotificationAction } from './ExecutionPlan';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';

interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        executionPlan: ExecutionPlan;
        handleUpdateExecutionPlan: Function;
      ifOrElse?: string
      removeNode?: Function
      id?: string
      typeOfNode:string
        
      };
}

const SendNotificationNode: React.FC<CustomNodeProps> = ({data}:CustomNodeProps ) => {

  const handleEditClick = () => {
    setIsSendNotificationPopoverOpen(true);
  };
  const [isSendNotificationPopoverOpen, setIsSendNotificationPopoverOpen] = useState(false);
  const [sendNotification,setSendNotification] = useState<SendNotificationAction>({
        type: '',
        notificationType: [],
        recipients: [],
        recipientsName: [],
        emailContent: ''
  })

  const handleCloseSendNotificationPopover = ()=>{
    setIsSendNotificationPopoverOpen(false);
  }

  useEffect(() => {
    const currentSendNotification = data?.executionPlan?.action?.find((actionItem)=> actionItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Send Notification') as SendNotificationAction; //  assuming that in the if block
    setSendNotification(currentSendNotification)
  },[data?.executionPlan?.action?.find((actionItem)=> actionItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Send Notification') as SendNotificationAction])

    return (
      <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
       
    <Paper
      elevation={3}
      sx={{
        border: '1px solid #0080DC',
        borderLeft: '6px solid #0080DC',
        borderRadius: '8px',
        padding: '8px 16px',
        width: '350px !important',
        backgroundColor: '#fff',
        position: 'relative',
        marginBottom: '16px',
        minHeight:'110px'
      }}
      >
   <IconButton
    size="small"
    onClick={() => {
      if (data?.removeNode) {  
        data.removeNode(data?.id,data?.ifOrElse,data?.typeOfNode);   
      } 
    }}
    sx={{ position: 'absolute', top: 0, right: 0 }}
     >
    <CloseIcon fontSize="small" sx={{ color: '#666' }} />
  </IconButton>
      <Handle type="target" className='transparent-handle' position={Position.Left} id = 'left' />
      <Box display="flex" alignItems="center" marginBottom="8px">
        
      <IconWithTooltip iconClassName={'send-notification-sidebar-1'} />
      <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            marginLeft:'8px'
          }}
        >
          { 'Send Notification'}
        </Typography>
      </Box>

      {/* Category and Sub Category */}
      <Box
        sx={{
          backgroundColor: '#f0f4ff',
          padding: '8px',
          borderRadius: '4px',
                position: 'relative',
        height:'50px !important',

        
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap='40px'>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
          <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
            Mode: <strong>{ sendNotification && sendNotification?.notificationType && <div className='rule__engine__define__execution__selected__parameters'>{sendNotification?.notificationType}</div>}</strong>
          </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
            Recipients: <strong>{ sendNotification && sendNotification?.recipients?.length > 0 && <div className='rule__engine__define__execution__selected__parameters'>{sendNotification?.recipients?.length}</div>}</strong>
          </Typography>
        </Box>
        {/* {format === 'ticket' && (
          <Box display="flex" alignItems="center" marginTop="4px">
            <FiberManualRecordIcon fontSize="small" sx={{ color: '#4a90e2', fontSize: '0.75rem', marginRight: '4px' }} />
            <Typography variant="body2" sx={{ fontSize: '0.875rem', color: '#666' }}>
              Sub Category: <strong>{subCategory || '(Not specified)'}</strong>
            </Typography>
          </Box>
        )} */}
        {/* Pencil icon */}
        <IconButton
          size="small"
          onClick={handleEditClick}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
        </IconButton>
      </Box>

      {/* Add Activity link */}
 
     </Paper>
    {data.actionButtonVisible && 
    <Typography
    onClick={() => data.addNodeToCanvas()}
    variant="body2"
    sx={{
      color: '#B0B1B6',
      marginTop: '8px',
      marginLeft: '10px',
      cursor: 'pointer',
      fontSize: '0.875rem',
    }}
  >
    + Add Activity
    </Typography>
      }
      </div>
      {isSendNotificationPopoverOpen && <SidePopover
         id='rule__engine__execution__plan__action__control__send__notification__popover'
         open={isSendNotificationPopoverOpen}
         headerText={`Action Control - ${data.ifOrElse === 'If' ? 'Then' : 'Else'}`}
         containerClassName='configure__notification__detail__popover'
         handleClose={()=>{}}
         headerSecondaryText={'Send Notification'}
         popperClassName='rule__engine__send__notification'
         headerClassName={'rule__engine__header__config'}
         isRuleEnginePopOver={true}
         ruleEngineSidePopoverOnclose={handleCloseSendNotificationPopover}
         subHeadingIconClassName={'send-notification-sidebar'}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {<SendNotificationSidePopOver 
                  sendNotification={sendNotification?? null}
                  handleClose={handleCloseSendNotificationPopover} 
                  executionPlan={data?.executionPlan}
                  handleUpdateExecutionPlan={data?.handleUpdateExecutionPlan}   
                  ifOrElse={data?.ifOrElse??''}
                
              />}
            </>
        } 
         />
       }

      </>

  );
};

export default SendNotificationNode;
