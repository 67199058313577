/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDnD } from './dndContext';
import CircleIcon from '@mui/icons-material/Circle';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';


interface SidebarProps {
  nodes: Array<{ type: string }>; 
}
const Sidebar: React.FC<SidebarProps> = ({ nodes }) => {
  const [_, setType] = useDnD();
    // Check if a Trigger node exists
    const hasTrigger = nodes.some((node) => (node.type === 'parameter' || node.type === 'alarm' ||  node.type === 'scheduler'));

    // Check if a Flow node (e.g., 'then', 'ifThen') exists
    const hasFlow = nodes.some((node) => ['thenNode', 'ifThen', 'ifThenElse'].includes(node.type));
  
    // Check if both a Trigger and Flow exist to disable further additions
  const disableTriggerSection = hasTrigger;
    const disableFlowSection = !hasTrigger || hasFlow;
    const disableActionSection =  !hasFlow;

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    if (setType != null) {
      setType(nodeType);
    }
    event.dataTransfer.effectAllowed = 'move';

  };

    // Utility function to count occurrences of each type
    const getNodeCount = (type) => nodes.filter(node => node.type === type).length;

    // Counts for specific nodes
  const setParameterCount = getNodeCount('actionControlSetParameter');
  const raiseTicketCount = getNodeCount('actionControlRaiseATicket');
  const sendNotificationCount = getNodeCount('sendNotification');
  const actionControlRemoteCommandCount = getNodeCount('actionControlRemoteCommand');
  const disableSetParameter = setParameterCount >= 2;
  const disableRaiseTicket = raiseTicketCount >= 2;
  const disableSendNotification = sendNotificationCount >= 2;
  const disableActionControlRemoteCommand = actionControlRemoteCommandCount >= 2;
  

  return (
    <aside className="sidebar">
      <div className="description">
        Drag & drop activity on the right panel for execution.
      </div>

      {/* Trigger Point Section */}
          <div className="section">
        <div className='flexGap'> 
        <IconWithTooltip iconClassName={'trigger-point-sidebar'} />
          <h4 className="section-title">
             Trigger Point
          </h4>
          <IconWithTooltip
  iconClassName="rms_info_icon-black"
  containerClassName="user__form_footer-auto-assign-info"
  toolTipText={
    <>
      Rule execution follows this logic, if the trigger condition persists:
      <ul style={{ listStyleType: 'none', margin: 0, paddingLeft: 0 }}>
        <li style={{ marginBottom: '5px' }}><b>Parameters:</b> Only once per hour (hard-coded)</li>
        <li style={{ marginBottom: '5px' }}><b>Alarms:</b> Only when a new Alarm is set</li>
        <li style={{ marginBottom: '5px' }}><b>Schedule Time:</b> Only at the scheduled time (One-time or Recurring)</li>
      </ul>
    </>
  }
/>
        </div>
        <div
         className={`dndnode input ${disableTriggerSection ? 'disabled' : ''}`}
          onDragStart={(event) => onDragStart(event, 'parameter')}
          draggable
         >
        <div className="custom-margin">   
            <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'trigger-parameters-sidebar'} />
          </div>
          <span className ={'node_text'}>

          Parameters
          </span>
        </div>
        <div
         className={`dndnode input ${disableTriggerSection ? 'disabled' : ''}`}
          onDragStart={(event) => onDragStart(event, 'alarm')}
          draggable

              >
        <div className="custom-margin">
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'trigger-alarms-sidebar-1'} />
          </div>
          <span className ={'node_text'}>
          Alarms
          </span>
        </div>
        <div
         className={`dndnode input ${disableTriggerSection ? 'disabled' : ''}`}
          onDragStart={(event) => onDragStart(event, 'scheduler')}
          draggable

              >
        <div className="custom-margin">
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'scheduleTime-sidebar'} />
          </div>
          <span className ={'node_text'}>

          Schedule Time
          </span>
        </div>
      </div>

      {/* Flow Controls Section */}
      <div className={`section ${disableFlowSection ? 'disabled' : ''}`}>
         <div className='flexGap'>    
          <CircleIcon fontSize='small'sx={{ color: '#8f8dff',fontSize:'14px' }}/>  
            <h4 className="section-title">
            Flow Controls
            </h4>
        </div>
        {/* Dont need it now */}

          <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'then')}
          draggable

              >
        <div className="custom-margin">   
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'action_control_cogs'} />
          </div>
          <span className ={'node_text'}>

       Then
          </span>
        </div>
        <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'ifThen')}
          draggable

              >
        <div className="custom-margin"> 

        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'action_control_cogs'} />
           
          </div>
          <span className ={'node_text'}>
          If - Then
          </span>
        </div>
        <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'ifThenElse')}
          draggable={!disableFlowSection}>
        <div className="custom-margin">   
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'action_control_cogs'} />
          </div>
          <span className ={'node_text'}>

         If - Then - Else
          </span>
        </div>
        {/* <div
          className="dndnode default"
          onDragStart={(event) => onDragStart(event, 'thenElse')}
          draggable
              >
        <div className="custom-margin">   
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'action_control_cogs'} />
          </div>
          <span className ={'node_text'}>

         Then - Else
          </span>
        </div> */}

      </div>

      {/* Action Controls Section */}
      <div className={`section ${disableActionSection ? 'disabled' : ''}`}>
        <div className='flexGap'>
          <CircleIcon fontSize='small'sx={{ color: '#0080DC',fontSize:'14px' }}/>
          <h4 className="section-title">
          Action Controls
          </h4>
        </div>

        {/* Set Parameter */}
         <div
           className={`dndnode output ${disableSetParameter ? 'disabled-node' : ''}`}
          onDragStart={(event) => onDragStart(event, 'actionControlSetParameter')}
          draggable
           >
        <div className="custom-margin">   
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'set-parameter-sidebar'} />
          </div>
          <span className ={'node_text'}>

        Set Parameter
          </span>
        </div>

        {/* Remote Command */}
        <div
        className={`dndnode output ${disableActionControlRemoteCommand ? 'disabled-node' : ''}`}
        onDragStart={(event) => onDragStart(event, 'actionControlRemoteCommand')}
        draggable
 >
        <div className="custom-margin">
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'remote-command-sidebar'} />
        </div>
          <span className ={'node_text'}>
         Remote Command
          </span>      
        </div>

        {/* Raise A Ticket */}
        <div
          className={`dndnode output ${disableRaiseTicket ? 'disabled-node' : ''}`}
          onDragStart={(event) => onDragStart(event, 'actionControlRaiseATicket')}
          draggable
         >
        <div className="custom-margin">
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'raise-a-ticket-sidebar'} />
          </div>
          <span className ={'node_text'}>
          Raise a Ticket
          </span>
        </div>

        {/* Send notification */}
        <div
         className={`dndnode output ${disableSendNotification ? 'disabled-node' : ''}`}

          onDragStart={(event) => onDragStart(event, 'sendNotification')}
          draggable
              >
        <div className="custom-margin">  
        <IconWithTooltip iconClassName={'sidebar-handle'} />
        <IconWithTooltip iconClassName={'send-notification-sidebar'} />
          </div>
          <span className ={'node_text'} style={{marginLeft:'4px'}}>
          Send Notification
          </span>
        </div>
      
      </div>
    </aside>
  );
};

export default Sidebar;

