
import { Box, Chip, ThemeProvider } from '@mui/material';
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomDialog, CustomSwitch,CustomTooltipWithLabel,DashboardHeader, DashboardLoader, FilterLayout, FilterProp, HorizontalNavbar,LinkButton,SearchBox, SidePopover, TableDashboardTheme, TableLayout, TableSubHeader, alarmCardMapper, formatRelativeTimeForTable, renderHeaderActionButtons, useAppDispatch, useAppSelector, useEffectOnce } from '../../globalUtils/globalExports';
import {  useNavigate, } from 'react-router-dom';
import { ActionIcons, IconWithTooltip } from '../../globalUtils/globalIcons';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import access from '../../auth/service/AccessControl';
import { ruleEnginePopOverNavList, ruleEngineStatusMapper } from './ruleEngineConstant';
import { fetchRuleEngineData, setRuleEngineHistoryListTableProps,fetchRuleEngineHistoryData,getCurrentFiltersValues, getFilterReducer, getFilterSelectedValue, getReferenceDataReducer, getRuleEngineReducer, ruleEngineTypes, setFilterData, setTableColumnPrefrences, getGlobalReducer, getCurrentScreenAppliedFiltersCount, chargerTypes, fetchChargerRuleEngineTriggerCategoryReferenceData, fetchChargerRuleEngineTriggerLevelReferenceData, fetchChargerRuleEngineActionControlReferenceData, fetchChargerRuleEngineScheduleReferenceData, fetchChargerRuleEngineStatusReferenceData, fetchChargerRuleEngineRuleNameReferenceData, fetchChargerStationReferenceData } from '../../rmsReduxStore/reduxExports';
import { cloneDeep,} from 'lodash';
import { clearRuleEngineFormRedux, deleteRuleEngine, setLatestRuleOnTop, setRuleEngineListTableProps, setRuleEngineStatus } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineCreator';
import { Id, toast } from 'react-toastify';
import './RuleEngine.css'
import { NoDataComponentRuleEngine, NoDataComponentRuleEngineHistory } from '../../globalUtils/TableDashboard/TableComponents';
import RuleEngineGrid from '../../globalUtils/GridViewCards/GridCards';
import { IRuleEngine, IRuleEngineTableProps } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineType';

const RuleEngineDashboard = memo(function RuleEngineDashboard(){
  const dispatch = useAppDispatch();
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  const {
    ruleEngineTriggerLevelReferenceData,
    ruleEngineActionControlReferenceData,
    // ruleEngineScheduleReferenceData,
    ruleEngineTriggerCategoryReferenceData,
    ruleEngineStatusReferenceData,
    chargingStationReferenceData,
    chargerVisibleIdReferenceData,
    ruleEngineRuleNameReferenceData,
  } = useAppSelector(getReferenceDataReducer);
  const { ruleEngineLayoutView } = useAppSelector(getRuleEngineReducer);
  const { ruleEngine,getLatestRuleOnTop, ruleEngineListTableProps ,ruleEngineCount, ruleEngineTableLoader,ruleEngineHistory,ruleEngineHistoryListTableProps,ruleEngineHistoryCount,ruleEngineFiltersAppliedFromRedirection} = useAppSelector(getRuleEngineReducer)
  const navigate = useNavigate();
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [layoutView, setLayoutView] = useState<string>(ruleEngineLayoutView ? ruleEngineLayoutView : 'list');
  const [selectedNav, setSelectedNav] = useState<'ruleEngine' | 'ruleEngineHistory'>('ruleEngine');
  const [selectedChip, setSelectedChip] = useState(getFilterSelectedValue('ruleEngineChipStatus', 'ruleEngine', false)?.[0].id ?? 'All');
  const [selectedRuleEngineId, setSelectedRuleEngineId] = useState<string | null>(null);
  const tableRef = useRef<HTMLDivElement>(null)
  const headerWrapRef = useRef<HTMLDivElement>(null)
  const searchWrapRef = useRef<HTMLDivElement>(null)
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const subHeaderRef = useRef<HTMLDivElement>(null)
  const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
  const [tableWrapHeight, setTableWrapHeight] = useState(0)
  const ruleEngineRef = useRef(ruleEngine);
  const ruleEngineHistoryRef= useRef(ruleEngineHistory)
  const ruleEngineListTablePropsRef = useRef(ruleEngineListTableProps);
  const ruleEngineHistoryListTablePropsRef = useRef(ruleEngineHistoryListTableProps);
  const [filtersVisible,setFiltersVisible] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isNoProductTypeDialogOpen, setIsNoProductTypeDialogOpen] = useState(false);
  const [deleteLoader,setDeleteLoader] = useState<boolean>(false);
  
   // TODO: Callback wasn't taking the updated value
   useEffect(() => {
    ruleEngineListTablePropsRef.current = ruleEngineListTableProps;
  }, [ruleEngineListTableProps]);

  useEffect(() => {
    ruleEngineHistoryListTablePropsRef.current = ruleEngineHistoryListTableProps;
  }, [ruleEngineHistoryListTableProps]);
  
  const updateSize = useCallback(() => {
    setForceRefreshId(new Date().getTime())
  }, [])

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      updateSize()
    })
    if (tableRef.current != null) {
      ro.observe(tableRef.current)
    }
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])

  useEffect(() => {
    const calculateTableWrapHeight = (): void => {
      if (((headerWrapRef?.current) != null) && ((searchWrapRef?.current) != null) && ((tableWrapRef?.current) != null)) {
        const totalHeight = tableWrapRef?.current?.getBoundingClientRect?.().height
        const headerHeight = headerWrapRef?.current?.getBoundingClientRect?.().height
        const searchHeight = searchWrapRef?.current?.getBoundingClientRect().height
        const subHeaderHeight = subHeaderRef?.current?.getBoundingClientRect?.().height ?? 0
        const tableWrapHeight = totalHeight - headerHeight - searchHeight - subHeaderHeight
        setTableWrapHeight(tableWrapHeight)
      }
    }
    calculateTableWrapHeight()
    window.addEventListener('resize', calculateTableWrapHeight)
    return () => {
      window.removeEventListener('resize', calculateTableWrapHeight)
    }
  }, [])

  useEffectOnce(()=>{
    dispatch(fetchChargerRuleEngineTriggerCategoryReferenceData());
    dispatch(fetchChargerRuleEngineTriggerLevelReferenceData());
    dispatch(fetchChargerRuleEngineActionControlReferenceData());
    dispatch(fetchChargerRuleEngineScheduleReferenceData());
    dispatch(fetchChargerRuleEngineStatusReferenceData());
    dispatch(fetchChargerStationReferenceData());
    dispatch(fetchChargerRuleEngineRuleNameReferenceData())
  })

  const ruleEngineCleanUpStates = useCallback(() => {
    const action = {
      type: ruleEngineTypes.CLEAR_RULE_ENGINE_LIST_DATA,
    }
    const action1 = {
      type: ruleEngineTypes.CLEAR_RULE_ENGINE_HISTORY_LIST_DATA,
    }
    dispatch(action);
    dispatch(action1);
    dispatch(setLatestRuleOnTop(false));
    if (ruleEngineFiltersAppliedFromRedirection) {
      onResetButtonClick(false)
    } 
  }, [ruleEngineFiltersAppliedFromRedirection])

  useEffect(() => {
    window.addEventListener('beforeunload', ruleEngineCleanUpStates);
    return (): void => {
      ruleEngineCleanUpStates()
      window.removeEventListener('beforeunload', ruleEngineCleanUpStates);
    }
  }, [])

  const handleLayoutToggle = useCallback(async (newLayoutVal,) => {
    setLayoutView(newLayoutVal);
    const action = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LAYOUT_VIEW,
      ruleEngineLayoutView: newLayoutVal 
    }
    dispatch(action)
  }, [ruleEngineRef?.current,ruleEngineHistoryRef?.current])

  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list')
        },
        layout: 'list',
        iconClassName: 'configure__notification__header__list__icon'
      },
      {
        toolTipText: 'Grid View',
        onIconClick: (): void => {
          handleLayoutToggle('grid')
        },
        layout: 'grid',
        iconClassName: 'configure__notification__header__grid__icon'
      },
    ]
  }, [handleLayoutToggle])

  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'create_new_rule_btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {
            dispatch(clearRuleEngineFormRedux()); /// clearing the entire rule engine form redux when create Rule button clicked
            navigate('/new-rule', { state: { mode: 'Define' } });
          }
        },
        {
          id: 'add_filter_btn',
          isAccessible: true,
          headerActionBtnClick: (): void => {   
            setFiltersVisible(true);
          },
          badgeCount: getCurrentScreenAppliedFiltersCount(selectedNav,selectedNav === 'ruleEngine' ? true : false)
        },
      ]
  },)

  const onChangeOfSearchText = useCallback(async (val) => {
    if (selectedNav === 'ruleEngine' && ruleEngineListTablePropsRef?.current) {
      await layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, val)
    }
    else{
      await layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, null, val)
    }
  }, [selectedNav,layoutView,ruleEngineListTablePropsRef?.current, ruleEngineHistoryListTablePropsRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-rule-engine-search-box',
      searchFieldName: 'manage-rule-engine-search-box',
      searchBoxClassName: 'manage-rule-engine-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [selectedNav,onChangeOfSearchText, layoutView,ruleEngineListTablePropsRef?.current,]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEditIconClick = (rowData) => {
    navigate('/new-rule', { state: { ruleId: rowData.id, mode: 'Edit', rowData: rowData,layoutView:layoutView } })
    
  }

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedRuleEngineId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon', isAccessible: access?.users?.edit(), action: (_, rowData) => onEditIconClick(rowData) },
    { icon: 'deleteIcon', isAccessible: access?.users?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
     }, [onDeleteIconClick]);

  const handleNavBtnClick = useCallback(async (val) => {
      setSelectedNav(val);
      if (val === 'ruleEngine' && ruleEngineListTablePropsRef?.current) {

        const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineListTablePropsRef?.current)
  
        if (tableProps) {
          tableProps.view =  layoutView
          tableProps.sortBy = ruleEngineListTablePropsRef?.current?.sortBy;
          tableProps.order = ruleEngineListTablePropsRef?.current?.order;
          tableProps.pageNumber = ruleEngineListTablePropsRef?.current?.pageNumber;
          tableProps.pageSize = ruleEngineListTablePropsRef?.current?.pageSize;
          tableProps.searchTerm = '';
  
        }
        await dispatch(setRuleEngineListTableProps(tableProps))
      } else if(val === 'ruleEngineHistory' && ruleEngineHistoryListTablePropsRef?.current){
        const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineHistoryListTablePropsRef?.current)
  
        if (tableProps) {
          tableProps.view =  layoutView
          tableProps.sortBy = ruleEngineHistoryListTablePropsRef?.current?.sortBy;
          tableProps.order = ruleEngineHistoryListTablePropsRef?.current?.order;
          tableProps.pageNumber = ruleEngineHistoryListTablePropsRef?.current?.pageNumber;
          tableProps.pageSize = ruleEngineHistoryListTablePropsRef?.current?.pageSize;
          tableProps.searchTerm = '';
  
        }
        await dispatch(setRuleEngineHistoryListTableProps(tableProps))
      }


      // if(val === 'ruleEngineHistory'){
      //   layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, null, '');
      // //   if (ruleEngine || ruleEngineCount) {
      // //     const reportAction = {
      // //         type: ruleEngineTypes.CLEAR_RULE_ENGINE_LIST_DATA,
      // //     }
      // //     await dispatch(reportAction);          
      // //  }
      // } else if(val === 'ruleEngine'){
      //   layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, '');
      // }
  }, [selectedNav,]);

  const navBarconfigurations = useMemo(() => {
      return {
          selectedNav: selectedNav,
          navList: ruleEnginePopOverNavList?.filter((nav) => nav?.isAccessible),
          onNavClick: handleNavBtnClick
      }
  }, [selectedNav,ruleEngine,ruleEngineHistory,ruleEngineHistoryCount,ruleEngineCount])  

const renderStatusCell = (params: GridRenderCellParams<GridValidRowModel, Date>) => {
  const rowData = params?.row;
  const [localStatus, setLocalStatus] = useState(rowData?.status === 'Enabled');

  const handleToggleChange = async (e: React.MouseEvent) => {
    e.stopPropagation();
    // Optimistically toggle the switch
    if(rowData?.runningStatus === 'Draft'){
      dateRangeExceededErrorToast('Rule is in Draft Mode');
      return;
    }
    setLocalStatus((prevStatus) => !prevStatus);
    const newStatus = !localStatus ? 'Enabled' : 'Disabled';
    try {
      const updatedRuleEngine = cloneDeep(ruleEngine);
      const rowToUpdate = updatedRuleEngine?.find((row) => row?.id === rowData?.id);
      if (rowToUpdate) rowToUpdate.status = newStatus;

      dispatch({
        type: ruleEngineTypes.FETCH_RULE_ENGINE,
        ruleEngine: updatedRuleEngine,
        ruleEngineCount: ruleEngineCount,
      });
      await dispatch(setRuleEngineStatus({
        id: rowData?.id,
      })).then(()=>{
        layoutCallback(ruleEngineListTablePropsRef?.current?.pageNumber, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, ruleEngineListTablePropsRef?.current?.searchTerm);
      })

    } catch (error) {
      console.error('Failed to update rule status:', error);
      setLocalStatus((prevStatus) => !prevStatus); // reverted toggle in the UI if API fails
    }
  };

  return (
    <CustomSwitch
      switchId="rule__engine__single__notification__status-switch"
      switchClassName="rule__engine__single__notification__status-switch"
      checked={localStatus}
      switchLabelName={localStatus ? 'Enabled' : 'Disabled'}
      handleClick={handleToggleChange}
      isDisabled={false}
    />
  );
};

const handleToggleChange = (id:string) => {
  const status = ruleEngine?.find((rule)=> rule?.id===id)?.status
  const runningStatus = ruleEngine?.find((rule)=> rule?.id===id)?.runningStatus;
  if(runningStatus === 'Draft'){
    dateRangeExceededErrorToast('Rule is in Draft Mode');
    return false;
  }else{
    const newStatus = !status? 'Enabled' : 'Disabled';
  try {
    const updatedRuleEngine = cloneDeep(ruleEngine);
    const rowToUpdate = updatedRuleEngine?.find((row) => row?.id === id);
    if (rowToUpdate) rowToUpdate.status = newStatus;
    dispatch({
      type: ruleEngineTypes.FETCH_RULE_ENGINE,
      ruleEngine: updatedRuleEngine,
      ruleEngineCount: ruleEngineCount,
    });
     dispatch(setRuleEngineStatus({
      id: id,
    })).then(()=>{
      layoutCallback(ruleEngineListTablePropsRef?.current?.pageNumber, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, ruleEngineListTablePropsRef?.current?.searchTerm);

    })
    return true;
  } catch (error) {
    console.error('Failed to update rule status:', error);
    return false;
  }
  }
  
};

const tableColumns = useMemo(() => {
return  [
  {
    field:'runningStatus',
    headerName:'',
    flex:1,
    maxWidth:40,
    minWidth:40,
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      const iconClass= ruleEngineStatusMapper[rowData?.runningStatus]?.icon
      return(
        <IconWithTooltip iconClassName={iconClass} toolTipText={rowData?.runningStatus}/>
      )
    }
  },
  {
    field:'error',
    headerName:'',
    flex:1,
    maxWidth:40,
    minWidth:40,
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      const iconClass= ruleEngineStatusMapper['Error']?.icon
      return(
        (rowData?.error===null || rowData?.error.length === 0) ? <div>{''}</div> :
        <IconWithTooltip iconClassName={iconClass} toolTipText={'Error'}/> 
      )
    }
  },
  {
      field: 'name',
      headerName: 'Rule Name',
      flex: 1,
      minWidth: 150,
      hideable: false,
      pinnable: false,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      // const iconClass= ruleEngineStatusMapper[rowData?.runningStatus]?.icon
      return (
        <div 
        className="rule__name__status rule__engine__highlighted__text"
        onClick={(e)=>{
          navigate('/new-rule', { state: { mode: 'View', rowData: rowData, layoutView:layoutView } })
          e.stopPropagation();
        }}>
        
          {/* <IconWithTooltip iconClassName={iconClass} toolTipText={rowData?.runningStatus}/> */}
          {/* <div style={{width: '80%'}}> */}
          <CustomTooltipWithLabel label={rowData?.name} labelId='rule__engine__rule__name' />
          {/* </div> */}
        </div>
      )
    },
  },
  {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
        const rowData = params?.row;
        return (
         
            <CustomTooltipWithLabel label={rowData?.description} labelId='rule__engine__rules__description' />
        );
      },
    },
    {
    field: 'triggerType',
    headerName: 'Category',
    flex: 1,
    minWidth: 120,
    maxWidth: 120,
    align: 'center',
    renderCell:(params :GridRenderCellParams<GridValidRowModel,Date>):JSX.Element=>{
      const rowData = params?.row;
      if (rowData?.triggerType === 'Alarm') {
        const severityIcon = rowData?.triggerAlarmCategory?.toUpperCase();
        const tooltipText = alarmCardMapper[severityIcon]?.title;
        return (
       
        <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
  
        );
      }else if(rowData?.triggerType === 'Scheduler'){
        return(
          <IconWithTooltip iconClassName={'scheduleTime-sidebar'} toolTipText={'Schedule Time'}/>
        )
      }
      else {
        return (
          <IconWithTooltip iconClassName='trigger-parameters-sidebar' toolTipText={'Parameter'}/>
        )
        }
    }
    },
    {
    field: 'triggerLevel',
    headerName: 'Trigger Level',
    flex: 1,
    minWidth: 180,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
        const rowData = params?.row;
        let triggerLevel='';
        if(rowData?.triggerType === 'Parameter')
         triggerLevel = rowData?.triggerParamName;
        else if(rowData?.triggerType === 'Alarm')
          triggerLevel = rowData?.triggerAlarmName;
        else if(rowData?.triggerType === 'Scheduler')
          triggerLevel = rowData?.scheduleType + ' Schedule';
          return (
          <CustomTooltipWithLabel label={triggerLevel??''} labelId={'rule__engine__dashboard__trigger__level'}/>
          );
      },
    },
    // {
    //     field: 'customer',
    //     headerName: 'Customer',
    //     flex: 0.5,
    //     minWidth: 150,
    //     sortable: true,
    // },
    {
        field: 'action',
        headerName: 'Action Controls',
        flex : 1,
        minWidth : 200,
        headerAlign: 'left',
        align: 'left',
    },
    // {
    //   field: 'scheduleType',
    //   headerName: 'Schedule',
    //   flex: 0.5,
    //   minWidth: 150,
    //   sortable: true,
    // },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.7,
      minWidth: 140,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
        const rowData = params?.row;
        return rowData?.createdAt ? formatRelativeTimeForTable(new Date(rowData?.createdAt)) : '-';
      },
    },
    {
      field: 'status',
      headerName: 'Enable/Disable',
      flex:1,
      minWidth: 150,
      renderCell: renderStatusCell,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
        const rowData = params?.row;
        return (
          <div className='configure__notification__dashboard__action__icons__wrap'>
            <ActionIcons actionIconsList={actionIconsList} data={rowData} />
          </div>
        );
      },
    }
  ]

},[])

const tableColumnsHistory = useMemo(() => {
return  [
  {
    field: 'fireTime',
    headerName: 'Fire Time',
    flex: 0.5,
    minWidth: 180,
    sortable: true,
    hideable: false,
    pinnable: false,
    renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
      const rowData = params?.row;
      return rowData?.fireTime ? formatRelativeTimeForTable(new Date(rowData?.fireTime)) : '-';
    },
  },
  {
      field: 'ruleName',
      headerName: 'Rule Name',
      flex: 1,
      minWidth: 120,
      sortable: true,
      hideable: false,
      pinnable: false,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      return (
        <div className='rule__engine__column__normal__text'>
          <CustomTooltipWithLabel label={rowData?.ruleName} labelId={'rule__engine__history__rule__name'}/>
        </div>
      )
    },
  },
  {
    field: 'triggerType',
    headerName: 'Category',
    flex: 1,
    minWidth: 120,
    maxWidth: 150,
    sortable: true,
    align: 'center',
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      if (rowData?.category) {
        const severityIcon = rowData?.category?.toUpperCase();
        const tooltipText = alarmCardMapper[severityIcon]?.title;
        return (
        <>
        <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
        </> 
        );
      }
      else if(rowData?.triggerType === 'Scheduler'){
        return(
          <IconWithTooltip iconClassName={'scheduleTime-sidebar'} toolTipText={'Schedule Time'}/>
        )
      }
      else {
        return (
          <>
        <IconWithTooltip iconClassName='trigger-parameters-sidebar' toolTipText={'Parameter'}/>
        </>
        )
      }
    },
  },
  {
    field: 'triggerLevel',
    headerName: 'Trigger Level',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      let triggerLevel='';
      if(rowData?.triggerType === 'Parameter')
       triggerLevel = rowData?.parameterName;
      else if(rowData?.triggerType === 'Alarm')
        triggerLevel = rowData?.alarmName;
      else if(rowData?.triggerType === 'Scheduler')
        triggerLevel = rowData?.scheduleType + ' Schedule';
        return (
        <CustomTooltipWithLabel label={triggerLevel??''} labelId={'rule__engine__history__dashboard__trigger__level'}/>
        );
    },
  },
  {
      field: 'chargerId',
      headerName: 'Charger',
      flex: 1,
      minWidth: 180,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
        const rowData = params?.row;
        return (
          rowData?.chargerId ?
            (<div
              className="rule__engine__highlighted__text"
              onClick={(e) => {
                e?.stopPropagation()
                const action = {
                  type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                  isChargerTablePropsRetained: true
                }
                dispatch(action)
              }
              }
            >
              <LinkButton
                linkBtnId={rowData?.chargerId}
                link={'/charger?chargerId=' + rowData.chargerId}
                btnClassName="individual__charger__text"
                btnText={rowData?.chargerVisibleId}
                linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
              />
            </div>) : (<div onClick={() => setIsNoProductTypeDialogOpen(true)}>{rowData?.chargerVisibleId}</div>)
        );
      },
  },
  {
    field: 'chargingStationName',
    headerName: 'Charging Station',
    flex:1,
    minWidth: 180,
    sortable: true,
    renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      const rowData = params?.row;
      return (
        <div 
        className="rule__engine__highlighted__text"
        onClick={(e)=>{
          e.stopPropagation();
              navigate('/station', { state: { id: rowData?.chargingStationId, mode: 'View', stationName: rowData?.chargingStationName, } });
        }}>
           <CustomTooltipWithLabel label=  {rowData?.chargingStationName} labelId={'rule__engine__history__charging__station__name'} />
        </div>
      )
    },
  },
  {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        minWidth: 180,
        sortable: true,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
        const rowData = params?.row;
        return (
          <div 
          className="rule__engine__highlighted__text"
          onClick={(e)=>{
            e.stopPropagation();
                navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customerName } });
          }}>
            <CustomTooltipWithLabel label= {rowData?.customerName} labelId={'rule__engine__history__customer__name'} />
             
          </div>
        )
      },
  },
  {
      field: 'connectorType',
      headerName: 'Connector',
      flex: 1,
      minWidth: 180,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel,Date>): ReactNode => {
        const rowData = params?.row;
        return rowData?.connectorType ? <CustomTooltipWithLabel label={rowData?.connectorType} labelId={'rule__engine__history__connector'}/>  : '-';
      },

  },
  {
      field: 'actionStatus',
      headerName: 'Action Controls Status',
      flex: 0.8,
      minWidth: 300,
      sortable: true,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
        const rowData = params?.row
        const val= rowData?.actionStatus  ||  {}
        const list: string[]=[];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        list.push(...Object.entries(val).map(([key,value])=> (key as string)));
        const textVal  = list?.join(', ')
        const toolTipText : string[]=[]
        Object.entries(val).map(([key,value])=> (toolTipText.push(key+': '+value)))
        const status = Object.values(rowData?.actionStatus).map(status => status === 'Completed').every(Boolean);
        return (
          <div className='rule__name__status'>
             <IconWithTooltip iconClassName={ status ? 'status__active__user__icon' : 'status__inactive__user__icon' } toolTipText={toolTipText.join(', ')}/>
             {textVal}
          </div>
        //  <CustomTooltipWithLabel label={`${textVal}`} labelId={'rule__engine__history__action__control__status'} />
      ); 
      },
  },
  ]

},[ruleEngine,ruleEngineHistory,selectedNav])

const dateRangeExceededErrorToast = (message): Id => toast.error(message);

const handleChipClick = (status: string) => {
  setSelectedChip(status);
  status=status.charAt(0).toUpperCase() + status.slice(1);
  const val={
    id:status,
    value:status,
    label:status,
    name:status
  }
  handleFilterDataChange(val,'ruleEngineChipStatus',false)
};

useEffect(() => {
  const statusFilterSelectedValues = getFilterSelectedValue('ruleEngineChipStatus', 'ruleEngine', false)
  if (statusFilterSelectedValues && statusFilterSelectedValues?.length > 0) {
  const value = statusFilterSelectedValues?.[0].id === 'All' ? 'All' : statusFilterSelectedValues?.[0].id;
  setSelectedChip(value)
  }
}, [screenFilters, selectedNav])
const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => { 

  const res = await setFilterData(val, filterKey, selectedNav === 'ruleEngine' ? 'ruleEngine' : 'ruleEngineHistory', 'SET', isGlobal ? isGlobal : false,false)(dispatch)
  if (res?.message === 'Action dispatched successfully') {
  const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'ruleEngineHistory', false);
    const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
    const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;

    if (startDate && endDate) {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays > 6) {
      dateRangeExceededErrorToast('Date range exceeded more than 5 days!!')
      return
    }
    } else if (startDate && !endDate) {
    dateRangeExceededErrorToast('Please select date range of 5 days!!')
    return
    }
    if (selectedNav === 'ruleEngine' && ruleEngineListTablePropsRef?.current) {
    await layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, res?.filters, ruleEngineListTablePropsRef?.current?.searchTerm)
    } 
    else{
    await layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, res?.filters, ruleEngineHistoryListTablePropsRef?.current?.searchTerm)
    }
  }
}, [selectedNav,ruleEngineListTablePropsRef?.current, ruleEngineHistoryListTablePropsRef?.current,dispatch, ruleEngineRef?.current,layoutView,ruleEngineHistoryRef?.current])


const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineListTablePropsRef.current)
  const filters = filterData ? filterData : getCurrentFiltersValues('ruleEngine')

await dispatch(fetchRuleEngineData({
  view: view ?? layoutView,
  sortBy: getLatestRuleOnTop ? 'createdAt' : (field || ruleEngineListTablePropsRef?.current?.sortBy),
  order: getLatestRuleOnTop ? 'descending' : order || ruleEngineListTablePropsRef?.current?.order,
  pageSize,
  pageNumber,
  filters: filters,
  searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ruleEngineListTablePropsRef?.current?.searchTerm
  }));
  if (tableProps) {
    tableProps.view = view || layoutView;
    tableProps.sortBy = field || ruleEngineListTablePropsRef?.current?.sortBy;
    tableProps.order = order || ruleEngineListTablePropsRef?.current?.order;
    tableProps.pageNumber = pageNumber;
    tableProps.pageSize = pageSize;
    if (searchTerm != null && searchTerm != undefined) {
    tableProps.searchTerm = searchTerm;
    }
  }
  await dispatch(setRuleEngineListTableProps(tableProps))
}, [layoutView, ruleEngineListTablePropsRef?.current,selectedNav,getLatestRuleOnTop,]);


const layoutCallbackHistory = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
  const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineHistoryListTablePropsRef.current)
  const filters = filterData ? filterData : getCurrentFiltersValues('ruleEngineHistory')
  await dispatch(fetchRuleEngineHistoryData({
      view: view ?? layoutView,
      sortBy: field || ruleEngineHistoryListTablePropsRef?.current?.sortBy,
      order: order || ruleEngineHistoryListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      filters: filters,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ruleEngineHistoryListTablePropsRef?.current?.searchTerm
    }));
  if (tableProps) {
    tableProps.view = view || layoutView;
    tableProps.sortBy = field || ruleEngineHistoryListTablePropsRef?.current?.sortBy;
    tableProps.order = order || ruleEngineHistoryListTablePropsRef?.current?.order;
    tableProps.pageNumber = pageNumber;
    tableProps.pageSize = pageSize;
    if (searchTerm != null && searchTerm != undefined) {
      tableProps.searchTerm = searchTerm;
    }
  }
  await dispatch(setRuleEngineHistoryListTableProps(tableProps))
}, [layoutView, ruleEngineHistoryListTablePropsRef?.current,selectedNav,ruleEngineHistory]);

const getTableRows = useCallback((): IRuleEngine[] => {
  return ruleEngine !== null && ruleEngine?.length > 0 ? ruleEngine : [];
}, [ruleEngine]);

const getTableRowsHistory = useCallback((): IRuleEngine[] => {
  return ruleEngineHistory !== null && ruleEngineHistory?.length > 0 ? ruleEngineHistory : [];
}, [ruleEngineHistory]);

const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
  if(selectedNav === 'ruleEngine'){
    await dispatch(setTableColumnPrefrences('ruleEngine', operation, data))
  } else if(selectedNav === 'ruleEngineHistory'){
    await dispatch(setTableColumnPrefrences('ruleEngineHistory', operation, data))
  }
 
}, [selectedNav])

const handlePageCountChange = async (count) => {
 await layoutCallback(ruleEngineListTablePropsRef?.current?.pageNumber,count, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, '')
}

const handleFiltersClosePopOver = (): void =>{
  setFiltersVisible(false);
}

  const handlePaginationChange = (page) => {
  layoutCallback(page, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, null)
}
const handleDeleteDialogClose = useCallback(() => {
  setIsDeleteDialogOpen(false);
  setSelectedRuleEngineId(null);
}, []);

const userDeletionSuccessToast = useCallback((): Id => {
  return toast.success('Rule deleted.');
}, []);

const userDeletionFailureToast = useCallback((message: string): Id => {
  return toast.warn(message ?? 'Something went wrong.');
}, []);

const handleDeleteRule = useCallback(async () => {
  setDeleteLoader(true)
  const { data, status } =
    await dispatch(deleteRuleEngine(
      selectedRuleEngineId
    ));
  if ((status === 200 || status === 202) && data?.message === 'Success') {
    setDeleteLoader(false);
    handleDeleteDialogClose();
    userDeletionSuccessToast();
    layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, null, '');
  } else {
    setDeleteLoader(false);
    handleDeleteDialogClose();
    userDeletionFailureToast(data?.message)
  }
}, [dispatch, selectedRuleEngineId, handleDeleteDialogClose, ruleEngineListTablePropsRef?.current,deleteLoader]);

const ruleEngineFilterProps: FilterProp[] = useMemo(() => {
  const ruleEngineFilter : FilterProp[]  =
      [
        {
          filterLabel: 'Trigger Category',
          filterType: 'dropdown',
          filterId: 'triggerCategory',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('triggerCategory', 'ruleEngine', false),
            selectOptions: ruleEngineTriggerCategoryReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'triggerCategory', false)
            },
            selectDropDownId: 'rule-engnie-trigger-category-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Trigger Level',
          filterType: 'dropdown',
          filterId: 'triggerLevel',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('triggerLevel', 'ruleEngine', false),
            selectOptions: ruleEngineTriggerLevelReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'triggerLevel', false)
            },
            selectDropDownId: 'rule-engine-trigger-level-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Action Controls',
          filterType: 'dropdown',
          filterId: 'actionControls',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('actionControls', 'ruleEngine', false),
            selectOptions: ruleEngineActionControlReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'actionControls', false)
            },
            selectDropDownId: 'rule-engnie-action-controls-filter-dropdown',
            isMultiSelect: true
          }
        },
        // {
        //   filterLabel: 'Schedule',
        //   filterType: 'dropdown',
        //   filterId: 'schedule',
        //   filterDropdownProps: {
        //     selectValue: getFilterSelectedValue('schedule', 'ruleEngine', false),
        //     selectOptions: ruleEngineScheduleReferenceData ?? [],
        //     handleSelectOptionChange: (val): void => {
        //       handleFilterDataChange(val, 'schedule', false)
        //     },
        //     selectDropDownId: 'rule-engnie-schedule-filter-dropdown',
        //     isMultiSelect: true
        //   }
        // },
        {
          filterLabel: 'Status',
          filterType: 'dropdown',
          filterId: 'ruleEngineChipStatus',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('ruleEngineChipStatus', 'ruleEngine', false),
            selectOptions: ruleEngineStatusReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'ruleEngineChipStatus', false)
                const status=val?.label ?? '';
              setSelectedChip(status.charAt(0).toLowerCase() + status.slice(1))
            },
            selectDropDownId: 'rule-engnie-status-filter-dropdown',
            isMultiSelect: false
          }
        },
  ]
  return ruleEngineFilter
}, [screenFilters, ruleEngineListTablePropsRef?.current, ruleEngineRef?.current, layoutView, selectedNav,selectedChip])

const ruleEngineHistoryFilterProps: FilterProp[] = useMemo(() => {
  const filterSetOnValue = getFilterSelectedValue('fireTimeRangeInterval', 'ruleEngineHistory', false);
  const ruleEngineHistoryFilter : FilterProp[]  =
      [
        {
          filterLabel: 'Charging Stations',
          filterType: 'dropdown',
          filterId: 'chargingStationId',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('chargingStationId', 'ruleEngineHistory', false),
            selectOptions:    chargingStationReferenceData?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'chargingStationId', false)
            },
            selectDropDownId: 'ruleEngine-history--charging-stations--filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Chargers',
          filterType: 'dropdown',
          filterId: 'chargerId',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('chargerId', 'ruleEngineHistory', false),
            selectOptions: chargerVisibleIdReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'chargerId', false)
            },
            selectDropDownId: 'ruleEngine-history-chargerId-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Rule Name',
          filterType: 'dropdown',
          filterId: 'ruleEngineRuleName',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('ruleEngineRuleName', 'ruleEngineHistory', false),
            selectOptions:  ruleEngineRuleNameReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'ruleEngineRuleName', false)
            },
            selectDropDownId: 'ruleEngineHistory-ruleEngineRuleName-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Trigger Category',
          filterType: 'dropdown',
          filterId: 'triggerCategory',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('triggerCategory', 'ruleEngineHistory', false),
            selectOptions: ruleEngineTriggerCategoryReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'triggerCategory', false)
            },
            selectDropDownId: 'rule-engnie-history-trigger-category-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Trigger Level',
          filterType: 'dropdown',
          filterId: 'triggerLevel',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('triggerLevel', 'ruleEngineHistory', false),
            selectOptions: ruleEngineTriggerLevelReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'triggerLevel', false)
            },
            selectDropDownId: 'rule-engine-history-trigger-level-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Action Controls',
          filterType: 'dropdown',
          filterId: 'actionControls',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('actionControls', 'ruleEngineHistory', false),
            selectOptions: ruleEngineActionControlReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'actionControls', false)
            },
            selectDropDownId: 'rule-engnie-history-action-controls-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Firetime Range',
          filterType: 'dateFilter',
          filterId: 'fireTimeRangeInterval',
          width:'100',
          dateFilterProps: {
            datePickerId: 'rule_engine__history__time__stamp__set__at__date__picker',
            datePickerClassName: 'rule_engine__history__time__stamp__cleared__at__date__picker',
            selectsRange: true,
            startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
            endDate: filterSetOnValue?.[1] ?   new Date(filterSetOnValue?.[1]) : null,    
            onChange: (val): void => {
              handleFilterDataChange(val, 'fireTimeRangeInterval', false)
            },
          }
        },
  ]
  return ruleEngineHistoryFilter
}, [screenFilters, ruleEngineHistoryListTablePropsRef?.current, ruleEngineHistoryRef?.current, layoutView, selectedNav])

const onResetButtonClick = useCallback(async (callListApi: boolean) => {
  const res = await setFilterData(null, '', selectedNav === 'ruleEngine' ? 'ruleEngine' : 'ruleEngineHistory', 'CLEAR', false)(dispatch);
  if (res?.message === 'Action dispatched successfully') {
    if (ruleEngineListTablePropsRef?.current && callListApi && selectedNav==='ruleEngine') {
      setSelectedChip('All');
      layoutCallback(1, ruleEngineListTablePropsRef?.current?.pageSize, ruleEngineListTablePropsRef?.current?.view, ruleEngineListTablePropsRef?.current?.sortBy, ruleEngineListTablePropsRef?.current?.order, res?.filters, ruleEngineListTablePropsRef?.current?.searchTerm)
    }
    if (ruleEngineHistoryListTablePropsRef?.current && callListApi && selectedNav==='ruleEngineHistory') {
      layoutCallbackHistory(1, ruleEngineHistoryListTablePropsRef?.current?.pageSize, ruleEngineHistoryListTablePropsRef?.current?.view, ruleEngineHistoryListTablePropsRef?.current?.sortBy, ruleEngineHistoryListTablePropsRef?.current?.order, res?.filters, ruleEngineHistoryListTablePropsRef?.current?.searchTerm)
    }
  }}, [ruleEngineListTablePropsRef?.current,ruleEngineHistoryListTablePropsRef?.current,selectedNav])

  const filterFooterBtns = useMemo(() => {
    return [
      {
        buttonText: 'Reset',
        buttonId: 'Reset',
        btnClassName: 'secondary__btn',
        handleClick: (): Promise<void> => onResetButtonClick(true),
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [onResetButtonClick,]);

  const handleCardClickRuleEngine = (e, rowData) => {
      navigate('/new-rule', { state: { mode: 'View', rowData: rowData, layoutView:layoutView } })
      e.stopPropagation();
  }
  return(
    <>
    <DashboardLoader showLoader={deleteLoader} />
    <ThemeProvider theme={TableDashboardTheme}>
      <div className={'rms__table__dashboard '+'rule_engine'} ref={tableWrapRef}>
        <div className="rms__table__dashboard-header-wrap" ref={headerWrapRef}>
          <DashboardHeader
            headerClassName={'rms__table__dashboard-header'}
            header={'All Rules' }
          />
          <div className="rms__table__dashboard-sub-header-wrap">
              <div className='rms__table__dashboard-sub-header-wrap-buttons'>
                <TableSubHeader headerActionBtns={headerActionBtns} />
              </div>
              <div className="rms__table__dashboard__view__options__wrap">
                { selectedNav==='ruleEngine' &&  (
                  Array.isArray(layoutsConfiguration) && layoutsConfiguration.length>0 &&
                  layoutsConfiguration.map((layout,index)=>{
                    return (
                         <IconWithTooltip
                          key={index}
                          toolTipText={layout.toolTipText}
                          onIconClick={() => layout?.onIconClick?.()}
                          iconClassName={
                            layoutView === layout.layout
                              ? layout.iconClassName + ' selected'
                              : layout.iconClassName ?? ''
                          }
                        />
                    )
                  })
                )
                  }
              </div>
          </div>
        </div>
          <div className='rule_engine_info_text'>
            Rule Engine can perform multiple fetches and transformations in a single rule.
          </div>
          <div
            className="rms__table__dashboard__search__wrap"
            ref={searchWrapRef}
          >
            <div className="rms__table__dashboard__search__wrap-left">
              <SearchBox
                key={'searchBox'+ selectedNav}
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
                handleSearch={searchBoxConfiguration?.handleSearch}
              />
            </div>
            {
              selectedNav ==='ruleEngine' && (
                <div className='rule_engine_rule_status_button_container'>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Chip
                    icon={<IconWithTooltip iconClassName={selectedChip === 'All' ? 'rule_engine_all_status_icon_selected':'rule_engine_all_status_icon'}/>}
                    label="All"
                    clickable
                    variant={(selectedChip === 'All'|| selectedChip === 'all') ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('All')}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      paddingTop: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '12px',
                      color: (selectedChip === 'All'|| selectedChip === 'all') ? '#fff' : 'unset',
                      backgroundColor: (selectedChip === 'All'|| selectedChip === 'all') ? '#06BFBF' : 'unset', 
                      '&:hover': {
                          backgroundColor: (selectedChip === 'All'|| selectedChip === 'all') ? '#06BFBF' : 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .MuiChip-label': {
                        paddingBottom: '4px',
                        paddingLeft: '8px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '& .MuiChip-filled': {
                          backgroundColor: '#06BFBF', 
                      },
                    }}
                  />
                  <Chip
                    icon={ <IconWithTooltip iconClassName={selectedChip === 'Running' ?'rule_engine_running_status_icon_selected':'rule_engine_running_status_icon'}/> }
                    label="Running"
                    clickable
                    variant={selectedChip === 'Running' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('Running')}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      paddingTop: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '12px',
                      color: selectedChip === 'Running' ? '#fff' : 'unset',
                      backgroundColor: selectedChip === 'Running' ? '#06BFBF' : 'unset', 
                      '&:hover': {
                          backgroundColor: selectedChip === 'Running' ? '#06BFBF' : 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .MuiChip-label': {
                        paddingBottom: '4px',
                        paddingLeft: '8px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '& .MuiChip-filled': {
                          backgroundColor: '#06BFBF', 
                      },
                    }}
                  />
                  <Chip
                    icon={ <IconWithTooltip iconClassName={selectedChip === 'Stopped' ? 'rule_engine_stopped_status_icon_selected':'rule_engine_stopped_status_icon'}/> }
                    label="Stopped"
                    clickable
                    variant={selectedChip === 'Stopped' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('Stopped')}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      paddingTop: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '12px',
                      color: selectedChip === 'Stopped' ? '#fff' : 'unset',
                      backgroundColor: selectedChip === 'Stopped' ? '#06BFBF' : 'unset', 
                      '&:hover': {
                          backgroundColor: selectedChip === 'Stopped' ? '#06BFBF' : 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .MuiChip-label': {
                        paddingBottom: '4px',
                        paddingLeft: '8px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '& .MuiChip-filled': {
                          backgroundColor: '#06BFBF', 
                      },
                    }}
                  />
                  <Chip
                    icon={ <IconWithTooltip iconClassName={selectedChip === 'Error' ?'rule_engine_error_status_icon_selected':'rule_engine_error_status_icon'}/> }
                    label="Error"
                    clickable
                    variant={selectedChip === 'Error' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('Error')}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      paddingTop: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '12px', 
                      color: selectedChip === 'Error' ? '#fff' : 'unset',
                      backgroundColor: selectedChip === 'Error' ? '#06BFBF' : 'unset', 
                      '&:hover': {
                          backgroundColor: selectedChip === 'Error' ? '#06BFBF' : 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .MuiChip-label': {
                        paddingBottom: '4px',
                        paddingLeft: '8px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '& .MuiChip-filled': {
                          backgroundColor: '#06BFBF', 
                      },
                    }}
                  />
                  <Chip
                    icon={ <IconWithTooltip iconClassName={selectedChip === 'Draft' ? 'rule_engine_draft_status_icon_selected':'rule_engine_draft_status_icon'}/> }
                    label="Draft"
                    clickable
                    variant={selectedChip === 'Draft' ? 'filled' : 'outlined'}
                    onClick={() => handleChipClick('Draft')}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      paddingTop: '2px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '12px', 
                      color: selectedChip === 'Draft' ? '#fff' : 'unset',
                      backgroundColor: selectedChip === 'Draft' ? '#06BFBF' : 'unset', 
                      '&:hover': {
                          backgroundColor: selectedChip === 'Draft' ? '#06BFBF' : 'rgba(0, 0, 0, 0.08)',
                      },
                      '& .MuiChip-label': {
                        paddingBottom: '4px',
                        paddingLeft: '8px !important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '& .MuiChip-filled': {
                          backgroundColor: '#06BFBF', 
                      },
                    }}
                  />
                </Box>
                </div>
              )
            }
          </div>
          <div className='rms_table_rule_engine_nav_bar_status_container'>
          <div ref={subHeaderRef} className="rms__table__dashboard-navbar-wrap">
          <div className='rule__engine__container__sub__header__left'>
               <HorizontalNavbar
                selectedNav={navBarconfigurations?.selectedNav}
                onNavClick={(event, val) => { event?.stopPropagation(); navBarconfigurations?.onNavClick?.(val) }}
                navList={navBarconfigurations?.navList}
              />
          </div>
          </div>

          </div>
        <div
          className="rms__table__dashboard__table__wrap"
          style={{ height: `${tableWrapHeight - 20}px` }}
        >
            {selectedNav === 'ruleEngine' ?  
             ( layoutView==='list'? 
            <TableLayout
              gridColumns={tableColumns}
              tableRows={getTableRows()} 
              tableId={'rule-engine-table'}
              tableClassName={'rule-engine-table'}
              layoutView={layoutView}
              listLayoutCallBack={layoutCallback}
              totalCount={ruleEngineCount}
              showLoader={ruleEngineTableLoader}
              showSkeleton={!ruleEngine}
              totalCountText={'Rules'}
              customNoDataComponent={NoDataComponentRuleEngine}
              pageNumber={ruleEngineListTableProps?.pageNumber}
              tablePageSize={ruleEngineListTableProps?.pageSize}
              sortField={ruleEngineListTableProps?.sortBy}
              sortOrder={ruleEngineListTableProps?.order}
              refreshId={forceRefreshId}
              columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'ruleEngine')}
              handleColumnPrefrenceChange={handleColumnPrefrenceChange}     
              leftPinnedColumns={['runningStatus','error','name']}
              // enableDisableBackgroundColourChange={true}         
                />
                :
                (<RuleEngineGrid 
                  cardClassName='rule_engine_card'
                  listLayoutCallBack={layoutCallback}
               ruleDetail={ruleEngine}
               screen='ruleEngine'
               handleToggleChange={handleToggleChange} 
               handleDelete={(id)=>{ setSelectedRuleEngineId(id);setIsDeleteDialogOpen(true)}}
               pageNumber={ruleEngineListTableProps?.pageNumber}
               tablePageSize={ruleEngineListTableProps?.pageSize}
               totalCount={ruleEngineCount}
               countText='Rules'
                handlePaginationChange={handlePaginationChange}
                handlePageCountChange={handlePageCountChange}
                handleCardClickRuleEngine={handleCardClickRuleEngine}
                />
              )
              ) 
              : <TableLayout
                key={'history ' + selectedNav}
            tableId='ruleEngineHistory'
            tableRows={getTableRowsHistory()} 
            gridColumns={tableColumnsHistory}
            layoutView={'list'}
            tableClassName='rule_engine_history_table'
            listLayoutCallBack={layoutCallbackHistory}
            totalCount={ruleEngineHistoryCount}
            showLoader={ruleEngineTableLoader}
            showSkeleton={!ruleEngineHistory}
            totalCountText={'Rules'}
            customNoDataComponent={NoDataComponentRuleEngineHistory}
            pageNumber={ruleEngineHistoryListTableProps?.pageNumber}
            tablePageSize={ruleEngineHistoryListTableProps?.pageSize}
            sortField={ruleEngineHistoryListTableProps?.sortBy}
            sortOrder={ruleEngineHistoryListTableProps?.order}
            refreshId={forceRefreshId}         
            columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'ruleEngineHistory')}
            handleColumnPrefrenceChange={handleColumnPrefrenceChange}    
            leftPinnedColumns={['fireTime','ruleName']}
          /> }
        </div>
      </div>
    </ThemeProvider>   
    { filtersVisible && <SidePopover
        id={'rule__engine__side__filters'}
        open={filtersVisible}
        containerClassName='manage__rule_engine__filters'
        handleClose={handleFiltersClosePopOver}
        headerText={'Add Filters'}
        // eslint-disable-next-line react/no-children-prop
        children={ 
          <FilterLayout 
          screen={selectedNav==='ruleEngine' ? 'ruleEngine' :'ruleEngineHistory'}
          requiredReferenceDataCallbacks={selectedNav === 'ruleEngine' ? ['triggerCategory','triggerLevel','actionControls','schedule','ruleEngineChipStatus'] : ['triggerCategory', 'triggerLevel','actionControls','chargerVisibleId','chargingStationId','ruleEngineRuleName'] }
          filters={selectedNav === 'ruleEngine' ? ruleEngineFilterProps : ruleEngineHistoryFilterProps } 
          handleFilterDataChange={handleFilterDataChange} 
          excludeGlobalFilters={selectedNav === 'ruleEngine' ? true : false} 
          />
        }
        btnsList={filterFooterBtns}
      />
      }
       { <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this rule?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteRule}
        deleteLoader={deleteLoader}
      />}
       {
        <CustomDialog
        dialogConfig={{
          dialogDescription: 'Charger information is not available, as the charger has not yet communicated with RMS.',
          dialogOkTitle: 'Close',
        }}
        show={isNoProductTypeDialogOpen}
        handleSubmit={() => { setIsNoProductTypeDialogOpen(false) }}
      />
      }

    </>
  )
})

export default RuleEngineDashboard
