/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './ScheduleExecutionSidePopover.css'
import { CustomButtonGroup, CustomDatePicker } from '../../../globalUtils/globalExports';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
// import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cloneDeep, isEqual } from 'lodash';
import { Id, toast } from 'react-toastify';
import ExecutionPlan from './ExecutionPlan';
import { ScheduleExecution } from '../../../rmsReduxStore/ruleEngineRedux/ruleEngineType';

interface ScheduleExecutionPopOverData {
    executionLevel: null | string;
    oneTimeSelectedDate?: Date | null;
    oneTimeSelectedTime?: null | Dayjs;
    recurringSelectedTime?: null | Dayjs,
    // recurringRepeatNumber?: null | string,
    // recurringRepeatInterval?: null | string,
    recurringSelectedDays?: null | string[];
    recurringSelectedRangeOption?: null | string;
    recurringSelectedRangeOptionRecurrenceNumber?: null | number;
    recurringSelectedRangeDate?: null | Date;

}
interface ScheduleExecutionSidePopoverProps {
    // scheduleExecutionFormData: ScheduleExecutionPopOverData;
    // handleUpdateScheduleExecutionData: Function;
    // setScheduleExecutionSidePopoverData: React.Dispatch<React.SetStateAction<ScheduleExecutionPopOverData>>;
    handleClose: () => void;
    scheduleTimeData: ScheduleExecution | null;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
}
const ScheduleExecutionSidePopover = memo(function ScheduleExecutionSidePopover({
    // scheduleExecutionFormData,
    // handleUpdateScheduleExecutionData,
    handleClose,
    scheduleTimeData,
    executionPlan,
    handleUpdateExecutionPlan
}: ScheduleExecutionSidePopoverProps) {

    const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    const [isTimePickerDropdownOpen, setIsTimePickerDropdownOpen] = useState<boolean>(false)
    const currentTime = dayjs();
    const [formData, setFormData] = useState<ScheduleExecutionPopOverData>({
        executionLevel: null,
        oneTimeSelectedDate: null,
        oneTimeSelectedTime: null,
        recurringSelectedTime: null,
        recurringSelectedDays: [],
        recurringSelectedRangeOption: 'NoEndDate',
        recurringSelectedRangeOptionRecurrenceNumber: null,
        recurringSelectedRangeDate: null
    });
    const [initialPopOverData, setInitialPopOverData] = useState<ScheduleExecutionPopOverData>({
        executionLevel: null,
        oneTimeSelectedDate: null,
        oneTimeSelectedTime: null,
        recurringSelectedTime: null,
        recurringSelectedDays: [],
        recurringSelectedRangeOption: 'NoEndDate',
        recurringSelectedRangeOptionRecurrenceNumber: null,
        recurringSelectedRangeDate: null
    });
    useEffect(()=>{
        if(scheduleTimeData){

            const data = cloneDeep(scheduleTimeData);
            const currentPopoverData = cloneDeep(formData);

            currentPopoverData.executionLevel = data.scheduleType,
            currentPopoverData.oneTimeSelectedDate =data.scheduleType === 'OneTime' ? new Date(data.dateTime??'') : null,
            currentPopoverData.oneTimeSelectedTime = data.scheduleType === 'OneTime' ? dayjs(data.dateTime) : null,
            currentPopoverData.recurringSelectedTime = data.scheduleType === 'Recurring' ?  dayjs(data.dateTime) : null,
            currentPopoverData.recurringSelectedRangeOptionRecurrenceNumber = data.scheduleType === 'Recurring'  && data.recurrenceCount!== null ? data.recurrenceCount : null,
            currentPopoverData.recurringSelectedDays = data.scheduleType === 'Recurring' ? data.recurrenceDays : null,
            currentPopoverData.recurringSelectedRangeDate = data.scheduleType === 'Recurring' && data.endDate!== null ? new Date(data.endDate?? '') : null
            currentPopoverData.recurringSelectedRangeOption = data.scheduleType === 'Recurring' ? data.recurrenceRange : null;

            setFormData(currentPopoverData);
            setInitialPopOverData(currentPopoverData);
        } 
    },[])

    // const autoPopulateScheduledExecution = (data : ScheduleExecution) => {
    //     setScheduleExecutionFormData({
    //       ...scheduleExecutionFormData,
    //       executionLevel: data.type,
    //       oneTimeSelectedDate: data.type === 'OneTime' ? new Date(data.dateTime??'') : null,
    //       oneTimeSelectedTime: data.type === 'OneTime' ? dayjs(data.dateTime) : null,
    //       recurringSelectedTime: data.type === 'Recurring' ?  dayjs(data.dateTime) : null,
    //       recurringSelectedRangeOptionRecurrenceNumber: data.type === 'Recurring'  && data.recurrenceCount!== null ? data.recurrenceCount : null,
    //       recurringSelectedDays: data.type === 'Recurring' ? data.recurrenceDays : null,
    //       recurringSelectedRangeDate: data.type === 'Recurring' && data.endDate!== null ? new Date(data.endDate?? '') : null
    //     })
    //   }
    const userFormErrorToast = (message): Id => toast.error(message);
    const hanldeBoxClick = (box) => {
        setIsTimePickerDropdownOpen(false);
        if(box === 'OneTime'){
            setFormData({
                ...formData,
                executionLevel: box,
                recurringSelectedTime: null,
                recurringSelectedDays: [],
                recurringSelectedRangeOption: '',
                recurringSelectedRangeOptionRecurrenceNumber: null,
                recurringSelectedRangeDate: null,
            })
        }else {
            setFormData({
                ...formData,
                executionLevel: box,
                oneTimeSelectedDate: null,
                oneTimeSelectedTime: null,
            })
        }

    }
    const handleDayClick = (day) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            recurringSelectedDays: prevFormData.recurringSelectedDays
                ? prevFormData.recurringSelectedDays.includes(day)
                    ? prevFormData.recurringSelectedDays.filter((selectedDay) => selectedDay !== day)
                    : [...prevFormData.recurringSelectedDays, day]
                : [day]
        }));
    };
    const compareDatesOnly = (date1: Date, date2: Date)=> {
        const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      
        return d1.getTime() >= d2.getTime();
    }

    const compareTimeOnly = (date1, date2) : boolean => {
        // Extract the date parts
        const isSameDate =
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate();
      
      
        // Compare time parts
        if ( isSameDate) {
         if(date1.getTime()>date2.getTime())
            return true;
        else 
            return false
        } else
          return true

      }
      
    const handleDateTimeChange = (date) => {

        if(date === null){
            setFormData({
                ...formData,
                oneTimeSelectedDate:null
            })
            
        }else {
            const currentDate = new Date();
            const incomingDate = new Date(date);
            if (compareDatesOnly(incomingDate,currentDate)) {
                setFormData({
                    ...formData,
                    oneTimeSelectedDate: date
                })
                //   setSelectedDate(date);
            }
        }
    };
    const handleRecurrenceChange = (event) => {
        const data = cloneDeep(formData);
        if(event.target.value === 'NoEndDate'){
            data.recurringSelectedRangeOptionRecurrenceNumber = null;
            data.recurringSelectedRangeDate = null;
        }else if(event.target.value === 'Recurrence'){
            data.recurringSelectedRangeDate = null;
        }else if(event.target.value === 'Date'){
            data.recurringSelectedRangeOptionRecurrenceNumber = null;
        }
        data.recurringSelectedRangeOption = event.target.value;
        setFormData(data);
    };


    const handleRecurrenceRangeDateChange = (date) => {
        if(date === null){
                setFormData({
                    ...formData,
                    recurringSelectedRangeDate: null
                })
        }else {
            const currentDate = new Date();
            if (date >= currentDate) {
                setFormData({
                    ...formData,
                    recurringSelectedRangeDate: date
                })
    
            }
        }
    }

    const getCombinedOneTimeAndDate = (date,time) => {
        const givenDate = new Date(date);
        const givenTime = new Date(time);
        const combinedDate = new Date(givenDate.getFullYear(),givenDate.getMonth(),givenDate.getDate(),givenTime.getHours(),givenTime.getMinutes(),givenTime.getSeconds(),givenTime.getMilliseconds());
        const newDate = new Date(combinedDate);
        const utcString = newDate.toISOString();
        return utcString;
      }

    // const handleErrorMessage = (recurringRepeatNumber, recurringRepeatInterval) => {
    //     if (recurringRepeatInterval && !(parseInt(recurringRepeatInterval) >= 1 && parseInt(recurringRepeatInterval) <= 720)) {
    //         setErrorMessage('Interval value must be greater than 1 and cannot exceed 720 minutes');
    //     } else if (recurringRepeatNumber && recurringRepeatInterval && parseInt(recurringRepeatNumber) > 0) {
    //         const currentHour = formData.recurringSelectedTime?.get('hours');
    //         if (currentHour && (24 - currentHour) * 60 >= parseInt(recurringRepeatInterval) * parseInt(recurringRepeatNumber)) {
    //             setErrorMessage('Repetition cannot carry over to the next day')
    //         }
    //     }
    // }
    const disableButton = useCallback(() => {
        if(isEqual(formData,initialPopOverData)){
            return true;
        }else{
            let result = true;
        if (formData.executionLevel === 'OneTime') {
            result = result && formData?.oneTimeSelectedDate !== null && formData?.oneTimeSelectedTime !== undefined && formData?.oneTimeSelectedTime?.isValid() !== undefined && formData?.oneTimeSelectedTime?.isValid() 
        } else if (formData.executionLevel === 'Recurring') {
            result = result && formData?.recurringSelectedDays !== undefined && formData?.recurringSelectedDays !== null && formData?.recurringSelectedDays.length > 0 && formData?.recurringSelectedTime?.isValid() !== undefined && formData?.recurringSelectedTime?.isValid() 
            if (formData?.recurringSelectedRangeOption === 'Recurrence') {
                result = result && (formData?.recurringSelectedRangeOptionRecurrenceNumber!==null && !Number.isNaN(formData?.recurringSelectedRangeOptionRecurrenceNumber))
            } else if (formData?.recurringSelectedRangeOption === 'Date') {
                result = result && (formData?.recurringSelectedRangeDate !== null)
            } else {
                result = result && formData?.recurringSelectedRangeOption !== null
            }
        }

        return !result;
        }
    },[formData])

    const btnsList = useMemo(() => {
        return [
            {
                buttonText: 'Save',
                buttonId: 'add',
                btnClassName: disableButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: (): void => {
                    const data = cloneDeep(formData);
                    // handleUpdateScheduleExecutionData(data);

                    const currentExecutionPlan = cloneDeep(executionPlan);
                    currentExecutionPlan.trigger.scheduleType = data?.executionLevel;
                    currentExecutionPlan.trigger.dateTime = data?.executionLevel === 'OneTime' ? new Date(getCombinedOneTimeAndDate(data.oneTimeSelectedDate,data.oneTimeSelectedTime)).toISOString() : data.recurringSelectedTime?.toDate().toISOString()??null ;
                    currentExecutionPlan.trigger.recurrenceDays = data?.executionLevel === 'OneTime' ? null : data?.recurringSelectedDays??null;
                    currentExecutionPlan.trigger.recurrenceRange = data?.executionLevel === 'OneTime' ? null: data.recurringSelectedRangeOption??null;
                    currentExecutionPlan.trigger.recurrenceCount = data?.executionLevel === 'OneTime' ? null : data?.recurringSelectedRangeOptionRecurrenceNumber??null,
                    currentExecutionPlan.trigger.endDate = (data?.executionLevel === 'Recurring' && data.recurringSelectedRangeOption === 'Date') ? data.recurringSelectedRangeDate?.toISOString()?? null : null;
                    currentExecutionPlan.trigger.type = 'Scheduler';

                    handleUpdateExecutionPlan(currentExecutionPlan);
                    handleClose();
                },
                isDisabled: disableButton(),
                buttonVariant: 'filled',
            },
            {
                buttonText: 'Cancel',
                buttonId: 'cancel',
                btnClassName: 'secondary__btn',
                handleClick: (): void => {
                    handleClose()
                },
                isDisabled: false,
                buttonVariant: 'outlined',
            },
        ];
    }, [formData]);

    function DateIcon() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 5.20015V10.0002L13.2001 11.6002M18.0001 10.0002C18.0001 14.4184 14.4184 18.0002 10.0001 18.0002C5.58184 18.0002 2.00012 14.4184 2.00012 10.0002C2.00012 5.58187 5.58184 2.00015 10.0001 2.00015C14.4184 2.00015 18.0001 5.58187 18.0001 10.0002Z" stroke="#002126" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            
        );
    }
    return (
        <>
            <div className='schedule__execution__wrapper'>
                <div className='schedule__execution__side__popover_boxes'>
                    <div className={`schedule__execution__box ${formData.executionLevel === 'OneTime' ? 'selected' : ''}`} onClick={() => hanldeBoxClick('OneTime')}>
                        <h4>One Time</h4>
                        <p className='schedule__execution__box__sub__heading'>Rule is triggered as per scheduled one time.</p>
                        {formData.executionLevel === 'OneTime' && <span className="rms__icon checkmark"></span>}
                    </div>
                    <div className={`schedule__execution__box ${formData.executionLevel === 'Recurring' ? 'selected' : ''}`} onClick={() => hanldeBoxClick('Recurring')}>
                        <h4>Recurring</h4>
                        <p className='schedule__execution__box__sub__heading'>Rule is triggered as per scheduled repeat at regular intervals.</p>
                        {formData.executionLevel === 'Recurring' && <span className="rms__icon checkmark"></span>}
                    </div>
                </div>
                {
                    formData.executionLevel === 'OneTime' &&
                    <div className='schedule__execution__one__time__options'>
                        <div className='schedule__execution__select__date__wrapper'>
                            <div className='schedule__execution__label'>
                                Select Date*
                            </div>
                            <div>
                                <CustomDatePicker
                                    startDate={formData.oneTimeSelectedDate ?? null}
                                    showTimePicker={false}
                                    endDate={null}
                                    selectsRange={false}
                                    
                                    onChange={handleDateTimeChange}
                                    datePickerClassName={'edit_parameter_scheduled_date_time'}
                                    popperPlacement='bottom-end'

                                />
                            </div>
                        </div>
                        <div className='schedule__execution__select__time__wrapper'>
                            <div className='schedule__execution__label'>Select Time *</div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        disabled={formData?.oneTimeSelectedDate === null}
                                        value={formData?.oneTimeSelectedTime}
                                        minTime={
                                            formData?.oneTimeSelectedDate && dayjs(formData.oneTimeSelectedDate).isAfter(dayjs())
                                              ? undefined
                                              : currentTime
                                          }
                                        open={isTimePickerDropdownOpen}
                                        onChange={(val) => {
                                            const data = cloneDeep(formData);
                                            data.oneTimeSelectedTime = val;
                                            if(!compareTimeOnly((data.oneTimeSelectedTime)?.toDate()??new Date(),new Date())){
                                                userFormErrorToast('Please Select a future Time')
                                                data.oneTimeSelectedTime = null;
                                            }
                                            setFormData(data)
                                        }}
                                        className='timer'
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                border: 'none',
                                                outline: 'none',
                                                borderRadius: '4px',
                                                padding: '6px 5px 6px 0px',
                                                fontSize: '12px',
                                                color: 'var(--total-alarm-color)'

                                            },
                                            '& .MuiOutlinedInput-root.Mui-disabled': {
                                                backgroundColor: 'hsl(0, 0%, 95%)', 
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                                '&:hover fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                            },
                                            '& .MuiIconButton-root':{
                                                padding: '0px 0px 0px 8px'
                                            }

                                        }}
                                        slotProps={{
                                            inputAdornment: {
                                            position: 'start',
                                            },
                                            popper: {
                                                sx: {
                                                  '& .MuiList-root': {
                                                    backgroundColor: 'white',
                                                  },
                                                  '& .MuiMenuItem-root': {
                                                    '&.Mui-selected': {
                                                       backgroundColor: 'var(--secondary-blue-color)',
                                                       color: 'white'
                                                    },
                                                    color: 'black'
                                                  },
                                                  '& .MuiDialogActions-root': {
                                                    backgroundColor: 'white',
                                                  },
                                                  '& .MuiDialogActions-root .MuiButton-text': {
                                                    color: 'var(--secondary-blue-color)'
                                                    },
                                                },
                                              },
                                        }}
                                        closeOnSelect={false}
                                        onClose={() => {
                                            setIsTimePickerDropdownOpen(false)
                                        }}
                                        onOpen={() => {
                                            setIsTimePickerDropdownOpen(true)
                                        }}
                                        onAccept={(val) => {
                                            const data = cloneDeep(formData);
                                            data.oneTimeSelectedTime = val;
                                            if(!compareTimeOnly((data.oneTimeSelectedTime)?.toDate()??new Date(),new Date())){
                                                userFormErrorToast('Please Select a future Time')
                                                data.oneTimeSelectedTime = null;
                                            }
                                            setFormData(data)
                                        }}
                                        slots={{
                                            openPickerIcon: DateIcon
                                        }}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                }
                {
                    formData.executionLevel === 'Recurring' &&
                    <div className='schedule__execution__recurring__wrapper'>
                        <div className='schedule__execution__recurring__select__time__wrapper'>
                            <div className='schedule__execution__label'>Select Time *</div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={formData?.recurringSelectedTime}
                                        open={isTimePickerDropdownOpen}
                                      
                                        onChange={(val) => {
                                            const data = cloneDeep(formData);
                                            data.recurringSelectedTime = val;
                                            setFormData(data)
                                        }}
                                        className='timer'
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                border: 'none',
                                                outline: 'none',
                                                borderRadius: '4px',
                                                padding: '6px 5px 6px 0px',
                                                fontSize: '12px',
                                                color: 'var(--total-alarm-color)'

                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                                '&:hover fieldset': {
                                                    border: '1px solid #ccc',
                                                },
                                            },
                                            '& .MuiIconButton-root':{
                                                padding: '0px 0px 0px 8px'
                                            }

                                        }}
                                        slotProps={{
                                            inputAdornment: {
                                            position: 'start',
                                            },
                                            popper: {
                                                sx: {
                                                  '& .MuiList-root': {
                                                    backgroundColor: 'white',
                                                  },
                                                  '& .MuiMenuItem-root': {
                                                    '&.Mui-selected': {
                                                       backgroundColor: 'var(--secondary-blue-color)',
                                                       color: 'white'
                                                    },
                                                    color: 'black'
                                                  },
                                                  '& .MuiDialogActions-root': {
                                                    backgroundColor: 'white',
                                                  },
                                                  '& .MuiDialogActions-root .MuiButton-text': {
                                                    color: 'var(--secondary-blue-color)'
                                                    },
                                                },
                                              },
                                        }}
                                        closeOnSelect={false}
                                        onClose={() => {
                                            setIsTimePickerDropdownOpen(false)
                                        }}
                                        onOpen={() => setIsTimePickerDropdownOpen(true)}
                                        onAccept={(val) => {
                                            const data = cloneDeep(formData);
                                            data.recurringSelectedTime = val;
                                            // console.log(data,'oneTimeSelectedTime');
                                            // if(!compareTimeOnly((data.recurringSelectedTime)?.toDate()??new Date(),new Date())){
                                            //     userFormErrorToast('Please Select a future Time')
                                            //     data.recurringSelectedTime = null;
                                            //     setFormData(data);
                                            //     return;
                                            // }else{
                                            setFormData(data)
                                            // }
                                        }}
                                        slots={{
                                            openPickerIcon: DateIcon
                                        }}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        {/* <div className='schedule__execution__recurring__accordion'>
                            <div className='accordion_container'>
                                <Accordion disabled={formData?.recurringSelectedTime !== null ? false : true} sx={{ background: formData?.recurringSelectedTime !== null ? '#fbfbfb' : '#FAFAFA' }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <div className="accordion-summary-content">
                                            <div className="accordion-title">Repeat Option</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="accordion-details">
                                            Repeat
                                            <input
                                                type="number"
                                                value={formData?.recurringRepeatNumber ?? ''}
                                                onChange={(e) => {
                                                    handleErrorMessage(e.target.value, formData?.recurringRepeatInterval)
                                                    setFormData({
                                                        ...formData,
                                                        recurringRepeatNumber: e.target.value
                                                    })
                                                }}
                                                className="recurrence__option__number"
                                            />
                                            times after
                                            <input
                                                type="number"
                                                value={formData?.recurringRepeatInterval ?? ''}
                                                onChange={(e) => {
                                                    handleErrorMessage(formData?.recurringRepeatNumber, e.target.value)
                                                    setFormData({
                                                        ...formData,
                                                        recurringRepeatInterval: e.target.value
                                                    })
                                                }}
                                                className="recurrence__option__number"
                                            />
                                            mins interval
                                        </div>
                                    </AccordionDetails>
                                    {errorMessage && <div className="schedule__execution__recurring__repeat__option__error__message">{errorMessage}</div>}
                                </Accordion>

                            </div>
                        </div> */}
                        <div className='schedule__execution__reccurence__days__wrapper'>
                            <div className='schedule__execution__label'>
                                Recurrence Days*
                            </div>
                            <div className='schedule__execution__days__wrapper'>
                                {daysOfWeek.map((day) => (

                                    <div
                                        key={day}
                                        className={`schedule__execution__day__box ${formData?.recurringSelectedDays && formData.recurringSelectedDays.includes(day) ? 'selected' : ''}`}
                                        onClick={() => handleDayClick(day)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {day}
                                    </div>
                                ))}
                            </div>
                            {formData?.recurringSelectedDays && <div className='schedule__execution__selected__days__wrapper'>
                                {formData?.recurringSelectedDays.map((day, index) => {
                                    return <div key={day} className='schedule__execution__selected__days'>
                                        {day}
                                        {formData?.recurringSelectedDays && index < formData?.recurringSelectedDays.length - 1 && ','}
                                    </div>
                                })}
                            </div>}
                        </div>
                        <div className='schedule__execution__recurrence__range__wrapper'>
                            <div className='schedule__execution__label'>
                                Recurrence Range*
                            </div>
                            <div className='schedule__execution__recurrence__range__options'>
                                <div className="recurrence__range__option">
                                    <input
                                        type="radio"
                                        id="NoEndDate"
                                        name="recurrenceRange"
                                        value="NoEndDate"
                                        checked={formData?.recurringSelectedRangeOption === 'NoEndDate'}
                                        onChange={handleRecurrenceChange}
                                    />
                                    <label htmlFor="NoEndDate">No end date</label>
                                </div>

                                <div className="recurrence__range__option">
                                    <input
                                        type="radio"
                                        id="Recurrence"
                                        name="recurrenceRange"
                                        value="Recurrence"
                                        checked={formData?.recurringSelectedRangeOption === 'Recurrence'}
                                        onChange={handleRecurrenceChange}
                                    />
                                    <label htmlFor="Recurrence">
                                        End after
                                        <input
                                            type="number"
                                            value={formData?.recurringSelectedRangeOptionRecurrenceNumber?.toString()??''}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                recurringSelectedRangeOptionRecurrenceNumber:parseInt(e.target.value)
                                            })}
                                            disabled={formData?.recurringSelectedRangeOption !== 'Recurrence'}
                                            className="recurrence__option__number"
                                        />
                                        {'      '}
                                        occurences
                                    </label>
                                </div>

                                <div className="recurrence__range__option recurrence__option__date">
                                    <input
                                        type="radio"
                                        id="Date"
                                        name="recurrenceRange"
                                        value="Date"
                                        checked={formData?.recurringSelectedRangeOption === 'Date'}
                                        onChange={handleRecurrenceChange}
                                    />
                                    <label htmlFor="Date">
                                        End by
                                        {/* <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        disabled={recurrenceType !== 'Date'}
                                        className="recurrence__option__number recurrence__option__date"
                                    /> */}
                                    </label>
                                    <CustomDatePicker
                                        disabled={formData?.recurringSelectedRangeOption !== 'Date'}
                                        startDate={formData?.recurringSelectedRangeDate ?? null}
                                        showTimePicker={false}
                                        endDate={null}
                                        selectsRange={false}
                                        onChange={handleRecurrenceRangeDateChange}
                                        datePickerClassName={'recurrence__option__custome__date__picker'}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='rule__engine__if__then__else__side__popover__footer'>
                <CustomButtonGroup
                    buttonsList={btnsList}
                    buttonGroupClassName='button__group__footer' />
            </div>
        </>
    )
})

export default ScheduleExecutionSidePopover;