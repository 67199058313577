
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ruleEngineTypes } from '../reduxExports';
import hwaxios from '../api-service/HWAxios';
import rmsStore from '../rmsStore';
import { AxiosError, isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { RuleEngineAction } from './ruleEngineType';

const url = process.env.REACT_APP_SERVER_BASE_URL

/* eslint-disable @typescript-eslint/ban-types */
export const fetchRuleEngineData: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LIST_REQUEST_HASH,
      ruleEngineListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.post(url + '/rule-engine/list', reqBody)
    if (searchHash === rmsStore.getState().ruleEngineReducer.ruleEngineListRequestHash) {
      const action: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.FETCH_RULE_ENGINE,
        ruleEngine: response.data?.data?.response,
        ruleEngineCount: response.data?.data?.count,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setRuleEngineListTableProps: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_LIST_TABLE_PROPS,
    ruleEngineListTableProps: data,
  };
  dispatch(action);
}

export const deleteRuleEngine: any = (id: string) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + `/rule-engine?id=${id}`,)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

//Rule Engine Single Row Enable/Disable API
export const setRuleEngineStatus: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.put(url + '/rule-engine/status', reqBody)
    const action: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_STATUS,

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);
      toast.success(`Rule ${response.data.data.status}`);
    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        toast.error(error.response.data)
      }
    }
  }
}

export const postAlarmRule: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => Object): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.post(url + '/rule-engine', reqBody)
    const action: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_STATUS,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);
      // toast.success(`Rule ${response.data.data.status}`);
      return response.data
    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        toast.error(error.response.data)
      }
    }
  }
}

export const updateAlarmRule: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => Object): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.put(url + '/rule-engine', reqBody)
    const action: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_STATUS,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);
      // toast.success(`Rule ${response.data.data.status}`);
      return response.data
    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        toast.error(error.response.data)
      }
    }
  }
}

export const fetchRuleEngineHistoryData: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH,
      ruleEngineHistoryListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.post(url + '/rule-engine/history', reqBody)
    if (searchHash === rmsStore.getState().ruleEngineReducer.ruleEngineHistoryListRequestHash) {
      const action: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.FETCH_RULE_ENGINE_HISTORY,
        ruleEngineHistory: response.data?.data?.response,
        ruleEngineHistoryCount: response.data?.data?.count,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchSingleExecutionSummary: any = (reqBody: any) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: true
    }
    dispatch(loaderStartAction);
    // const searchHash = reqBody?.searchTerm
    // const hashAction: Partial<RuleEngineAction> = {
    //   type: ruleEngineTypes.SET_RULE_ENGINE_HISTORY_LIST_REQUEST_HASH,
    //   ruleEngineHistoryListRequestHash: searchHash
    // }
    // dispatch(hashAction)
    const response = await hwaxios.post(url + '/rule-engine/history', reqBody) /// change the URL with the BE
    // if (searchHash === rmsStore.getState().ruleEngineReducer.ruleEngineHistoryListRequestHash) {
    // }
    if (response?.status === 200) {
      const loaderEndAction: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
        ruleEngineTableLoader: false
      }
      dispatch(loaderEndAction);

      const action: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.FETCH_SINGLE_EXECUTION_SUMMARY,
        ruleEngineExecutionSummary: response.data?.data?.response,
        ruleEngineExecutionSummaryCount: response.data?.data?.count,
      }
      dispatch(action)

    }
  } catch (error) {
    const loaderEndAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LOADER,
      ruleEngineTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setRuleEngineExecutionSummaryListTableProps: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_EXECUTION_SUMMARY_LIST_TABLE_PROPS,
    ruleEngineExecutionSummaryListTableProps: data,
  };
  dispatch(action);
}



export const setRuleEngineHistoryListTableProps: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_HISTORY_LIST_TABLE_PROPS,
    ruleEngineHistoryListTableProps: data,
  };
  dispatch(action);
}

export const setRuleEngineExecutionPlanData: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_EXECUTION_PLAN_DATA,
    ruleEngineExecutionPlanData: data,
  };
  dispatch(action);
}

export const setRuleEngineFormRedux: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_FORM_REDUX,
    ruleEngineFormRedux: data,
  };
  dispatch(action);
}

export const clearRuleEngineFormRedux: any = () => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_RULE_ENGINE_FORM_REDUX,
    ruleEngineFormRedux: null,
  };
  dispatch(action);
}

export const fetchSingleRuleEngine: any = (reqBody: any, id: string) => async (dispatch: (arg0: Partial<RuleEngineAction>) => any): Promise<any> => {
  try {
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<RuleEngineAction> = {
      type: ruleEngineTypes.SET_RULE_ENGINE_LIST_REQUEST_HASH,
      ruleEngineListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.get(url + '/rule-engine' + `?id=${id}`, reqBody)
    if (searchHash === rmsStore.getState().ruleEngineReducer.ruleEngineListRequestHash) {
      const action: Partial<RuleEngineAction> = {
        type: ruleEngineTypes.FETCH_RULE_SINGLE_RULE_ENGINE_FOR_EDIT,
        ruleEngineFormRedux: response?.data?.data,
      }
      dispatch(action)
    }

    return response;
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      if (error.response) {
        toast.error(error.response.data)
      }
    }
  }
}

export const setLatestRuleOnTop: any = (data) => async (dispatch: (arg0: Partial<RuleEngineAction>) => void): Promise<void> => {
  const action: Partial<RuleEngineAction> = {
    type: ruleEngineTypes.SET_LATEST_RULE_ON_TOP,
    getLatestRuleOnTop: data,
  };
  dispatch(action);
}