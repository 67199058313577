import { FormLabel } from '@mui/material';
import React, { FC } from 'react';
import { CustomButtonGroupPropTypes } from '../CustomButton/CustomButton';
import CustomInput, {
  CustomInputFieldPropTypes,
} from '../CustomInput/CustomInput';
import CustomTextArea, {
  CustomTextAreaFieldPropTypes,
} from '../CustomTextArea/CustomTextArea';
import { DashboardHeaderPropTypes } from '../DashboardHeader/DashboardHeader';
import { LinkButtonPropTypes } from '../LinkButton/LinkButton';
import { CustomDropdownPropTypes } from '../SelectDropDown/SelectDropDown';
import {
  CustomButtonGroup,
  CustomUpload,
  DashboardHeader,
  LinkButton,
  SelectDropDown,
} from '../globalExports';
import './CustomForm.css';
import PhoneInput from 'react-phone-input-2';
import { CustomUploadFieldPropTypes } from '../CustomUpload/CustomUpload';
import CustomBreadCrumbs, { CustomBreadCrumbsProps } from '../CustomBreadCrumbs/CustomBreadCrumbs';
import { CountryCallingCode, CountryCode } from 'libphonenumber-js';
import { allowedCountries, getCurrentCountryCode } from '../globalHooks';
import { IconWithTooltip } from '../globalIcons';

export interface IFormObject {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onMobileNumberChange?: (value: string,countryCode:CountryCode,dialCode:CountryCallingCode) => void;
  phoneNumber?: string | null;
  formLabel: string;
  isRequired: boolean;
  objectType: 'dropdown' | 'input' | 'text-area' | 'mobile-number' | 'upload';
  selectDropdownProps: CustomDropdownPropTypes | null;
  inputProps: CustomInputFieldPropTypes | null;
  initialValue?: string | null;
  textAreaProps: CustomTextAreaFieldPropTypes | null;
  uploadProps?: CustomUploadFieldPropTypes
  tooltipText?: string
  isFixedWidth?: boolean;
}

export interface IFormContentProp {
  rowCountClassName: string;
  sectionHeading?: string;
  tooltipText?: string;
  sectionTitle?: string;
  formObject: IFormObject[];
}
export interface ICustomFormProps {
  linkBtnProps?: LinkButtonPropTypes | null;
  headerPropTypes?: DashboardHeaderPropTypes | null;
  formArray: IFormContentProp[];
  footerBtnProps?: CustomButtonGroupPropTypes;
  formContainerClassName: string;
  breadCrumbprops?: CustomBreadCrumbsProps
}

const CustomForm: FC<ICustomFormProps> = (props) => {
  const {
    linkBtnProps,
    headerPropTypes,
    formArray,
    footerBtnProps,
    formContainerClassName,
    breadCrumbprops
  } = props;

  return (
    <div className={'custom__form__wrap ' + formContainerClassName}>
      {linkBtnProps && <LinkButton
        linkBtnId={linkBtnProps?.linkBtnId}
        link={linkBtnProps?.link}
        btnClassName={linkBtnProps?.btnClassName}
        btnText={linkBtnProps?.btnText}
        linkBtnState={linkBtnProps?.linkBtnState}
        showArrow={linkBtnProps?.showArrow}
      />}

      {breadCrumbprops && <CustomBreadCrumbs
        containerId={breadCrumbprops?.containerId}
        containerClassName={breadCrumbprops?.containerClassName}
        breadCrumbs={breadCrumbprops?.breadCrumbs}
      />}

      {headerPropTypes && <div className='custom__form__wrap-header'>
        <DashboardHeader
          header={headerPropTypes?.header}
          headerClassName={headerPropTypes?.headerClassName}
        />
      </div>}

      <div className='custom__form__wrap-content'>
        {formArray &&
          formArray?.length > 0 &&
          formArray?.map((formItem, index) => {
            return (
              <>
                {(formItem?.sectionTitle && formItem?.sectionTitle?.length > 0) && <div className='custom__form__wrap-header-text-title'>{formItem?.sectionTitle}</div>}
                {(formItem?.sectionHeading && formItem?.sectionHeading?.length > 0) && <div className='custom__form__wrap-header-text'>{formItem?.sectionHeading}</div>}
                <div
                  key={'Form' + index}
                  className={
                    'custom__form__wrap-content-row ' +
                    formItem?.rowCountClassName
                  }
                >
                  {formItem?.formObject &&
                    formItem?.formObject?.length > 0 &&
                    formItem?.formObject?.map((object, index) => {
                      return (
                        <div key={index} className={`custom__form__wrap-content-column ${object.isFixedWidth ? 'custom__form__fixed__width__label' : ''}`}>
                          <div className='flex-tooltip'>
                            {object?.formLabel && <FormLabel className={'rms-form-label'} required={object?.isRequired}>
                              {object?.formLabel}
                            </FormLabel>}
                            { object.tooltipText && object.tooltipText.length > 1 && 
                              <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={object.tooltipText} containerClassName='user__form_header-status-info-custom' />
                            }
                            </div>


                          {object?.objectType === 'dropdown' &&
                            object?.selectDropdownProps !== null && (
                              <SelectDropDown
                                selectValue={object?.selectDropdownProps?.selectValue}
                                selectOptions={object?.selectDropdownProps?.selectOptions}
                                handleSelectOptionChange={object?.selectDropdownProps?.handleSelectOptionChange}
                                selectDropDownId={object?.selectDropdownProps?.selectDropDownId}
                                selectDropDownName={object?.selectDropdownProps?.selectDropDownName}
                                isMultiSelect={object?.selectDropdownProps?.isMultiSelect}
                                isDisabled={object?.selectDropdownProps?.isDisabled}
                                handleClose={object?.selectDropdownProps?.handleClose}
                              renderToBody={object?.selectDropdownProps?.renderToBody}
                              secondaryLabel={object?.selectDropdownProps?.secondaryLabel}
                              secondaryLabelView={object?.selectDropdownProps?.secondaryLabelView}
                              />
                            )}
                          

                    

                          {object?.objectType === 'input' &&
                            object?.inputProps !== null && (
                              <CustomInput
                                inputPlaceHolder={object?.inputProps?.inputPlaceHolder}
                                handleInputChange={object?.inputProps?.handleInputChange}
                                inputValue={object?.inputProps?.inputValue || object?.initialValue || ''}
                                inputFieldId={object?.inputProps?.inputFieldId}
                                isRequired={object?.inputProps?.isRequired}
                                handleKeyPress={object?.inputProps?.handleKeyPress}
                                isDisabled={object?.inputProps?.isDisabled}
                                errorMessage={object?.inputProps?.errorMessage}
                                autoFocus={object?.inputProps?.autoFocus}
                              />
                            )}

                          {object?.objectType === 'mobile-number' &&
                            object?.inputProps !== null && (
                              <div>
                              <PhoneInput
                                disabled={object?.inputProps?.isDisabled || false}
                                inputClass={'custom__form-mobile-input'}
                                specialLabel={''}
                                country={getCurrentCountryCode()}
                                onlyCountries={allowedCountries}
                                value={String(object?.inputProps?.inputValue) || ''}
                                onChange={(value: string,data:{countryCode:CountryCode,dialCode:CountryCallingCode}) => {
                                  object?.onMobileNumberChange?.(value,data?.countryCode,data?.dialCode)
                                }}
                                placeholder="**********"
                                autoFormat={true}
                                enableSearch={true}
                                countryCodeEditable={false}
                                searchNotFound='No countries to show.'
                                searchPlaceholder='Search'
                                inputProps={{
                                  name: 'phone',
                                  required: true,
                                  autoFocus: false,
                                }}
                              />
                              {object?.inputProps?.errorMessage && object?.inputProps?.errorMessage?.length > 0 && 
                                 (<div className="rms__custom__input__error__message">
                                   {object?.inputProps?.errorMessage}
                                 </div>)}
                              </div>
                            )}

                          {object?.objectType === 'text-area' &&
                            object?.textAreaProps !== null && (
                              <>
                                <CustomTextArea
                                  inputPlaceHolder={object?.textAreaProps?.inputPlaceHolder}
                                  handleInputChange={object?.textAreaProps?.handleInputChange}
                                  isRequired={object?.textAreaProps?.isRequired}
                                  inputValue={object?.textAreaProps?.inputValue}
                                  inputFieldId={object?.textAreaProps?.inputFieldId}
                                  isDisabled={object?.textAreaProps?.isDisabled}
                                  className={object?.textAreaProps?.className}
                                />
                                {
                                  object?.textAreaProps?.errorMessage && object?.textAreaProps?.errorMessage?.length > 0 &&
                                  (<div className="rms__custom__input__error__message">
                                    {object?.textAreaProps?.errorMessage}
                                  </div>)}
                              </>
                            
                            
                            )}

                          {object?.objectType === 'upload' &&
                            object?.uploadProps !== null && (
                              <CustomUpload
                                fileName={object?.uploadProps?.fileName}
                                inputFieldId="adding_station"
                                isRequired={object?.isRequired}
                                handleInputChange={object?.uploadProps?.handleInputChange}
                                showDialog={object?.uploadProps?.showDialog}
                                isDisabled = {object?.uploadProps?.isDisabled}
                              />
                            )}
                        </div>
                      );
                    })}
                </div>
              </>
            );
          })}
      </div>

      {footerBtnProps && <div className='custom__form__wrap-footer'>
        <CustomButtonGroup
          buttonsList={footerBtnProps?.buttonsList}
          buttonGroupClassName={'button__group__footer'}
        />
      </div>}
    </div>
  );
};

export default CustomForm;
