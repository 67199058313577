/* eslint-disable @typescript-eslint/ban-types */
import React, {useEffect, useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Handle, Position } from '@xyflow/react';
import CloseIcon from '@mui/icons-material/Close';
import { SidePopover,  } from '../../../globalUtils/globalExports';
import { ExecutionPlan, RaiseATicketPopOver } from '../../componentExports';
import './RaiseATicketNode.css'
import { RaiseTicketAction } from './ExecutionPlan';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';

interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        executionPlan: ExecutionPlan;
        handleUpdateExecutionPlan: Function;
        ifOrElse?:string
        removeNode?: Function
      id?: string
        typeOfNode:string
      };
}

const RaiseATicketNode: React.FC<CustomNodeProps> = ({data} : CustomNodeProps) => {

  const [isRaiseATicketPopoverOpen, setIsRaiseATicketPopoverOpen] = useState(false);
  const [raiseATicket,setRaiseATicket] = useState<RaiseTicketAction | null>(null)

  const handleEditClick = () => {
    setIsRaiseATicketPopoverOpen(true);
  };
  
  const handleCloseRaiseATicketPopover = ()=>{
    setIsRaiseATicketPopoverOpen(false);
  }

  useEffect(() => {
    const currentRaiseATicket = data?.executionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Raise Ticket') as RaiseTicketAction; //  assuming that in the if block && change the action[0] to dynamic
  //  if(currentRaiseATicket!== raiseATicket){
    setRaiseATicket(currentRaiseATicket);
  //  }
  },[data?.executionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Raise Ticket'),])



    return (
      <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
       
    <Paper
      elevation={3}
      sx={{
        border: '1px solid #0080DC',
        borderLeft: '6px solid #0080DC',
        borderRadius: '8px',
        padding: '8px 16px',
        width: '350px !important',
        backgroundColor: '#fff',
        position: 'relative',
        marginBottom: '16px',
        minHeight:'110px'
      }}
      > 
      <IconButton
        size="small"
        onClick={() => {
          if (data?.removeNode) {  
            data.removeNode(data?.id,data?.ifOrElse,data?.typeOfNode);      
          } 
        }}
        sx={{ position: 'absolute', top: 0, right: 0 }}
        >
        <CloseIcon fontSize="small" sx={{ color: '#666' }} />
      </IconButton>
      <Handle type="target" position={Position.Left} className='transparent-handle' id ='left' />
      <Box display="flex" alignItems="center" marginBottom="1px">
        
      <IconWithTooltip iconClassName={'raise-a-ticket-sidebar-1'} />
  
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            marginBottom:'1px'
          }}
        >
          { 'Raise a Ticket'}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: '#f0f4ff',
          padding: '8px',
          borderRadius: '4px',
        position: 'relative',
          
        }}
      >
       <Box display="flex" flexDirection="column" alignItems="start" height={'36px'}>
         <Box display="flex" alignItems="center" mb={1}>
            <span
          style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#0080DC',
            borderRadius: '50%',
           display: 'inline-block',
            marginRight:'10px'
          }}
          />
          <Typography variant="body2" sx={{ fontSize: '0.775rem', color: '#666' }}>
          Category: <span>{raiseATicket && raiseATicket?.category && <span className='rule__engine__define__execution__selected__parameters'>({raiseATicket?.category})</span>}</span>
           </Typography>
            </Box>
            <Box display="flex" alignItems="center">
            <span
          style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#f0f4ff',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight:'10px'
            
          }}
           />
           <Typography variant="body2" sx={{ fontSize: '0.775rem', color: '#666' }}>
                Sub-Category: <span>{raiseATicket && raiseATicket?.subCategory && <span className='rule__engine__define__execution__selected__parameters'>({raiseATicket?.subCategory})</span>}</span>
              </Typography>
            </Box>
        </Box>
        <IconButton
          size="small"
          onClick={handleEditClick}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <EditIcon fontSize="small" sx={{ color: '#666' }} />
        </IconButton>
      </Box>

            </Paper>
        {data.actionButtonVisible && 
            <Typography
            onClick={() => data.addNodeToCanvas()}
           variant="body2"
           sx={{
             color: '#B0B1B6',
             marginTop: '8px',
             marginLeft: '10px',
             cursor: 'pointer',
             fontSize: '0.875rem',
           }}
         >
           + Add Activity
            </Typography>
         }
      </div>
      {isRaiseATicketPopoverOpen && <SidePopover
         id='rule__engine__execution__plan__action__control__send__notification__popover'
         open={isRaiseATicketPopoverOpen}
         headerText={`Action Control - ${data.ifOrElse === 'If' ? 'Then' : 'Else'}`}
         containerClassName='configure__notification__detail__popover'
         handleClose={()=>{}}
         headerSecondaryText={'Raise a Ticket'}
         horizontalLinePopover={true}
         popperClassName='rule__engine__raise__a__ticket'
         headerClassName={'rule__engine__header__config'}
         isRuleEnginePopOver={true}
         subHeadingIconClassName={'raise-a-ticket-sidebar'}
         ruleEngineSidePopoverOnclose={handleCloseRaiseATicketPopover}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {
                <RaiseATicketPopOver 
                  handleClose={handleCloseRaiseATicketPopover}
                  raiseATicketCallTypeValue={'Complaint'}
                  raiseATicket={raiseATicket}
                  executionPlan={data?.executionPlan}
                  handleUpdateExecutionPlan={data?.handleUpdateExecutionPlan} 
                  ifOrElse={data.ifOrElse??''}    
                />
              }
            </>
        } 
         />
       }

      </>

  );
};

export default RaiseATicketNode;
