/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import './RemoteCommandSidePopOver.css';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppDispatch, useAppSelector, useEffectOnce, } from '../../../globalUtils/globalExports';
import { fetchRuleRemoteCommands, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { FormLabel } from '@mui/material';
import './RemoteCommandSidePopOver.css';
import {  cloneDeep, isEqual } from 'lodash';
import ExecutionPlan, { RemoteCommandAction } from './ExecutionPlan';



interface RemoteCommandPopOverProps{
    
    remoteCommand: null | RemoteCommandAction;
    handleClose : () => void;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    ifOrElse: String;
}

const RemoteCommandSidePopOver = memo(function RemoteCommandSidePopOver({
    remoteCommand,
    handleClose,
    executionPlan,
    handleUpdateExecutionPlan,
    ifOrElse

} : RemoteCommandPopOverProps){

    const dispatch = useAppDispatch(); 
    const {ruleEngineRemoteCommands} = useAppSelector(getReferenceDataReducer);
    const roleId = JSON.parse(localStorage.getItem('rms-user') ?? '')?.roleId
    // const [urlError,setUrlError] =  useState<string|null>(null);
    const [formData,setFormData] = useState<RemoteCommandAction>({
        type: '',
        remoteCommandType: '',
        url: '',
        connectorNumber: null, 
    })


    const [initialFormData,setInitialFormData] = useState<RemoteCommandAction>({
        type: '',
        remoteCommandType: '',
        url: '',
        connectorNumber: null,
  })


    useEffect(()=>{
        dispatch(fetchRuleRemoteCommands(roleId));
    })

    useEffectOnce(()=>{
      if(remoteCommand){
        setInitialFormData(remoteCommand)
        setFormData(remoteCommand);
      }
    })



    // const validateUrl = (url: string)=>{
    //   const urlPattern = new RegExp(
    //     '^https?:\\/\\/' + '([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}' + '(\\/[^\\s]*)?$', 'i' 
    // );
    // return urlPattern.test(url);
    // }

    const isFtpUrlRequired = useMemo(() => {
      return formData && formData.remoteCommandType.length >0 && 
      (['ftp_software_upgrade','ftp_log_upload','ftp_certificate_upgrade','ftp_screen_saver_images_upgrade','ftp_customer_logo_update',
'FTP_SOFTWARE_UPGRADE','FTP_LOG_UPLOAD','FTP_CERTIFICATE_UPGRADE','FTP_SCREEN_SAVER_IMAGES_UPGRADE','FTP_CUSTOMER_LOGO_UPDATE',].indexOf(formData.remoteCommandType) != -1);
    },[formData])

    const disableButton = useCallback(() => {

      if(isEqual(initialFormData,formData))
        return true;
      else{
        let result = true;
        result = result && formData && formData.remoteCommandType.length>0;
        if(isFtpUrlRequired){
          result = result && formData &&  formData.url !== undefined  &&formData.url.length>0 
        }

        
        return !result; 
        }
       }, [formData,initialFormData,])

    


    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                const data = cloneDeep(formData);
                const formattedData: RemoteCommandAction = {
                  type: 'Remote Command',
                  remoteCommandType: data.remoteCommandType.toLowerCase(),
                  url: data.url,
                }
                const executionData = cloneDeep(executionPlan);
                const remoteCommandIndex = executionData?.action?.find((executionDataItem) => executionDataItem.flowType === ifOrElse)?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Remote Command')
                if (remoteCommandIndex!== undefined && remoteCommandIndex !== -1) {
                  executionData.action[ifOrElse === 'If' ? 0 : 1].list[remoteCommandIndex] = formattedData
                } else {
                  executionData?.action?.[ifOrElse === 'If' ? 0: 1].list?.push(formattedData) 
                }
                handleUpdateExecutionPlan(executionData);  
                handleClose();
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,//disableCancelButton(),
            buttonVariant: 'outlined',
          },
        ];
      }, [formData,handleClose,]);

      
    return(
        <>
         <div className='rule__engine__remote__command__side__popover__wrapper'>
            <div>
                <div className='rule__engine__remote__command__side__popover__row__label'>
                    Remote Command*
                </div>
                <div>
                    <SelectDropDown
                        selectDropDownId={'rule__engine__remote__command__popover__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            setFormData({
                                ...formData,
                                remoteCommandType: val?.label,
                                url: ''
                            })
                        }} 
                        selectOptions={ ruleEngineRemoteCommands ?? []}
                        selectValue={{id: formData?.remoteCommandType.toUpperCase(),label : formData?.remoteCommandType.toUpperCase()}}
                     />
                </div>
            </div>

            { isFtpUrlRequired && (<div>
          <div className="rule__engine__remote__command__url__wrapper">
            <FormLabel className={'rms-form-label'} required>
              URL
            </FormLabel>
            <CustomInput
              inputPlaceHolder="Type here"
              handleInputChange={(event)=>{
                // if(event.target.value === ''){
                //   setUrlError(null);
                // } else{
                //       if(validateUrl(event.target.value)){
                //         setUrlError(null);
                //       }
                //       else{
                //         setUrlError('Enter a valid Url');
                //       }
                // }
                const data = {...formData};
                  data.url = event.target.value;
                  setFormData(data);
              }}
              inputValue={formData?.url ?? ''}
              inputFieldId="rule__engine__remote__command__url"
              isRequired={true}
              // errorMessage={urlError}
            />
          </div>
        </div>)}
         </div>
         <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default RemoteCommandSidePopOver;