/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeProvider } from '@mui/material';
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { alarmCardMapper, CustomTooltipWithLabel, FilterLayout, FilterProp, formatRelativeTimeForTable, LinkButton, SidePopover, TableDashboardTheme, TableLayout, useAppSelector, useEffectOnce } from '../../globalUtils/globalExports';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { chargerTypes, fetchChargerVisibleIdReferenceData, getCurrentFiltersValues, getFilterReducer, getFilterSelectedValue, getGlobalReducer, getReferenceDataReducer, getRuleEngineReducer, ruleEngineTypes, setFilterData, setTableColumnPrefrences } from '../../rmsReduxStore/reduxExports';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import { useNavigate } from 'react-router-dom';
import { NoDataComponentRuleEngine } from '../../globalUtils/TableDashboard/TableComponents';
import { IRuleEngineHistory, IRuleEngineTableProps } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineType';
import { cloneDeep } from 'lodash';
import { fetchSingleExecutionSummary, setRuleEngineExecutionSummaryListTableProps } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineCreator';

interface ExecutionSummarySidePopoverProps {
  ruleId : string;
  summaryExecutionFiltersVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleFiltersClosePopOver: ()=> void;
}
const ExecutionSummarySidePopover = memo(function ExecutionSummarySidePopover({ruleId,summaryExecutionFiltersVisible,handleFiltersClosePopOver}: ExecutionSummarySidePopoverProps){

    const tableWrapRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLDivElement>(null)
    // const [tableWrapHeight, setTableWrapHeight] = useState(0)
    const { ruleEngineExecutionSummary,ruleEngineTableLoader,ruleEngineExecutionSummaryListTableProps,ruleEngineExecutionSummaryCount} = useAppSelector(getRuleEngineReducer)
    const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
    const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { screenFilters } = useAppSelector(getFilterReducer)
    const {
      ruleEngineTriggerLevelReferenceData,
      ruleEngineActionControlReferenceData,
      ruleEngineTriggerCategoryReferenceData,
      chargingStationReferenceData,
      chargerVisibleIdReferenceData,
    } = useAppSelector(getReferenceDataReducer);

    const ruleEngineExecutionSummaryListTablePropsRef = useRef(ruleEngineExecutionSummaryListTableProps);
    const ruleEngineExecutionSummaryRef = useRef(ruleEngineExecutionSummary);

    useEffect(() => {
      ruleEngineExecutionSummaryListTablePropsRef.current = ruleEngineExecutionSummaryListTableProps;
    }, [ruleEngineExecutionSummaryListTableProps]);

    // useEffectOnce(()=>{
    //   dispatch(fetchSingleExecutionSummary())
    // })

    const updateSize = useCallback(() => {
      setForceRefreshId(new Date().getTime())
    }, [])

    useEffect(() => {
      const ro = new ResizeObserver(() => {
        updateSize()
      })
      if (tableRef.current != null) {
        ro.observe(tableRef.current)
      }
      window.addEventListener('resize', updateSize)
      return () => {
        window.removeEventListener('resize', updateSize)
      }
    }, [tableRef])

    const ruleEngineExecutionSummaryCleanUpStates = useCallback(() => {
      const action = {
        type: ruleEngineTypes.CLEAR_RULE_ENGINE_EXECUTION_SUMMARY,
      }
      dispatch(action);
      // if (ruleEngineFiltersAppliedFromRedirection) {
      //   onResetButtonClick(false)
      // } 
    }, [])
  
    useEffect(() => {
      window.addEventListener('beforeunload', ruleEngineExecutionSummaryCleanUpStates);
      return (): void => {
        ruleEngineExecutionSummaryCleanUpStates()
        window.removeEventListener('beforeunload', ruleEngineExecutionSummaryCleanUpStates);
      }
    }, [])
  
    // const tableColumnExecutionSummary = useMemo(() => {
    //   return  [
    //     {
    //       field: 'fireTime',
    //       headerName: 'Fire Time',
    //       flex: 0.5,
    //       minWidth: 200,
    //       sortable: true,
    //       hideable: false,
    //       pinnable: true,
    //       renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
    //         const rowData = params?.row;
    //         return rowData?.fireTime ? formatRelativeTimeForTable(new Date(rowData?.fireTime)) : '-';
    //       },
    //     },
    //     // {
    //     //     field: 'ruleName',
    //     //     headerName: 'Rule Name',
    //     //     flex: 1,
    //     //     minWidth: 120,
    //     //     sortable: true,
    //     //     hideable: false,
    //     //     pinnable: false,
    //     //     renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //     //     const rowData = params?.row;
    //     //     return (
    //     //       <div 
    //     //       className="active__alarms__name__wrap"
    //     //       onClick={(e)=>{
    //     //         e.stopPropagation();
    //     //             // toast.info('Feature Coming Soon!')
    //     //       }}>
    //     //           <div className="individual__charger__text">
    //     //           <div className="alarm_name-text">
    //     //           {rowData?.ruleName}
    //     //       </div>
    //     //       </div>
    //     //       </div>
    //     //     )
    //     //   },
    //     // },
    //     {
    //       field: 'category',
    //       headerName: 'Category',
    //       flex: 1,
    //       minWidth: 110,
    //       sortable: true,
    //       renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //         const rowData = params?.row;
    //         if (rowData?.category) {
    //           const severityIcon = rowData?.category?.toUpperCase();
    //           const tooltipText = alarmCardMapper[severityIcon]?.title;
    //           return (
    //           <>
    //           <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
    //           </> 
    //           );
    //         }
    //         else {
    //           return (
    //             <>
    //           <IconWithTooltip iconClassName='trigger-parameters-sidebar' toolTipText={'Parameter'}/>
    //           </>
    //           )
    //         }
    //       },
    //     },
    //     {
    //       field: 'categoryTriggerLevel',
    //       headerName: 'Trigger Level',
    //       flex: 1,
    //       minWidth: 200,
    //       sortable: true,
    //         renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //           const rowData = params?.row;
    //           if (rowData?.category) {
    //             return (
    //             <div>
    //               {rowData?.alarmName}
    //             </div>
    //             );
    //           }
    //           else {
    //           return (
    //             <div>
    //             {rowData?.parameterName}
    //             </div>
    //           )
    //           }
    //         },
    //     },
    //     {
    //         field: 'chargerId',
    //         headerName: 'Charger',
    //         flex: 1,
    //         minWidth: 180,
    //         sortable: true,
    //         renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
    //           const rowData = params?.row;
    //           return (
    //             rowData?.chargerId ?
    //               (<div
    //                 className="individual__charger__wrap"
    //                 onClick={(e) => {
    //                   e?.stopPropagation()
    //                   const action = {
    //                     type: chargerTypes.SET_CHARGER_REDUCER_STATE,
    //                     isChargerTablePropsRetained: true
    //                   }
    //                   dispatch(action)
    //                 }
    //                 }
    //               >
    //                 <LinkButton
    //                   linkBtnId={rowData?.chargerId}
    //                   link={'/charger?chargerId=' + rowData.chargerId}
    //                   btnClassName="individual__charger__text"
    //                   btnText={rowData?.chargerVisibleId}
    //                   linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
    //                 />
    //               </div>) : (<div>{rowData?.chargerVisibleId}</div>)
    //           );
    //         },
    //     },
    //     {
    //       field: 'chargingStationName',
    //       headerName: 'Charging Station',
    //       flex:1,
    //       minWidth: 180,
    //       sortable: true,
    //       renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //         const rowData = params?.row;
    //         return (
    //           <div 
    //           className="active__alarms__name__wrap"
    //           onClick={(e)=>{
    //             e.stopPropagation();
    //                 navigate('/station', { state: { id: rowData?.chargingStationId, mode: 'View', stationName: rowData?.chargingStationName, } });
    //           }}>
    //               <div className="individual__charger__text">
    //               <div className="alarm_name-text">
    //               {rowData?.chargingStationName}
    //           </div>
    //           </div>
    //           </div>
    //         )
    //       },
    //     },
    //     {
    //           field: 'customerName',
    //           headerName: 'Customer',
    //           flex: 1,
    //           minWidth: 180,
    //           sortable: true,
    //           renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //           const rowData = params?.row;
    //           return (
    //             <div 
    //             className="active__alarms__name__wrap"
    //             onClick={(e)=>{
    //               e.stopPropagation();
    //                   navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customerName } });
    //             }}>
    //                         <div className="individual__charger__text">
    //                         <div className="alarm_name-text">
    //                 {rowData?.customerName}
    //             </div>
    //             </div>
    //             </div>
    //           )
    //         },
    //     },
    //     {
    //         field: 'connectorType',
    //         headerName: 'Connector',
    //         flex: 1,
    //         minWidth: 180,
    //         sortable: true,
    //     },
    //     {
    //         field: 'actionStatus',
    //         headerName: 'Action Controls Status',
    //         flex: 0.8,
    //         minWidth: 300,
    //         sortable: true,
    //         renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
    //           const rowData = params?.row
    //           const val= rowData?.actionStatus  ||  {}
    //           const list: string[]=[];
    //           // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //           list.push(...Object.entries(val).map(([key,value])=>(key as string)));
    //           const textVal  = list?.join(', ')
    //           return (
    //               <>
    //             <div>
    //             {textVal}
    //             </div>
    //             </>
    //         ); 
    //         },
    //     },
    //     ]
      
    //   },[ruleEngineExecutionSummary])
    const tableColumnExecutionSummary = useMemo(() => {
      return  [
        {
          field: 'fireTime',
          headerName: 'Fire Time',
          flex: 0.5,
          minWidth: 180,
          sortable: true,
          hideable: false,
          pinnable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
            const rowData = params?.row;
            return rowData?.fireTime ? formatRelativeTimeForTable(new Date(rowData?.fireTime)) : '-';
          },
        },
        // {
        //     field: 'ruleName',
        //     headerName: 'Rule Name',
        //     flex: 1,
        //     minWidth: 120,
        //     sortable: true,
        //     hideable: false,
        //     pinnable: false,
        //     renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
        //     const rowData = params?.row;
        //     return (
        //       <div className='rule__engine__column__normal__text'>
        //         <CustomTooltipWithLabel label={rowData?.ruleName} labelId={'rule__engine__history__rule__name'}/>
        //       </div>
        //     )
        //   },
        // },
        {
          field: 'triggerType',
          headerName: 'Category',
          flex: 1,
          minWidth: 110,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row;
            if (rowData?.category) {
              const severityIcon = rowData?.category?.toUpperCase();
              const tooltipText = alarmCardMapper[severityIcon]?.title;
              return (
              <>
              <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
              </> 
              );
            }
            else if(rowData?.triggerType === 'Scheduler'){
              return(
                <IconWithTooltip iconClassName={'scheduleTime-sidebar'} toolTipText={'Schedule Time'}/>
              )
            }
            else {
              return (
                <>
              <IconWithTooltip iconClassName='trigger-parameters-sidebar' toolTipText={'Parameter'}/>
              </>
              )
            }
          },
        },
        {
          field: 'triggerLevel',
          headerName: 'Trigger Level',
          flex: 1,
          minWidth: 200,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row;
            let triggerLevel='';
            if(rowData?.triggerType === 'Parameter')
              triggerLevel = rowData?.parameterName;
            else if(rowData?.triggerType === 'Alarm')
              triggerLevel = rowData?.alarmName;
            else if(rowData?.triggerType === 'Scheduler')
              triggerLevel = rowData?.scheduleType + ' Schedule';
              return (
              <CustomTooltipWithLabel label={triggerLevel??''} labelId={'rule__engine__history__dashboard__trigger__level'}/>
              );
          },
        },
        {
            field: 'chargerId',
            headerName: 'Charger',
            flex: 1,
            minWidth: 180,
            sortable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
              const rowData = params?.row;
              return (
                rowData?.chargerId ?
                  (<div
                    className="rule__engine__highlighted__text"
                    onClick={(e) => {
                      e?.stopPropagation()
                      const action = {
                        type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                        isChargerTablePropsRetained: true
                      }
                      dispatch(action)
                    }
                    }
                  >
                    <LinkButton
                      linkBtnId={rowData?.chargerId}
                      link={'/charger?chargerId=' + rowData.chargerId}
                      btnClassName="individual__charger__text"
                      btnText={rowData?.chargerVisibleId}
                      linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
                    />
                  </div>) : (<div 
                  // onClick={() => setIsNoProductTypeDialogOpen(true)}
                  >{rowData?.chargerVisibleId}</div>)
              );
            },
        },
        {
          field: 'chargingStationName',
          headerName: 'Charging Station',
          flex:1,
          minWidth: 180,
          sortable: true,
          renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            const rowData = params?.row;
            return (
              <div 
              className="rule__engine__highlighted__text"
              onClick={(e)=>{
                e.stopPropagation();
                    navigate('/station', { state: { id: rowData?.chargingStationId, mode: 'View', stationName: rowData?.chargingStationName, } });
              }}>
                  <CustomTooltipWithLabel label=  {rowData?.chargingStationName} labelId={'rule__engine__history__charging__station__name'} />
              </div>
            )
          },
        },
        {
              field: 'customerName',
              headerName: 'Customer',
              flex: 1,
              minWidth: 180,
              sortable: true,
              renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
              const rowData = params?.row;
              return (
                <div 
                className="rule__engine__highlighted__text"
                onClick={(e)=>{
                  e.stopPropagation();
                      navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customerName } });
                }}>
                  <CustomTooltipWithLabel label= {rowData?.customerName} labelId={'rule__engine__history__customer__name'} />
                    
                </div>
              )
            },
        },
        {
            field: 'connectorType',
            headerName: 'Connector',
            flex: 1,
            minWidth: 180,
            sortable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel,Date>): ReactNode => {
              const rowData = params?.row;
              return rowData?.connectorType ? <CustomTooltipWithLabel label={rowData?.connectorType} labelId={'rule__engine__history__connector'}/>  : '-';
            },
      
        },
        {
            field: 'actionStatus',
            headerName: 'Action Controls Status',
            flex: 0.8,
            minWidth: 300,
            sortable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
              const rowData = params?.row
              const val= rowData?.actionStatus  ||  {}
              const list: string[]=[];
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              list.push(...Object.entries(val).map(([key,value])=> (key as string)));
              const textVal  = list?.join(', ')
              const toolTipText : string[]=[]
              Object.entries(val).map(([key,value])=> (toolTipText.push(key+': '+value)))
              const status = Object.values(rowData?.actionStatus).map(status => status === 'Completed').every(Boolean);
              return (
                <div className='rule__name__status'>
                    <IconWithTooltip iconClassName={ status ? 'status__active__user__icon' : 'status__inactive__user__icon' } toolTipText={toolTipText.join(', ')}/>
                    {textVal}
                </div>
              //  <CustomTooltipWithLabel label={`${textVal}`} labelId={'rule__engine__history__action__control__status'} />
            ); 
            },
        },
        ]
      
      },[ruleEngineExecutionSummary])

    const handleColumnPrefrenceChange = useCallback(async (operation,data)=>{
      await dispatch(setTableColumnPrefrences('executionSummaryRuleEngineForm', operation, data))
    },[])

    const errorToast = (message: string)=> toast.error(message);

    const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => { 

      if (isGlobal && filterKey === 'customer') {
        const reqBody = {
          customerIds: val?.filter((item) => item?.id === 'all')?.length > 0 ? ['all'] : val?.map((customer) => customer?.id)
        }
        dispatch(fetchChargerVisibleIdReferenceData(reqBody))
      }
      

      const res = await setFilterData(val, filterKey, 'executionSummaryRuleEngineForm', 'SET', isGlobal ? isGlobal : false,false)(dispatch)
      if (res?.message === 'Action dispatched successfully') {
      const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'executionSummaryRuleEngineForm', false);
        const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
        const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
    
        if (startDate && endDate) {
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        if (differenceInDays > 6) {
          errorToast('Date range exceeded more than 5 days!!')
          return
        }
        } else if (startDate && !endDate) {
          errorToast('Please select date range of 5 days!!')
        return
        }

        if (ruleEngineExecutionSummaryListTablePropsRef?.current) {
          await layoutCallbackExecutionSummary(1, ruleEngineExecutionSummaryListTablePropsRef?.current?.pageSize, ruleEngineExecutionSummaryListTablePropsRef?.current?.view, ruleEngineExecutionSummaryListTablePropsRef?.current?.sortBy, ruleEngineExecutionSummaryListTablePropsRef?.current?.order, res?.filters, ruleEngineExecutionSummaryListTablePropsRef?.current?.searchTerm)
          }
      }
    }, [ruleEngineExecutionSummaryRef?.current,ruleEngineExecutionSummaryListTablePropsRef?.current])

    const getTableRows = useCallback((): IRuleEngineHistory[] => {
      return ruleEngineExecutionSummary !== null && ruleEngineExecutionSummary?.length > 0 ? ruleEngineExecutionSummary : [];
    }, [ruleEngineExecutionSummary]);

    const layoutCallbackExecutionSummary = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm)=>{
      const tableProps: IRuleEngineTableProps = cloneDeep(ruleEngineExecutionSummaryListTablePropsRef.current)
      const filters = filterData ? filterData : getCurrentFiltersValues('executionSummaryRuleEngineForm');

      await dispatch(fetchSingleExecutionSummary({
        view: view,
        sortBy: field || ruleEngineExecutionSummaryListTablePropsRef?.current?.sortBy,
        order: order || ruleEngineExecutionSummaryListTablePropsRef?.current?.order,
        pageSize,
        pageNumber,
        filters: filters,
        ruleId:  ruleId ?? null,
        searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ruleEngineExecutionSummaryListTablePropsRef?.current?.searchTerm
      }));


      if (tableProps) {
        tableProps.view = view ;
        tableProps.sortBy = field || ruleEngineExecutionSummaryListTablePropsRef?.current?.sortBy;
        tableProps.order = order || ruleEngineExecutionSummaryListTablePropsRef?.current?.order;
        tableProps.pageNumber = pageNumber;
        tableProps.pageSize = pageSize;
        if (searchTerm != null && searchTerm != undefined) {
          tableProps.searchTerm = searchTerm;
        }
      }
      await dispatch(setRuleEngineExecutionSummaryListTableProps(tableProps))
      
    },[ruleEngineExecutionSummaryListTablePropsRef?.current])

    const onResetButtonClick = useCallback(async () => {
      const res = await setFilterData(null, '', 'executionSummaryRuleEngineForm', 'CLEAR', false, false)(dispatch);
      if (res?.message === 'Action dispatched successfully') {
        if (ruleEngineExecutionSummaryListTablePropsRef?.current ) {
          await layoutCallbackExecutionSummary(1, ruleEngineExecutionSummaryListTablePropsRef?.current?.pageSize, ruleEngineExecutionSummaryListTablePropsRef?.current?.view, ruleEngineExecutionSummaryListTablePropsRef?.current?.sortBy, ruleEngineExecutionSummaryListTablePropsRef?.current?.order, res?.filters, ruleEngineExecutionSummaryListTablePropsRef?.current?.searchTerm)
        }
      }
      // setRedirectedStateForChargerFilters(false)(dispatch)
    }, [ruleEngineExecutionSummaryListTablePropsRef?.current,])

    const filterFooterBtns = useMemo(() => {
      return [
        {
  
          buttonText: 'Reset',
          buttonId: 'Reset__execution__summary',
          btnClassName: 'secondary__btn',
          handleClick: (): Promise<void> => onResetButtonClick(),
          isDisabled: false,
          buttonVariant: 'outlined',
        },
      ];
    }, [onResetButtonClick]);
  

    const ExecutionSummaryFilterProps: FilterProp[] = useMemo(() => {
      const filterSetOnValue = getFilterSelectedValue('fireTimeRangeInterval', 'executionSummaryRuleEngineForm', false);
      const ruleEngineHistoryFilter : FilterProp[]  =
          [
            {
              filterLabel: 'Charging Stations',
              filterType: 'dropdown',
              filterId: 'chargingStationId',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('chargingStationId', 'executionSummaryRuleEngineForm', false),
                selectOptions:    chargingStationReferenceData?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'chargingStationId', false)
                },
                selectDropDownId: 'ruleEngine-history--charging-stations--filter-dropdown',
                isMultiSelect: true
              }
            },
            {
              filterLabel: 'Chargers',
              filterType: 'dropdown',
              filterId: 'chargerId',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('chargerId', 'executionSummaryRuleEngineForm', false),
                selectOptions: chargerVisibleIdReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'chargerId', false)
                },
                selectDropDownId: 'ruleEngine-history-chargerId-filter-dropdown',
                isMultiSelect: true
              }
            },
            // {
            //   filterLabel: 'Rule Name',
            //   filterType: 'dropdown',
            //   filterId: 'ruleEngineRuleName',
            //   filterDropdownProps: {
            //     selectValue: getFilterSelectedValue('ruleEngineRuleName', 'executionSummaryRuleEngineForm', false),
            //     selectOptions:  ruleEngineRuleNameReferenceData ?? [],
            //     handleSelectOptionChange: (val): void => {
            //       // handleFilterDataChange(val, 'ruleEngineRuleName', false)
            //     },
            //     selectDropDownId: 'ruleEngineHistory-ruleEngineRuleName-filter-dropdown',
            //     isMultiSelect: true
            //   }
            // },
            {
              filterLabel: 'Trigger Category',
              filterType: 'dropdown',
              filterId: 'triggerCategory',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('triggerCategory', 'executionSummaryRuleEngineForm', false),
                selectOptions: ruleEngineTriggerCategoryReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'triggerCategory', false)
                },
                selectDropDownId: 'rule-engnie-history-trigger-category-filter-dropdown',
                isMultiSelect: true
              }
            },
            {
              filterLabel: 'Trigger Level',
              filterType: 'dropdown',
              filterId: 'triggerLevel',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('triggerLevel', 'executionSummaryRuleEngineForm', false),
                selectOptions: ruleEngineTriggerLevelReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'triggerLevel', false)
                },
                selectDropDownId: 'rule-engine-history-trigger-level-filter-dropdown',
                isMultiSelect: true
              }
            },
            {
              filterLabel: 'Action Controls',
              filterType: 'dropdown',
              filterId: 'actionControls',
              filterDropdownProps: {
                selectValue: getFilterSelectedValue('actionControls', 'executionSummaryRuleEngineForm', false),
                selectOptions: ruleEngineActionControlReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFilterDataChange(val, 'actionControls', false)
                },
                selectDropDownId: 'rule-engnie-history-action-controls-filter-dropdown',
                isMultiSelect: true
              }
            },
            {
              filterLabel: 'Firetime Range',
              filterType: 'dateFilter',
              filterId: 'fireTimeRangeInterval',
              width:'100',
              dateFilterProps: {
                datePickerId: 'rule_engine__history__time__stamp__set__at__date__picker',
                datePickerClassName: 'rule_engine__history__time__stamp__cleared__at__date__picker',
                selectsRange: true,
                startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
                endDate: filterSetOnValue?.[1] ?   new Date(filterSetOnValue?.[1]) : null,    
                onChange: (val): void => {
                  handleFilterDataChange(val, 'fireTimeRangeInterval', false)
                },
              }
            },
      ]
      return ruleEngineHistoryFilter
    }, [screenFilters,ruleEngineExecutionSummaryRef?.current,ruleEngineExecutionSummaryListTablePropsRef?.current,chargingStationReferenceData,chargerVisibleIdReferenceData,ruleEngineTriggerCategoryReferenceData,ruleEngineTriggerLevelReferenceData,ruleEngineActionControlReferenceData])
    
    return(
        <>
         <ThemeProvider theme={TableDashboardTheme}>
            <div ref={tableWrapRef}>
                <div
                className="rms__table__dashboard__table__wrap"
                // <div style={{ height: 'calc(100dvh - 290px)', display: 'block' }}>
                style={{ height: 'calc(100dvh - 150px)' }}
                >
                    <TableLayout
                    gridColumns={tableColumnExecutionSummary}
                    tableRows={getTableRows()} 
                    layoutView='list'
                    tableId={'rule__summary__execution'}
                    tableClassName={'rule__summary__execution'}
                    listLayoutCallBack={layoutCallbackExecutionSummary}
                    totalCount={ruleEngineExecutionSummaryCount}
                    showLoader={ruleEngineTableLoader}
                    showSkeleton={!ruleEngineExecutionSummary}
                    totalCountText={'Rule'}
                    customNoDataComponent={NoDataComponentRuleEngine}
                    pageNumber={ruleEngineExecutionSummaryListTableProps?.pageNumber}
                    tablePageSize={ruleEngineExecutionSummaryListTableProps?.pageSize}
                    sortField={ruleEngineExecutionSummaryListTableProps?.sortBy}
                    sortOrder={ruleEngineExecutionSummaryListTableProps?.order}
                    refreshId={forceRefreshId}
                    columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'executionSummaryRuleEngineForm')}
                    handleColumnPrefrenceChange={handleColumnPrefrenceChange}     
                    leftPinnedColumns={['fireTime']}         
                    /> 
                </div>
            </div>
        </ThemeProvider>
            { summaryExecutionFiltersVisible && <SidePopover
                  id={'rule__engine__execution__summary__side__filters'}
                  open={summaryExecutionFiltersVisible}
                  containerClassName='manage__rule_engine__filters'
                  handleClose={handleFiltersClosePopOver}
                  headerText={'Add Filters'}
                  // eslint-disable-next-line react/no-children-prop
                  children={ 
                    <FilterLayout 
                    screen={'executionSummaryRuleEngineForm'}
                    requiredReferenceDataCallbacks={['triggerCategory','triggerLevel','actionControls','chargerVisibleId','chargingStationId',] }
                    filters={ExecutionSummaryFilterProps } 
                    handleFilterDataChange={handleFilterDataChange} 
                    excludeGlobalFilters={false} 
                    />
                  }
                  btnsList={filterFooterBtns}
          />
          }
        </>
    )
})

export default ExecutionSummarySidePopover;
