import cache from './InMemoryCache';
import hwAxios from './HWAxios';
import { AxiosResponse } from 'axios';
export class CachedAPI {
  baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

  fetchCacheFunction(apiUrl, cacheTime, key, forceCall?): Promise<AxiosResponse> {
    const fetchFunction = (): unknown => {
      const url = this.baseUrl + apiUrl;
      return hwAxios.get(url);
    };
    return cache.get(key, cache.CacheModule.refData, fetchFunction, cacheTime, forceCall); // 30 hours ttl.
  }
}

const cachedAPI = new CachedAPI();
export default cachedAPI;
