/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import hwaxios from '../api-service/HWAxios';
import { chargerTypes, fetchAlarmSeverityReferenceData, fetchChargerStationReferenceData, fetchChargerStatusReferenceData, fetchCustomerReferenceData, getCurrentFiltersValues, getFilterSelectedValue, setFilterData } from '../reduxExports'
import cachedAPI from '../api-service/CachedAPIs';
import rmsStore from '../rmsStore';
import { cloneDeep } from 'lodash';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: Charger List API
export const fetchChargersData: any = (reqBody: any) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    // // const loaderStartAction: Partial<ChargerAction> = {
    // //   type: chargerTypes.SET_CHARGER_LOADER,
    // //   chargerTableLoader: true
    // // }
    // dispatch(loaderStartAction);
    const searchHash = reqBody?.searchTerm
    const hashAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LIST_REQUEST_HASH,
      chargerListRequestHash: searchHash
    }
    dispatch(hashAction)
    const response = await hwaxios.post(url + '/chargers', reqBody)
    if (searchHash === rmsStore.getState().chargersReducer.chargerListRequestHash) {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_CHARGERS_DATA,
        chargers: response.data?.data?.chargers,
        chargerCount: response.data?.data?.chargerCount,
      }
      dispatch(action)
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_LOADER,
        chargerTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// ADD NEW CHARGER
export const createCharger: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// UPDATE CHARGER
export const updateCharger: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charger', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// EXPORT CHARGER LIST
export const exportToCsvForChargerList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger/export-chargers', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Individual Charger Details API
export const fetchChargerDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => any): Promise<any> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/charger', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_DETAIL_DATA,
      chargerDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_LOADER,
        chargerTableLoader: false
      }
      dispatch(loaderEndAction);
    }
    return response;
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Individual Charger Details API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchIndividualChargerDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/detail', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_INDIVIDUAL_CHARGER_DETAIL_DATA,
      individualChargerDetail: response?.data?.data,
      lastRefreshTime: new Date().toISOString(),

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Info API
export const fetchChargerMasterInfo: any = (reqParams: any, forceCall: boolean = false) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/system-info/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-system-master-info-${reqParams?.chargerId}`, forceCall).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_MASTER_INFO_DATA,
        chargerMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger System Info API
export const fetchChargerSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/system-info', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_SYSTEM_INFO_DATA,
      chargerSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}


// TODO: Charger Connector Current Status API
export const fetchChargerConnectorStatus: any = (reqParams) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/connector/status', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_CONNECTOR_STATUS_DATA,
      chargerConnectorStatus: response?.data?.data?.connectorStatus,
      chargerNetwork: response?.data?.data?.chargerNetwork,
      lastRefreshTime: response?.data?.data?.lastRefreshTime,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Graph Detail API
export const fetchChargerGraphDetail: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => unknown): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/trend', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_GRAPH_DETAIL,
      chargerHistoryGraphData: modifyChargerHistoryGraphData(response?.data?.data, 'SET')
    }

    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Status Logs API
export const fetchChargerConnectorStatusLogs: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/connector-status-logs', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_STATUS_LOGS,
      chargerConnectorStatusLogs: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Uptime Data API

export const fetchChargerUptimeHistory: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/uptime-history', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_UPTIME,
      chargerHistoryUptime: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Charger status Info API
export const fetchChargerStatusMasterInfo: any = (reqParams: any, forceCall: boolean = false) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/status/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-status-master-info-${reqParams?.chargerId}`, forceCall).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_CHARGER_STATUS_MASTER_INFO_DATA,
        chargerStatusMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger System Info API
export const fetchChargerStatusSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/status', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_STATUS_SYSTEM_INFO_DATA,
      chargerStatusSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Charger Settings Info API
export const fetchChargerSettingsMasterInfo: any = (reqParams: any, forceCall: boolean = false) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/setting/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-settings-master-info-${reqParams?.chargerId}`, forceCall).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_CHARGER_SETTINGS_MASTER_INFO_DATA,
        chargerSettingsMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Settings System Info  API
export const fetchChargerSettingsSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/charger/setting', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_SETTINGS_SYSTEM_INFO_DATA,
      chargerSettingsSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_LIST_TABLE_PROPS,
    chargerListTableProps: data,
  };
  dispatch(action);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
export const setRedirectedStateForChargerFilters: any = (data, chargerStatusId?: string, customerId?: string, chargingStationId?: string, screen?: 'dashboard' | 'customers' | 'chargingStations') => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const action = {
        type: chargerTypes.SET_FILTERS_APPLIED_FROM_REDIRECTION,
        chargerFiltersAppliedFromRedirection: data,
      };
      if (screen === 'dashboard') {
        const dashboardSelectedChargerType = getFilterSelectedValue('chargerType', 'dashboard', false)
        if (dashboardSelectedChargerType) {
          await setFilterData(dashboardSelectedChargerType, 'chargerType', 'chargers', 'SET', false)(dispatch)
        }
      }
      if (chargerStatusId === 'Offline' || chargerStatusId === 'Online' || chargerStatusId === 'Faulty' || chargerStatusId === 'Healthy' || customerId || chargingStationId) {
        let severitySelected: Array<string> = [];
        if (customerId) {
          if (!rmsStore.getState().referenceDataReducer?.customerReferenceData) {
            await dispatch(fetchCustomerReferenceData())
          }
          const customer = rmsStore.getState().referenceDataReducer?.customerReferenceData?.find(customer => customer?.id === customerId)
          if (customer) {
            await setFilterData(customer, 'customer', 'chargers', 'SET', true)(dispatch);
          }
        }
        if (chargingStationId) {
          const chargingStationRefData = (rmsStore.getState().referenceDataReducer.chargingStationReferenceData) ? rmsStore.getState().referenceDataReducer?.chargingStationReferenceData : JSON.parse(await dispatch(fetchChargerStationReferenceData()));
          const chargingStation = chargingStationRefData?.find(chargingStation => chargingStation?.id === chargingStationId)
          if (chargingStation) {
            await setFilterData(chargingStation, 'chargingStationId', 'chargers', 'SET', false)(dispatch);
          }
        }

        if (chargerStatusId === 'Faulty') {
          chargerStatusId = 'Online'
          severitySelected.push('Critical')
        } else if (chargerStatusId === 'Healthy') {
          chargerStatusId = 'Online'
          severitySelected = severitySelected.concat(['No Alarm', 'Major', 'Minor'])
        }

        const chargerStatusReferenceDataVal = (rmsStore.getState().referenceDataReducer.chargerStatusReferenceData) ? rmsStore.getState().referenceDataReducer?.chargerStatusReferenceData : JSON.parse(await dispatch(fetchChargerStatusReferenceData()));
        if (!rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData) {
          await dispatch(fetchAlarmSeverityReferenceData())
        }
        const status = chargerStatusReferenceDataVal?.find(chargerStatus => chargerStatus?.id === chargerStatusId)
        const severity = rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData?.filter(severity => severitySelected?.includes(severity?.id))
        if (status) {
          await setFilterData(status, 'chargerStatus', 'chargers', 'SET', false)(dispatch);
        }
        if (severity && severity?.length > 0) {
          await setFilterData(severity, 'alarmStatus', 'chargers', 'SET', false)(dispatch);
        }

      }
      await dispatch(action);
      // Resolve the promise
      resolve('Action dispatched successfully');
    } catch (error) {
      // Reject the promise with the error
      reject(error);
    }
  });
};

// TODO: Update Charger Settings Parameters API
export const updateChargerSettingsParameters: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<unknown> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.put(url + '/charger/setting/configure', reqParams)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
      return response?.data
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchBroadcastMessages: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      broadcastMessageTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/broadcast-messages', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_BROADCAST_MESSAGES,
      chargerBroadcastMessages: response.data?.data?.messages,
      broadcastMessagesCount: response.data?.data?.messageCount,
    }
    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_BROADCAST_MESASGE_LOADER,
        broadcastMessageTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      broadcastMessageTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setBroadcastMessageTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_BROADCAST_MESSAGES_TABLE_PROPS,
    chargerBroadcastMessageTableProps: data,
  };
  dispatch(action);
}

export const fetchParametersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-parameters', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_DATA,

      chargerParameters: response.data?.data?.resultParams,
      chargerParametersCount: response.data?.data?.paramsCount,
    }

    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_LOADER,
        chargerParametersTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerParametersListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_PARAMETERS_LIST_TABLE_PROPS,
    chargerParametersListTableProps: data,
  };
  dispatch(action);
}

export const exportToCsvForParametersList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-parameter/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const createParameters: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-parameter', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const setChargerParametersDevicesListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_PARAMETERS_DEVICES_LIST_TABLE_PROPS,
    chargerParametersDevicesListTableProps: data,
  };
  dispatch(action);
}

export const fetchParametersDevicesData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-parameter/devices', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_DEVICES_DATA,

      chargerParametersDevices: response.data?.data?.devices,
      chargerParametersDevicesCount: response.data?.data?.total,
    }


    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
        chargerParametersDevicesTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchParameterDevicesStatusData: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/charger-parameter/count', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETER_DEVICES_STATUS_COUNT,
      parameterDevicesStatusCount: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchAddParametersListDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsListLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/setting/master/charger-level', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsList: response?.data?.data,

    }



    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
        addParametersDetailsListLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsListLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchAddParameterRefChargingStations: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.get(url + '/ref/charging-station', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGING_STATIONS,
      addParameterRefChargingStations: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchAddParameterRefChargers: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/ref/charger-visible-id', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGERS,
      addParameterRefChargers: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchParametersSettingsSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/setting/values', reqParams)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_SETTINGS_SYSTEM_INFO_DATA,
      parametersSettingsSystemInfo: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const deleteParameter: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/charger-parameter', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}
export const setParametersExpandedToggleIds: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_PARAMETERS_TOGGLE_IDS,
    parametersToggleExpandedIds: data,
  };
  dispatch(action);
};

export const fetchParametersSettingsMasterInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/setting/master-common', reqParams)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA,
      chargerParametersSettingsMasterInfo: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchIndividualParameterDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger-parameter', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_INDIVIDUAL_PARAMETER_DETAIL_DATA,
      individualParameterDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const updateParameters: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charger-parameter', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const exportBulkChargerUploadTemplate: any = () => async () => {
  try {
    const response = await hwaxios.get(url + '/upload-charger-template')
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const fetchPreSignedURLForUpload: any = (reqParams: unknown) => async () => {
  try {
    const response = await hwaxios.post(url + '/pre-singed-url', reqParams)
    return response;

  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const uploadBulkChargers: any = (reqParams: unknown) => async () => {
  try {
    const response = await hwaxios.post(url + '/charger-bulk', reqParams)

    return response;

  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const modifyChargerHistoryGraphData: any = (response: IChargerHistoryGraph, action: 'SET' | 'REMOVE', removeKey?: string, removeKeyType?: 'parameter' | 'connector'): IChargerHistoryGraph => {
  if (action === 'SET') {
    if (!rmsStore.getState().chargersReducer.chargerHistoryGraphData) {
      return response
    }
    const updatedData = cloneDeep(rmsStore.getState().chargersReducer.chargerHistoryGraphData)
    const previousFilterHashes = cloneDeep(rmsStore.getState().chargersReducer.chargerHistoryGraphFilterHashes)
    if (updatedData?.data?.find((item) => item.connectorName === 'Charger' && item.parameterName === 'Alarm') && previousFilterHashes?.includes(response?.filterHash)) {
      updatedData?.data?.push(...(response.data.filter((item) => item.connectorName !== 'Charger')))
    }
    else if (previousFilterHashes?.includes(response?.filterHash)) {
      updatedData?.data?.push(...response.data)
    }
    return updatedData
  }
  if (action === 'REMOVE' && removeKey && removeKeyType) {
    const previousData = cloneDeep(rmsStore.getState().chargersReducer.chargerHistoryGraphData)
    previousData.data = previousData?.data?.filter(((item) => {
      if (removeKeyType === 'parameter') return item.parameterName !== removeKey
      else return item.connectorName !== removeKey
    }))
    return previousData
  }
  return rmsStore.getState().chargersReducer.chargerHistoryGraphData
}

/*  Clear Charger History Graph Data on fresh call as it has been modified 
      and data has been appended on client side for different filter combinations */
export const clearChargerHistoryGraphData: any = () => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const updatedData = cloneDeep(rmsStore.getState().chargersReducer.chargerHistoryGraphData)
  updatedData.data = []
  const action: Partial<ChargerAction> = {
    type: chargerTypes.CLEAR_CHARGER_GRAPH_DETAIL,
    chargerHistoryGraphData: updatedData
  }
  dispatch(action)
  const chargerAction: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_HISTORY_GRAPH_FILTER_HASHES,
    chargerHistoryGraphFilterHashes: []
  }
  dispatch(chargerAction)
}

// FETCH Charger History Graph Detail for all selected HistoryType and Connector Combination
export const fetchChargerGraphDetailBulk: any = (reqParams: any) => async (dispatch): Promise<void> => {
  const filters = getCurrentFiltersValues('chargerPanel', true)
  const remainingFilters = filters?.filter?.((filter) => filter?.key !== 'connectorId' && filter.key !== 'historyType')
  const selectedConnectors = filters?.find((filter) => filter?.key === 'connectorId')
  const selectedHistoryTypes = filters?.find((filter) => filter?.key === 'historyType')
  selectedConnectors?.values?.forEach(connector => {
    selectedHistoryTypes?.values?.forEach(historyType => {
      let filtersToUpdate = cloneDeep(remainingFilters)
      filtersToUpdate = filtersToUpdate?.concat([{ key: 'historyType', values: [historyType] }, { key: 'connectorId', values: [connector] }])
      const filterHash = dispatch(setChargerHistoryGraphFilterHash('SET', historyType, connector))
      dispatch(fetchChargerGraphDetail({
        chargerId: reqParams?.chargerId,
        filterHash: filterHash,
        filters: filtersToUpdate
      }))
    });
  });
}

export const setChargerHistoryGraphFilterHash: any = (action: 'SET' | 'REMOVE', historyType, connectorId, removeKey?: string, removeKeyType?: 'parameter' | 'connector') => (dispatch: (arg0: Partial<ChargerAction>) => void): string | null => {
  let updatedData = cloneDeep(rmsStore.getState().chargersReducer.chargerHistoryGraphFilterHashes)
  let hashValue: string = ''
  const selectedStatType = getFilterSelectedValue('statType', 'chargerPanel', false)?.[0]?.id
  const selectedStatInterval = getFilterSelectedValue('statInterval', 'chargerPanel', false)?.[0]?.id
  const selectedHistoryInterval = getFilterSelectedValue('statInterval', 'chargerPanel', false)?.[0]?.id
  const selectedConnectors = getFilterSelectedValue('connectorId', 'chargerPanel', false)
  if (action === 'SET') {
    hashValue = historyType + '#' + connectorId + '#' + selectedStatInterval + '#' + selectedStatType + '#' + selectedHistoryInterval;
    updatedData?.push(hashValue)
  }
  if (action === 'REMOVE' && removeKey && removeKeyType) {
    if (removeKeyType === 'connector') {
      updatedData = updatedData?.filter(item => !item?.includes(removeKey))
    } else {
      const hashToRemove: string[] = []
      selectedConnectors?.forEach(connector => {
        hashValue = removeKey + '#' + connector?.id + '#' + selectedStatInterval + '#' + selectedStatType + '#' + selectedHistoryInterval;
        hashToRemove?.push(hashValue)
      })
      updatedData = updatedData?.filter(item => !hashToRemove?.includes(item))
    }
  }
  const chargerAction: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_HISTORY_GRAPH_FILTER_HASHES,
    chargerHistoryGraphFilterHashes: updatedData
  }
  dispatch(chargerAction)
  return hashValue
}

// TODO: Delete individual Chargere 
export const deleteCharger: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/charger', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const fetchChargerUpdateCommandsData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-update-commands', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_UPDATE_COMMANDS_DATA,
      chargerUpdateCommands: response.data?.data?.resultCommands ?? [],
      chargerUpdateCommandsCount: response.data?.data?.commandsCount,
    }
    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_LOADER,
        chargerParametersTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerUpdateCommandsListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_UPDATE_COMMANDS_LIST_TABLE_PROPS,
    chargerUpdateCommandsListTableProps: data,
  };
  dispatch(action);
}

export const createChargerUpdateCommands: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-update-command', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}


export const deleteUpdateCommand: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/charger-delete-command', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

//  TODO Redux state creation 
export const fetchIndividualUpdateCommandDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger-update-command', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_INDIVIDUAL_UPDATE_COMMAND_DETAIL_DATA,
      individualUpdateCommandDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchUpdateCommandDevicesStatusData: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/charger-update-command/count', reqParams);
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_UPDATE_COMMAND_DEVICES_STATUS_COUNT,
      updateCommandDevicesStatusCount: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};


export const fetchUpdateCommandDevicesData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-update-command/devices', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_UPDATE_COMMANDS_DEVICES_DATA,
      chargerUpdateCommandDevices: response.data?.data?.devices,
      chargerUpdateCommandDevicesCount: response.data?.data?.total,
    }
    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
        chargerParametersDevicesTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerUpdateCommandDevicesListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_UPDATE_COMMANDS_DEVICES_LIST_TABLE_PROPS,
    chargerUpdateCommandDevicesListTableProps: data,
  };
  dispatch(action);
}

export const exportToCsvForUpdateCommandsList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-update-command/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const updateChargerUpdateCommand: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charger-update-command', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

