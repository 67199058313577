import React from 'react';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography'; 
import './RaiseATicketNode.css' 
import { Handle, Position } from '@xyflow/react';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
// interface PlaceholderNodeProps {
//   data: {};
// }


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThenNode: React.FC<any> = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
    <Handle type="target" className='transparent-handle' position={Position.Left}  />
    <Handle type="source" className='transparent-handle' position={Position.Bottom}  id = 'bottom'/>
    <Handle type="source" className='transparent-handle'  position={Position.Right}  id= 'right' />
      <Box
        sx={{
          position: 'relative', 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width:  80 , 
          height:  34, 
          border: '2px solid #BAB9FC',
          borderRadius: '25px',
        }}
      >
        <IconWithTooltip iconClassName={'set-parameter-sidebar-1'}  />

        <Typography
          sx={{
            fontSize: 14,
            color: '#9e9e9e', // light grey text
            fontFamily: 'Arial, sans-serif',
          }}
        >
          Then
        </Typography>
      </Box>
    </div>
  );
};

export default ThenNode;
