import { ThemeProvider } from '@mui/material/styles';
import React, { useState, useCallback, useMemo, useEffect, memo } from 'react';
import {
  AutoRefresh,
  DashboardHeader,
  DashboardLoader,
  DashboardTheme,
  FilterLayout,
  FilterProp,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '../../globalUtils/globalExports';
import { alarmTypes, dashboardTypes, fetchAlarmCardCountData, fetchAlarmListData, fetchAlarmSeverityReferenceData, fetchConnectivityMediumData, fetchCustomerHealthData, fetchDashboardChargerHealthData, fetchDashboardHeaderData, getAlarmReducer, getCurrentFiltersValues, getDashboardReducer, getFilterReducer, getFilterSelectedValue, getReferenceDataReducer, setFilterData } from '../../rmsReduxStore/reduxExports';
import {
  AlarmSummary,
  ChargerHealthStatus,
  CustomerWiseChargerWorkingStatus,
  headerCardConstants,
} from '../componentExports';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';
import {
  DashboardHeaderSkeleton,
} from '../../globalUtils/SkeletonDesign/SkeletonDesign';
import { Icon, IconWithTooltip } from '../../globalUtils/globalIcons';
import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators';
import ConnectivityMediumStatus from './ConnectivityMediumStatus';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';

const Dashboard = memo(function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pulseAlarmLoader } = useAppSelector(getAlarmReducer);
  const { dashboardHeaderData } = useAppSelector(getDashboardReducer);
  const { chargerTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [showDashboardLoader, setShowDashboardLoader] = useState(false);



  const fetchDashBoardData = useCallback((val, showLoader): void => {
    if (showLoader) {
      setShowDashboardLoader(true)
    }
    const filterWithoutSeverity = val?.filters && val?.filters?.length > 0 && val?.filters?.filter(item => item?.key !== 'severity')
    dispatch(fetchDashboardHeaderData({ filters: filterWithoutSeverity }));
    dispatch(fetchCustomerHealthData({ filters: filterWithoutSeverity }));
    dispatch(fetchAlarmCardCountData({ filters: filterWithoutSeverity }));
    dispatch(fetchAlarmListData({
      ...val,
      pageSize: 5,
      pageNumber: 1
    }));
    dispatch(fetchDashboardChargerHealthData({ filters: filterWithoutSeverity }));
    dispatch(fetchConnectivityMediumData({ filters: filterWithoutSeverity }));
    if (showLoader) {
      setTimeout(() => {
        setShowDashboardLoader(false)
      }, 1)
    }
  }, []);

  useEffectOnce(() => {
    dispatch(fetchAlarmSeverityReferenceData());
  });


  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'dashboard', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      fetchDashBoardData({ filters: res?.filters }, false)
    }
  }, [])

  const dashboardFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Connector Types',
        filterType: 'dropdown',
        filterId: 'chargerType',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerType', 'dashboard', false),
          selectOptions: chargerTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerType', false)
          },
          selectDropDownId: 'dashboard-charger-type-filter-dropdown',
          isMultiSelect: false
        }
      }
    ]
  }, [chargerTypeReferenceData, screenFilters])

  const onRefeshCallBack = useCallback(() => {
    const dashboardCurrentFilters = getCurrentFiltersValues('dashboard');
    fetchDashBoardData({ filters: dashboardCurrentFilters }, false)
  }, [getCurrentFiltersValues])

  const handleHeaderCardClick = useCallback(async (e, card) => {
    e?.stopPropagation();
    if (card?.id === 'cmsConnectivity') return;  // No redirection for CMS Connectivity Card
    setRedirectedStateForChargerFilters(true, card?.filterId, null, null, 'dashboard')(dispatch)
      .then((res) => {
        if (res === 'Action dispatched successfully') {
          navigate(card?.redirectLink, {
            state: { chargerStatusId: card?.filterId }
          });
        }
      })
      .catch((error) => {
        console.error('Error dispatching action:', error); // Handle error
      });
  }, [])

  const dashboardCleanUpStates = useCallback(() => {
    //: Partial<DashboardAction> 
    const dashboardAction = {
      type: dashboardTypes.CLEAR_DASHBOARD_STATE_DATA,
    }
    dispatch(dashboardAction);
    //: Partial<AlarmsAction>
    const alarmAction = {
      type: alarmTypes.CLEAR_ALARM_DATA,
    }
    dispatch(alarmAction);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', dashboardCleanUpStates);
    return (): void => {
      dashboardCleanUpStates()
      window.removeEventListener('beforeunload', dashboardCleanUpStates);
    }
  }, [])

  return (
    <ThemeProvider theme={DashboardTheme}>
      <DashboardLoader handleClose={setShowDashboardLoader} showLoader={showDashboardLoader} />
      <div className="app__dashboard__header-container">
        <div className="app__dashboard__header-left">
          <DashboardHeader header='Dashboard' headerClassName={'main__dashboard__header'} />
          <div className="app__dashboard__header-bottom-auto-refresh-wrap">
            <AutoRefresh
              refreshDate={dashboardHeaderData?.lastRefreshTime ? dashboardHeaderData?.lastRefreshTime : ''}
              containerClassName="dashboard__auto__refresh__component"
              onRefreshCallback={onRefeshCallBack}
              pathName="/dashboard"
              refreshLocation={true}
            />
          </div>
        </div>
        <div className="app__dashboard__header-right">
          <FilterLayout requiredReferenceDataCallbacks={['chargerType']}
            filters={dashboardFilterProps} handleFilterDataChange={handleFilterDataChange} />
        </div>
      </div>

      {!dashboardHeaderData ? (
        <div className="dashboard__header__skeleton__wrap">
          <DashboardHeaderSkeleton />
        </div>
      ) : (
        <div className="app__dashboard__header-cards">
          {headerCardConstants?.map((card, index) => {
            return (
              <div
                key={'card' + index}
                className={'app__dashboard__header-card ' + card?.id}
              >
                <div
                  key={index}
                  className={'card ' + card?.cardClassName}
                  style={{ backgroundColor: card?.bgcolor }}
                  onClick={async (e) => await handleHeaderCardClick(e, card)}>

                  <div className="card__header">
                    <Icon
                      containerClassName={'card__header__icon'}
                      iconClassName={card?.icon}
                    />
                    <div
                      style={{
                        color:
                          card?.bgcolor === '#F8F8F8'
                            ? '#8C8C8C'
                            : 'var(--primary-text-color)',
                      }}
                      className="card__header__text"
                    >
                      {card?.text}
                    </div>
                    {(card?.id === 'onlineChargers' || card?.id === 'cmsConnectivity') && <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={card?.tooltipText} containerClassName='charger__health__status-header-info' />}
                  </div>
                  <div className="card__footer__wrap">
                    <div className="card__header__count">
                      {dashboardHeaderData && formatNumberWithCommas(dashboardHeaderData[card?.id])}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="app__dashboard__body-container">
        <ChargerHealthStatus />
        <AlarmSummary loader={pulseAlarmLoader} />
      </div>

      <div className="app__dashboard__charts__body-container">
        <div className="first-component">
          <ConnectivityMediumStatus />
        </div>
        <div className="second-component">
          <CustomerWiseChargerWorkingStatus />
        </div>
      </div>
    </ThemeProvider>
  );
});

export default Dashboard;
