/* eslint-disable @typescript-eslint/no-explicit-any */
import cachedAPI from '../api-service/CachedAPIs';
import { referenceDataTypes } from '../reduxExports';
import hwaxios from '../api-service/HWAxios';
import access from '../../auth/service/AccessControl';

const url = process.env.REACT_APP_SERVER_BASE_URL

export const fetchRegionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/region', 30 * 60 * 60, 'region-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.name,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_REGION_REFERENCE_DATA,
            regionReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchSoftwarVersionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/software-version', 30 * 60 * 60, 'software-version-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data,
              value: data,
              label: data,
              name: data,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_SOFTWARE_VERSION_REFERENCE_DATA,
            softwareVersionReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchChargerTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/charger-type', 30 * 60 * 60, 'charger-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.name,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_TYPE_REFERENCE_DATA,
            chargerTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        })


      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchCustomerReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<any> => {

      try {
        const response = hwaxios.get(url + '/ref/customer').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.customerId,
              value: data?.customerId,
              label: data?.customerName,
              name: data?.customerName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CUSTOMER_REFERENCE_DATA,
            customerReferenceData: reduxReponse,
          };
          dispatch(action);

          return JSON.stringify(reduxReponse);

        })
        return response
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerStatusReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/charger-status', 30 * 60 * 60, 'charger-status-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_CHARGER_STATUS_REFERENCE_DATA,
        chargerStatusReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchAlarmSeverityReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-severity', 30 * 60 * 60, ' alarm-severity-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ALARM_SEVERITY_REFERENCE_DATA,
            alarmSeverityReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchConfigureNotificationHistoryRuleIdReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-rule', 30 * 60 * 60, ' alarm-rule-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_RULE_ID_CONFIGURE_NOTIFICATION_HISTORY,
            ruleIdConfigurNotificationHistoryReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchAlarmTypeOfNotification: any =
    (allRequired: boolean) =>
      async (
        dispatch: (arg0: Partial<ReferenceDataAction>) => void
      ): Promise<void> => {
        try {
          hwaxios.get(url +`/ref/rule-notification-type?forFilter=${allRequired ?? true}`).then((response) => {
            const reduxReponse = response?.data?.data?.map((data) => {
              return {
                id: data?.id,
                value: data?.id,
                label: data?.name,
                name: data?.name,
              };
            });
  
            const action: Partial<ReferenceDataAction> = {
              type: referenceDataTypes.FETCH_RULE_TYPE_OF_NOTIFICATION,
              typeOfNotificationReferenceData: reduxReponse,
            };
            dispatch(action);
          });
        } catch (error) {
          console.log(error);
  
          throw error;
        }
      };    

export const fetchChargerModelReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/charger-model', 30 * 60 * 60, 'charger-model-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data,
              value: data,
              label: data,
              name: data,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_MODEL_REFERENCE_DATA,
            chargerModelReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };
export const fetchChargerVisibilityReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/visibility-types', 30 * 60 * 60, 'charger-visibility-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data,
              value: data,
              label: data,
              name: data,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_VISIBILITY_REFERENCE_DATA,
            chargerVisibilityReferenceData: reduxReponse,

          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerStationReferenceData: any = () =>
  async (
    dispatch: (arg0: Partial<ReferenceDataAction>) => void
  ): Promise<string> => {
    try {
      const response = cachedAPI.fetchCacheFunction('/ref/charging-station', 30 * 60 * 60, 'charging-station-data').then((response) => {
        const reduxReponse = response?.data?.data?.map((data) => {
          return {
            id: data?.id,
            value: data?.id,
            label: data?.name,
            name: data?.name,
            customerId: data?.customerId
          };
        });

        const action: Partial<ReferenceDataAction> = {
          type: referenceDataTypes.FETCH_CHARGER_STATION_REFERENCE_DATA,
          chargingStationReferenceData: reduxReponse,
        };
        dispatch(action);
        return JSON.stringify(reduxReponse)
      })
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const fetchChargerLocationReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    cachedAPI.fetchCacheFunction('/ref/location', 30 * 60 * 60, 'charging-location-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data,
          value: data,
          label: data,
          name: data,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_CHARGER_LOCATION_REFERENCE_DATA,
        chargerLocationReferenceData: reduxReponse,
      };
      dispatch(action);
    })
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchChargerHistoryTypeReferenceData: any = (reqParams: any) => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.get(url + `/ref/trend-type?chargerId=${reqParams?.chargerId}`)

    const reduxReponse = response?.data?.data?.data?.historyTypes?.map((data) => {
      return {
        id: data?.id,
        value: data?.name,
        label: data?.name,
        name: data?.name,
        defaultOption: data?.defaultOption,
        statType: data?.statType?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        })),
        connectorType: data?.connectorType?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        }))
      };
    });
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_HISTORY_TYPE_REFERENCE_DATA,
      chargerHistoryTypeReferenceData: reduxReponse,
    };
    dispatch(action);
    return reduxReponse
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchChargerHistoryIntervalReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.get(url + '/ref/interval-type')

    const reduxReponse = response?.data?.data?.data?.historyInterval?.map((data) => {
      return {
        id: data?.id,
        value: data?.name,
        label: data?.name,
        name: data?.name,
        defaultOption: data?.defaultOption,
        statInterval: data?.statInterval?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        })),
      };
    });
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_HISTORY_INTERVAL_REFERENCE_DATA,
      chargerHistoryIntervalReferenceData: reduxReponse,
    };
    dispatch(action);
    return reduxReponse
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchAlarmTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-type', 30 * 60 * 60, ' alarm-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ALARM_TYPE_REFERENCE_DATA,
            alarmTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerVisibleIdReferenceData: any =
  (reqBody) =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        hwaxios.post(url + '/ref/charger-visible-id', reqBody).then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.visibleId,
              name: data?.visibleId,
              customerId:data?.customerId,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA,
            chargerVisibleIdReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };


export const fetchUserRoleTypeReferenceData: any =
  (reqParams) =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction(`/ref/role?orgId=${reqParams?.orgId}`, 30 * 60 * 60, `user-roleType-data-${reqParams?.orgId}`).then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              value: item?.id,
              label: item?.name,
              name: item?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_USER_ROLE_TYPE_REFERENCE_DATA,
            userRoleTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };


export const fetchOrgReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        hwaxios.get(url + '/ref/organization').then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              value: item?.id,
              label: item?.name,
              name: item?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ORG_REFERENCE_DATA,
            orgReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchParametersReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/params-master', 30 * 60 * 60, 'parameters-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.userName,
              name: data?.userName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_PARAMETERS_REFERENCE_DATA,
            parametersReferenceData: reduxReponse,
          };
          dispatch(action);
        })

      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchUsersReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        hwaxios.get(url + '/ref/users').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.userName,
              name: data?.userName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_USERS_REFERENCE_DATA,
            userReferenceData: reduxReponse,
          };
          dispatch(action);
        })

      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchOrgTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/org-types', 30 * 60 * 60, 'org-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item,
              value: item,
              label: item,
              name: item,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ORG_TYPE_REFERENCE_DATA,
            orgTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchRolePermissionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/role/permission/master', 30 * 60 * 60, 'role-permission-data').then((response) => {
          const reduxReponse = response?.data?.data;
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ROLE_PERMISSION_DATA,
            rolePermissionReferenceData: reduxReponse
          }
          dispatch(action);
        });
      }
      catch (error) {
        console.log(error);
        throw error;
      }
    }

export const fetchExceptionLogsSeverityReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/log-severity', 30 * 60 * 60, 'exception-logs-severity').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_EXCEPTION_LOGS_SEVERITY_REFERENCE_DATA,
        exceptionLogsSeverityReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchChargerProductTypeReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/product-type', 30 * 60 * 60, 'charger-product-type-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_CHARGER_PRODUCT_TYPE_REFERENCE_DATA,
        chargerProductTypeReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchAlarmRules: any = (allRequired: string) => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction(`/ref/rule-alarm-type?forFilter=${allRequired ? allRequired : 'true'}`, 30 * 60 * 60, 'rule-alarm-type').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          severity: data?.severity,
          name: data?.name,
          label: data?.name,
          value: data?.name
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ALARM_TYPES,
        alarmNameReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};      

export const fetchChargerRuleEngineTriggerCategoryReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-engine-trigger', 30 * 60 * 60, 'charger-rule-engine-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });
      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_TRIGGER_CATEGORY_REFERENCE_DATA,
        ruleEngineTriggerCategoryReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const fetchChargerRuleEngineTriggerLevelReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-engine-filter-param', 30 * 60 * 60, 'charger-rule-engine-trigger-level-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_TRIGGER_LEVEL_REFERENCE_DATA,
        ruleEngineTriggerLevelReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const fetchChargerRuleEngineActionControlReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-engine-action', 30 * 60 * 60, 'charger-rule-engine-action-control-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_ACTION_CONTROL_REFERENCE_DATA,
        ruleEngineActionControlReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const fetchChargerRuleEngineScheduleReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-engine-schedule', 30 * 60 * 60, 'charger-rule-engine-schedule-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_SCHEDULE_REFERENCE_DATA,
        ruleEngineScheduleReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};    
export const fetchChargerRuleEngineStatusReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-engine-live-status', 30 * 60 * 60, 'charger-rule-engine-status-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_STATUS_REFERENCE_DATA,
        ruleEngineStatusReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};    
export const fetchTriggerPoitnParameterConditionData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<any> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/rule-engine-param', 30 * 60 * 60, 'rule-engine-param').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {            
            return {
              id: data?.id,
              value: data?.name,
              label: data?.name,
              name: data?.name,
              dataType: data?.dataType,
              ...data
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_TRIGGER_POINT_PARAMETER_CONDITION_REFERENCE_DATA,
            ruleEngineTriggerPointParameterReferenceData: reduxReponse,
          };
          dispatch(action);

        })
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchRuleEngineParamConditionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<any> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/rule-engine-param-condition', 30 * 60 * 60, 'rule-engine-param-condition').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {            
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
              ...data
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_RULE_ENGINE_PARAM_CONDITION,
            ruleEngineParamConditionReferenceData: reduxReponse,
          };
          dispatch(action);

        })
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchRuleEngineParamConditionOperatorReferenceData: any =
() =>
async (
  dispatch: (arg0: Partial<ReferenceDataAction>) => void
): Promise<any> => {

  try {
    cachedAPI.fetchCacheFunction('/ref/rule-engine-operator', 30 * 60 * 60, 'rule-engine-operator').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {            
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
          ...data
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_PARAM_OPERATOR,
        ruleEngineParamOperatorReferenceData: reduxReponse,
      };
      dispatch(action);

    })
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export const fetchRecipientListReferenceData: any =
  (reqParams) =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction(`/ref/recipient/list?orgId=${reqParams?.orgId}`, 30 * 60 * 60, `user-roleType-data-${reqParams?.orgId}`).then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              value: item?.userName,
              label: item?.userName,
              name: item?.userName,
              ...item
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_RULE_ENGINE_ACTION_CONTROL_SEND_NOTIFICATION_RECIPIENT,
            ruleEngineActionControlSendNotificationReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchRuleEngineRaiseATicketCategories: any = () => async(dispatch : (arg0 : Partial<ReferenceDataAction>) => void): Promise<void> => {
try {
  cachedAPI.fetchCacheFunction('/rule-engine/ticket-categories', 30 * 60 * 60, '/rule-engine/ticket-categories').then((response) => {    
    const reduxReponseCategory = response?.data?.data?.map((item) => {
      return {
        id: item?.id,
        value: item?.label,
        label: item?.label,
        name: item?.label,
        ...item
      };
    });
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_CATEGORY,
      ruleEngineRaiseATicketCategoryReferenceData : reduxReponseCategory

    }
    dispatch(action)

  });
} catch (error) {
  console.error(error)
  throw error;
}
}
export const fetchChargerRuleEngineRuleNameReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/rule-list', 30 * 60 * 60, 'charger-rule-engine-rule-name-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.userName,
          name: data?.userName,
        };
      });
      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_RULE_ENGINE_RULE_NAME_REFERENCE_DATA,
        ruleEngineRuleNameReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchRuleEngineRaiseATicketCallType: any = () => async(dispatch : (arg0 : Partial<ReferenceDataAction>) => void): Promise<void> => {
try {
cachedAPI.fetchCacheFunction('/rule-engine/call-type', 30 * 60 * 60, '/rule-engine/call-type').then((response) => {    
  const reduxReponseCategory = response?.data?.data?.map((item) => {
    return {
      id: item?.id,
      label: item?.name,
      value: item?.name,
      name: item?.name,
      ...item
    };
  });

  const action: Partial<ReferenceDataAction> = {  
    type: referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_CALL_TYPE,    
    ruleEngineRaiseATicketCallTypeReferenceData : reduxReponseCategory    

  }      
  dispatch(action)  

}); 
} catch (error) {
console.error(error)
throw error;  
}
}

export const fetchRuleEngineRaiseATicketUrgency: any = () => async(dispatch : (arg0 : Partial<ReferenceDataAction>) => void): Promise<void> => {
try {
cachedAPI.fetchCacheFunction('/rule-engine/ticket-urgency', 30 * 60 * 60, '/rule-engine/ticket-urgency').then((response) => {    
  const reduxReponseCategory = response?.data?.data?.map((item) => {
    return {
      id: item?.id,
      label: item?.label,
      value: item?.label,
      name: item?.label,
      ...item
    };
  });

  const action: Partial<ReferenceDataAction> = {
    type: referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_URGENCY,
    ruleEngineRaiseATicketUrgencyReferenceData : reduxReponseCategory

  }
  dispatch(action)

});
} catch (error) {
console.error(error)
throw error;
}
}

export const fetchRuleEngineSetParameterParams: any = () => async(dispatch : (arg0 : Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
  cachedAPI.fetchCacheFunction('/ref/rule-engine-setting-param', 30 * 60 * 60, '/ref/rule-engine-setting-param').then((response) => {    
    const reduxReponseCategory = response?.data?.data?.map((item) => {
      return {
        id: item?.id,
        label: item?.name,
        value: item?.name,
        name: item?.name,
        ...item
      };
    });
  
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_RULE_ENGINE_SET_PARAMETER_PARAM,
      ruleEngineSetParameterParam : reduxReponseCategory
  
    }
    dispatch(action)
  
  });
  } catch (error) {
  console.error(error)
  throw error;
  }
  }

export const fetchRuleRemoteCommands: any = (roleId) => async(dispatch : (arg0 : Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    cachedAPI.fetchCacheFunction(`/charger/remote-command/master?roleId=${roleId}`, 30 * 60 * 60, '/charger/remote-command/master?roleId').then((response) => {   
    const reduxReponseCategory = response?.data?.data?.map((item) => {
      return {
        id: item.toUpperCase(),
        label: item.toUpperCase(),
        value: item.toUpperCase(),
        ...item
      };
    });
  
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_RULE_ENGINE_REMOTE_COMMANDS,
      ruleEngineRemoteCommands : reduxReponseCategory
  
    }
    dispatch(action)
  
  });
  } catch (error) {
  console.error(error)
  throw error;
  }
  }

  export const fetchAlarmRuleSeverityReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-rule-severity', 30 * 60 * 60, ' alarm-rule-severity-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ALARM_RULE_SEVERITY,
            alarmRuleSeverityReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

    export const fetchAllowedCommandsForRole: any =
    (chargerId) =>
      async (
        dispatch: (arg0: Partial<ReferenceDataAction>) => void
      ): Promise<void> => {
        try {
          const response = await hwaxios.get(url+`/charger/remote-command/master?roleId=${access?.fetchRoleId()}&chargerId=${chargerId}`).then((response) => {
            const action: Partial<ReferenceDataAction> = {
              type: referenceDataTypes.FETCH_ALLOWED_COMMANDS_FOR_ROLE_REFERENCE_DATA,
              allowedCommandsForRoleReferenceData: response?.data?.data,
            };
            dispatch(action);

          });
          return response;
        } catch (error) {
          console.log(error);
          throw error;
        }
      };

  
      export const fetchChargerActiveCertificateReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
        try {
          const response = cachedAPI.fetchCacheFunction('/ref/charger-cert-type', 30 * 60 * 60, 'charger-active-certificate-type-data').then((response) => {
            const reduxReponse = response?.data?.data?.map((data) => {
              return {
                id: data?.id,
                value: data?.id,
                label: data?.name,
                name: data?.name,
              };
            });
      
            const action: Partial<ReferenceDataAction> = {
              type: referenceDataTypes.FETCH_CHARGER_ACTIVE_CERTIFICATE_REFERENCE_DATA,
              chargerActiveCertificateReferenceData: reduxReponse,
            };
            dispatch(action);
            return JSON.stringify(reduxReponse);
          })
      
          return response
        } catch (error) {
          console.log(error);
          throw error;
        }
      };  
