/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { memo, } from 'react'
import ChargerPanelRemoteCommandsComponent from './ChargerPanelRemoteCommands'
import { useAppSelector } from '../../../rmsReduxStore/rmsReduxHooks'
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports'

interface ChargerPanelAdminActions { contentHeight: number }

const ChargerPanelAdminActionsDashboard = memo(

  function ChargerPanelAdminActionsDashboard({ contentHeight }: ChargerPanelAdminActions) {
    const {allowedCommandsForRoleReferenceData} = useAppSelector(getReferenceDataReducer)
    const adminActionsNavList = [
      { buttonText: 'Enable System', buttonIconClassName: 'panel_enable_system_icon', buttonId: 'enable_system' },
      { buttonText: 'Disable System', buttonIconClassName: 'panel_disable_system_icon', buttonId: 'disable_system' },
      { buttonText: 'Enable Connector', buttonIconClassName: 'panel_enable_connector_icon', buttonId: 'enable_connector' },
      { buttonText: 'Disable Connector', buttonIconClassName: 'panel_disable_connector_icon', buttonId: 'disable_connector' },
      { buttonText: 'FTP Log Upload', buttonIconClassName: 'panel_ftp_log_upload_icon', buttonId: 'ftp_log_upload' },
      { buttonText: 'Clear Charge Records', buttonIconClassName: 'panel_clear_charge_records_icon', buttonId: 'clear_charge_records'},
      { buttonText: 'Clear History Alarm Records', buttonIconClassName: 'panel_clear_history_alarm_records_icon', buttonId: 'clear_history_alarm_records'},
      { buttonText: 'Clear Authorization Cache', buttonIconClassName: 'panel_clear_authorization_cache_icon', buttonId: 'clear_authorization_cache'},
      { buttonText: 'Clear Local List', buttonIconClassName: 'panel_clear_local_list_icon', buttonId: 'clear_local_list'},
      { buttonText: 'Clear All Pending Txn Data', buttonIconClassName: 'panel_clear_txn_data_icon', buttonId: 'clear_all_pending_txn_data'},
      { buttonText: 'Clear Next Pending Txn Data', buttonIconClassName: 'panel_clear_txn_data_icon', buttonId: 'clear_next_pending_txn_data'}
    ]
    const allowedCommands = adminActionsNavList?.filter((command) => allowedCommandsForRoleReferenceData?.includes(command?.buttonId))
    return (<ChargerPanelRemoteCommandsComponent contentHeight={contentHeight} commands={allowedCommands}></ChargerPanelRemoteCommandsComponent>)
  }
)

export default ChargerPanelAdminActionsDashboard
