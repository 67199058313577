/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useEffect, useState } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { fetchNotificationTemplate } from '../../rmsReduxStore/reduxExports';
import './HistorySidePopoverPage.css'
import { Icon } from '../../globalUtils/globalIcons';

// Styled Dialog component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface NotificationTemplateProps {
  open: boolean;
  onClose: () => void;
  heading?: string;
  body?: string;
  notificationId?: string | null;
  templateResponse: any;
}


const recepeints = (recepeintsStatus: string[][] | null) => {

  return (
    <div className='notification__template__recepeints'>
      To:
      {recepeintsStatus && recepeintsStatus?.length > 0 && <div className='notification__template__recepeints__list'>
        {recepeintsStatus?.map((item, index) => {
          return (
            <div key={index} className='notification__template__recepeints__list__item'>
              <Icon iconClassName={item?.[1] !== 'Notified' ? 'status__inactive__user__icon' : 'status__active__user__icon'} />
              {item?.[0]}
            </div>
          )
        })}
      </div>}
    </div>
  )

}

const NotificationTemplate: React.FC<NotificationTemplateProps> = ({ open, onClose, templateResponse }) => {

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 4,
          top: 4,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px' }} >
        {templateResponse?.smsMessage && <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} >
          <div style={{ fontWeight: 'bold' }}>SMS</div>
          {recepeints(templateResponse?.smsStatus)}
          {templateResponse?.smsMessage}
        </div>}
        {templateResponse?.smsMessage && templateResponse?.emailMessage &&
          <hr />
        }

        {templateResponse?.emailMessage && <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} >
          <div style={{ fontWeight: 'bold' }}>Email</div>
          {recepeints(templateResponse?.emailStatus)}
          {templateResponse?.emailSubject && <div>{`Subject: ${templateResponse?.emailSubject} `}</div>}
          <div dangerouslySetInnerHTML={{ __html: templateResponse?.emailMessage }}></div>
        </div>}
      </div>

    </BootstrapDialog>
  );
}

export default NotificationTemplate;
