/* eslint-disable @typescript-eslint/ban-types */
import React, {  useCallback, useMemo, useState } from 'react';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppSelector, useEffectOnce } from '../../../globalUtils/globalExports';
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import './IfThenElseSidePopOver.css'
import '../../../rmsAssets/rmsIcons.css'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Icon } from '../../../globalUtils/globalIcons';
import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
import { cloneDeep, isEqual } from 'lodash';
import ExecutionPlan, { ExecutionPlanCondition } from './ExecutionPlan';

interface IfThenElseSidePopOverProps {
    handleClose: Function;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
}

const IfThenElseSidePopOver = (function IfThenElseSidePopOver({
    handleClose,
    executionPlan,
    handleUpdateExecutionPlan
} : IfThenElseSidePopOverProps){

    const { ruleEngineTriggerPointParameterReferenceData, ruleEngineParamConditionReferenceData, ruleEngineParamOperatorReferenceData} = useAppSelector(getReferenceDataReducer);
    const [popOverData,setPopOverData] = useState<ExecutionPlanCondition[]>([{
        index: 0,
        type: '',
        alarmTypeId: '',
        parameterId: '',
        parameterName: '',
        parameterCondition: '',
        parameterConditionName: '',
        parameterValue: [],
        operator: 'AND',
        dataType: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        errorMessage: null,
        errorMessageSecondary: null,
        parameterType: null
    }])
    const [initialPopOverData,setInitialPopOverData] = useState<ExecutionPlanCondition[]>([{
        index: 0,
        type: '',
        alarmTypeId: '',
        parameterId: '',
        parameterName: '',
        parameterCondition: '',
        parameterConditionName: '',
        parameterValue: [],
        operator: 'AND',
        dataType: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        errorMessage: null,
        errorMessageSecondary: null,
        parameterType: null
    }])

    
    useEffectOnce(()=>{
        if(executionPlan.condition && executionPlan?.condition.length > 0){
        setPopOverData(executionPlan?.condition);
        setInitialPopOverData(executionPlan?.condition)
        // handleClick();
        }
    })

    const checkMinMax =(val) =>{
        
        if (!val || typeof val !== 'string') {
            return false;
        }
        try {
            const options = parseDropDownValues(val);
            return (options.length >0 && (options[0].id === 'min' || options[1].id === 'max')) ? true : false;
        } catch (error) {
            return false;
        }
    }
       
    const disableButton = useCallback(() => {
            
             if(isEqual(initialPopOverData,popOverData)){
                return true;
             }
             else{ 
                let result = true;
                popOverData.forEach((item,) => {
                    if (item.parameterCondition.length > 0 && (item.parameterConditionName === 'Between' || item.parameterConditionName === 'Not between')) {
                        result = result && (item.parameterId.length > 0 && item.parameterCondition.length > 0 && item.parameterValue && item.parameterValue.length > 1  && item.parameterValue[0].length>0  && ((item?.errorMessage === '' || item?.errorMessage === null)) && (item?.errorMessageSecondary === '' || item?.errorMessageSecondary === null));
                    } else if((item.dataType === 'String' || item.dataType === 'Boolean' || (item?.possibleValue !==null && !checkMinMax(item?.possibleValue)))){
                        result = result && (item.parameterId.length>0 && item.parameterCondition.length > 0 && item.parameterValue.length > 0 && item.parameterValue[0].length > 0)
                    }
                    else {
                        result = result && (item.parameterId.length > 0 && item.parameterCondition.length > 0 && item.parameterValue.length > 0  && ((item?.errorMessage === null)|| (item?.errorMessage !== undefined && item?.errorMessage?.length == 0)));
                    }
                });
                return !result; 

             }
    }, [popOverData,]);

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                
                
                setPopOverData(popOverData);

                const ConditionData: ExecutionPlanCondition[] = popOverData.map((item, index) => ({
                    index: index,
                    type: item.type,
                    alarmTypeId: '',
                    parameterName: item.parameterName,
                    parameterId: item.parameterId,
                    parameterCondition: item.parameterCondition,
                    parameterConditionName: item.parameterConditionName,
                    parameterValue: item.parameterValue.map((item) => item),
                    operator: item.operator || '',
                    dataType: item.dataType,
                    fieldType: item.fieldType,
                    unit: item.unit,
                    possibleValue: item.possibleValue,
                    errorMessage: item.errorMessage,
                    errorMessageSecondary: item.errorMessageSecondary,
                    parameterType: ''
                  }));

                  const data = cloneDeep(executionPlan);
                  data.condition = ConditionData;
                  handleUpdateExecutionPlan(data);
                //   setExecutionPlan(data)
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]);

    const handleClick = () => {
        const data = cloneDeep(popOverData);
        setPopOverData([...data, {
            parameterCondition: '', operator: 'AND', parameterValue: [], fieldType: '', unit: '', possibleValue: '', errorMessage: null, errorMessageSecondary: null,
            index: 0,
            type: '',
            alarmTypeId: '',
            parameterId: '',
            dataType: '',
            parameterName: '',
            parameterConditionName: '',
            parameterType: null
        }])
    };

    const handleDeleteIconClick = (i: number)=>{
        const data = popOverData.filter((item, index)=>{
            return (index!==i)
        })
        setPopOverData(data); 
    }

    

    const checkValidationsAndShowError = (subParameter, value): string | null => {
        
        const parsedValues = JSON.parse(subParameter?.possibleValue)
        // if (subParameter?.dataType === 'String') {
        //   return parsedValues && value?.length >= parsedValues['max'] ? `Only ${parsedValues['max']} characters are allowed` : null
        if (subParameter?.dataType === 'Integer') {
          return value && !checkValidInteger(value) ? 'Enter a valid Integer value' :  (subParameter?.parameterConditionName === 'Between' || subParameter?.parameterConditionName === 'Not between') ? ((subParameter && subParameter?.parameterValue.length > 1 &&  parseInt(subParameter?.parameterValue[0]) >  parseInt(subParameter?.parameterValue[1])) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.`
           : null
        } else if (subParameter?.dataType === 'Double') {
          return value && !checkValidDecimal(value) ? 'Enter a valid Double value' : (subParameter?.parameterConditionName === 'Between'|| subParameter?.parameterConditionName === 'Not between')? ((subParameter && subParameter?.parameterValue.length > 1 &&  parseFloat(subParameter?.parameterValue[0]) >  parseFloat(subParameter?.parameterValue[1])) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } 
        return null
      }


      const getFilterSelectedValueParameter = (item)=>{
        const selectedParameter  = ruleEngineTriggerPointParameterReferenceData?.filter((refItem)=> item.parameterId === refItem.id);
        return {
            id: selectedParameter?.[0]?.id,
            label : selectedParameter?.[0]?.label,
        }
      }
      const getFilterSelectedParameterCondition = (item)=>{
        const selectedParameter  = ruleEngineParamConditionReferenceData?.filter((refItem)=> item.parameterCondition === refItem.id);
        return {
            id: selectedParameter?.[0]?.id,
            label : selectedParameter?.[0]?.label,
        }
      }


    return(
        <>
        <div className='flow__controls__side__popover__wrapper'>
            <div className='flow__control__side__popover__column__label'>
                <div className='flow__control__side__popover__label flow__control__side__popover__parameter__label'>
                    Parameter*
                </div>
                <div className=' flow__control__side__popover__label flow__control__side__popover__operator__label'>
                    Operator*
                </div>
                <div className='flow__control__side__popover__label flow__control__side__popover__value__label'>
                    Value*
                </div>
            </div>
            <hr className="custom-hr" />
            <div className='flow__control__side__popover__parameters'>
            {
                popOverData!== null && popOverData!== undefined && popOverData?.map((item, i)=>{
                    
                return  <div key = {i}>
                        <div className='flow__control__side__popover__dropdown'>

                            <div className='flow__control__side__popover__parameter__label'>
                            <SelectDropDown 
                            dropDownClassName={'parameter__select'}
                            selectDropDownId={'paramter__name'}
                            selectValue={getFilterSelectedValueParameter(item)}
                            renderToBody={true}
                            handleSelectOptionChange={(val) : void =>{
                            
                            const data = cloneDeep(popOverData);
                            data[i]['parameterId'] = val?.id
                            data[i]['parameterName'] = val?.label;
                            data[i]['parameterCondition'] = '';
                            data[i]['parameterConditionName'] = '';
                            data[i]['dataType'] = val?.dataType;
                            data[i]['fieldType'] = val?.fieldType;
                            data[i]['unit'] = val?.unit;
                            data[i]['possibleValue'] = val?.possibleValue;
                            data[i]['errorMessage'] = '';
                            data[i]['parameterValue'] = [];
                            data[i]['errorMessageSecondary'] = '';
                            data[i]['type'] = 'Parameter';
                            data[i]['parameterType'] = val?.parameterType;
                            
                            setPopOverData(data);
                        

                            }} 
                            selectOptions={ruleEngineTriggerPointParameterReferenceData ?? []}    
                            // isDisabled={i=}    
                            />
                            </div>
                            <div className='flow__control__side__popover__operator__label'>
                            <SelectDropDown 
                            selectDropDownId={'paramter__operator__value'} 
                            handleSelectOptionChange={(val)=>{
                                const data = cloneDeep(popOverData);
                                data[i]['parameterCondition'] = val?.id;
                                data[i]['parameterConditionName'] = val?.label;
                                setPopOverData(data);
                            }} 
                            renderToBody={true}
                            isDisabled={(popOverData[i]['parameterId'] && popOverData[i]['parameterId'].length > 0 ? false : true)}
                            selectOptions={(item.dataType === 'String' || item.dataType === 'Boolean' || (item?.possibleValue !==null && !checkMinMax(item?.possibleValue)) ) ? ruleEngineParamConditionReferenceData?.slice(0,2) ?? [] : ruleEngineParamConditionReferenceData?.slice(2) ?? []} // if possible value does not have min max then is/is not 
                            selectValue={getFilterSelectedParameterCondition(item)}          
                            />
                            </div>

                            <div className='flow__control__side__popover__value__label'>
                            {item?.possibleValue && item?.possibleValue !== null && !checkMinMax(item?.possibleValue) ? (  
                                <div style={{width: '180px'}}>
                                    <SelectDropDown 
                                        // isDisabled={i===0}
                                        selectDropDownId={'flow__control__side__popover__value__dropdown__value'} 
                                        handleSelectOptionChange={(val)=>{
                                            const data = cloneDeep(popOverData);
                                            data[i]['parameterValue'][0] = (val?.label)
                                            setPopOverData(data);
                                        } } 
                                        selectOptions={parseDropDownValues(item?.possibleValue) ?? []}
                                        isMultiSelect={false} 
                                        selectValue={{id : item.parameterValue[0], label : item.parameterValue[0]}}
                                />
                                </div> 
                            ) : 
                            (
                                <>
                                <CustomInput 
                                    inputValue={item.parameterCondition &&  item.parameterCondition.length > 0 ? item.parameterValue[0] : ''} 
                                    inputFieldId={'parameter__value'} 
                                    isRequired={true}   
                                    isDisabled={(popOverData[i]['parameterCondition'] && popOverData[i]['parameterCondition'].length>0 ? false : true)}
                                    handleInputChange={(e) => {
                                        const data = cloneDeep(popOverData);
                                        if(e.target.value !== '' && e.target.value.length>0){
                                            data[i]['parameterValue'][0] =(e.target.value);
                                        // setPopOverData(data); 
                                            data[i]['errorMessage']=null;
                                            data[i]['errorMessageSecondary']=null;
                                            const error = checkValidationsAndShowError(data[i],e.target.value);
                                            data[i]['errorMessage'] = error!==null ? error : null;
                                            setPopOverData(data);  
                                        }else {
                                            data[i]['parameterValue'][0] = '';
                                            data[i]['errorMessage']=null;
                                            setPopOverData(data);
                                        }
                                    }} 
                                    errorMessage={item.errorMessage}
                                />
                                {item?.unit && item?.unit !== 'NA' && (
                                    <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                    {item.unit.slice(0,3)}
                                    </div>
                                )}
                                
                                {popOverData && (popOverData[i]['parameterConditionName'] === 'Between' || popOverData[i]['parameterConditionName'] === 'Not between') && (
                                    <>
                                    <CustomInput 
                                        inputValue={item.parameterCondition && item.parameterCondition.length >0 ? item.parameterValue[1] : ''} 
                                        inputFieldId={'parameter__secondary_value'} 
                                        isRequired={true} 
                                        isDisabled={(item.parameterValue.length === 0)}  
                                        handleInputChange={(e) => {
                                        const data = cloneDeep(popOverData);
                                        if(e.target.value !== '' && e.target.value.length > 0){
                                            data[i]['parameterValue'][1] = (e.target.value);
                                            // setPopOverData(data);
                                            data[i]['errorMessage']=null;
                                            data[i]['errorMessageSecondary']= null;
                                            const error = checkValidationsAndShowError(data[i],e.target.value);
                                            data[i]['errorMessageSecondary'] = error!== null ? error : null;
                                            setPopOverData(data);
                                        }else {
                                            data[i]['parameterValue'].pop();
                                            data[i]['errorMessageSecondary']=null;
                                            setPopOverData(data);
                                        }
                                        // handleErrorMessage(data[i]['parameterValue'])
                                        }} 
                                        errorMessage={item.errorMessageSecondary}
                                    />
                                    {item?.unit && item?.unit !== 'NA' && (
                                        <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                        {item.unit.slice(0,3)}
                                        </div>
                                    )}
                                    </>
                                )}
                                </>
                            )}
                            </div>
                       {
                        i>0 && <Icon iconClassName='rms__delete__icon' onIconClick={() => handleDeleteIconClick(i)}/>

                       }

                        </div>
                        {((i  < popOverData.length-1)) && 
                        <div className='flow__control__side__popover__logical__operator'>
                            <SelectDropDown 
                            selectDropDownId={'flow__control__side__popover__logical__operator'} 
                            handleSelectOptionChange={(val)=>{
                                const data = cloneDeep(popOverData);
                                data[i]['operator'] = val.name;
                                setPopOverData(data);
                            }}
                            selectOptions={ruleEngineParamOperatorReferenceData ?? []}
                            selectValue={{id : item.operator, label : item.operator}}
                            // isDisabled={i === 0}
                            />
                        </div>}
                    </div>
                })
            }
            </div>
            <Button
                startIcon={<AddIcon />}
                sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem',marginTop:'12px' ,display: 'flex', justifyContent: 'flex-start', width:'180px'}}
                onClick={handleClick}
            >
                <div className='flow__control__side__popover__add__condition'>  Add Condition </div>
            </Button>  

        </div>
        <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default IfThenElseSidePopOver;

export const checkValidInteger = (data) =>{
    const integerRegex = /^-?\d+$/;
    return integerRegex.test(data)
}

export const checkValidDecimal = (data) => {
    const decimalRegex = /^-?\d+(\.\d+)?$/;
    return decimalRegex.test(data);
}