/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { ThemeProvider } from '@mui/material'
import type { } from '@mui/x-data-grid-pro/themeAugmentation'
import React, { type FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  CustomBreadCrumbs,
  CustomButton,
  CustomSwitch,
  DashboardHeader,
  FilterLayout,
  FilterProp,
  GridLayout,
  HorizontalNavbar,
  MapLayout,
  SearchBox,
  TableDashboardTheme,
  TableLayout,
  TableSubHeader
} from '../globalExports'
import './TableDashboard.css'
import { IconWithTooltip } from '../globalIcons'
import { SearchBoxPropTypes } from '../SearchBox/SearchBox'
import { BreadCrumbObject } from '../CustomBreadCrumbs/CustomBreadCrumbs'
import { HorizontalNavbarPropTypes } from '../HorizontalNavbar/HorizontalNavbar'
import { HeaderActionBtn } from '../globalHooks'
import { CustomSwitchTypePropTypes } from '../CustomSwitch/CustomSwitch'
export interface TableType {
  tableClassName?: string;
  tableId?: string;
  header?: string;
  searchBoxIncluded?: boolean;
  mobileViewConfiguration?: object;
  tableRows?: Array<any> | null;
  gridColumns?: Array<any> | null;
  searchBoxConfiguration?: SearchBoxPropTypes | null;
  layoutsEnabled?: boolean;
  layouts?: Array<any> | null;
  layoutView?: string;
  refreshId?: number;
  pageSize?: number;
  gridLayoutConfiguration?: GridLayoutConfiguration;
  totalCount: number;
  setLayoutView?: Function;
  gridLayoutCallBack?: Function;
  mapLayoutCallBack?: Function;
  listLayoutCallBack?: Function;
  gridRows?: GridRowDataPropTypes;
  headerActionBtns?: HeaderActionBtn[];
  filterBtn?: HeaderActionBtn[];
  headerText?: string;
  mapDataPoints?: Array<{
    latitude: number;
    longitude: number;
    status: string;
    id: string;
    tooltipData: {
      id: string;
      headerText: string;
      headerOnClick: Function;
      data: Array<{
        icon: string;
        text: string;
      }>;
    };
  }>;
  usedefaultMapCenter?: boolean;
  mapFocusedDataPoint?: MapFocusedDataPoint;
  disableHeader?: boolean;
  disablePaginationFooter?: boolean;
  renderCustomRowComponent?: boolean;
  customRowComponent?: Function;
  customNoDataComponent?: Function;
  handleCallBackFromParent?: boolean;
  showSkeleton?: boolean;
  totalCountText?: string;
  showLoader?: boolean;
  previousPageNo?: number;
  pageNumber?: number;
  tablePageSize?: number;
  layoutsConfiguration?: Array<LayoutConfiguration>;
  excludeGlobalFilters?: boolean;
  filterProps?: FilterProp[];
  individualChargerMapView?: boolean;
  callbackFunction?: Function;
  handleFilterDataChange?: Function,
  breadCrumbs?: BreadCrumbObject[]
  referenceDataCallbacksFilters?: string[]
  navBarconfigurations?: HorizontalNavbarPropTypes
  showRefreshButton?: boolean
  handleRefresh?: Function
  sortField?: string
  sortOrder?: string
  leftPinnedColumns?: string[]
  rightPinnedColumns?: string[]
  columnPrefrences?: ITableColumnPrefrences
  handleColumnPrefrenceChange?: Function
  filterClassName?: string
  screen?: string
  toggleSwitchConfig?: CustomSwitchTypePropTypes
  enableDisableBackgroundColourChange?: boolean
}

interface GridLayoutConfiguration {
  headerFields: Array<HeaderField>;
  tableExcludeFields: Array<any>;
  colors: Array<any>;
  groupField: string;
  sortFields: Array<any>;
}

interface HeaderField {
  key: string;
  title: string;
  icon: string;
}

interface GridRowDataPropTypes {
  // Define the properties of GridRowDataPropTypes here
}
interface MapFocusedDataPoint {
  latitude: number;
  longitude: number;
  id: string;
}

interface LayoutConfiguration {
  toolTipText?: string;
  onIconClick?: Function;
  layout?: string;
  iconClassName?: string;
}


// export type TablePropTypes = PropTypes.InferProps<typeof TableType>

const TableDashboard: FC<TableType> = (props) => {
  const {
    tableId,
    header,
    searchBoxConfiguration,
    searchBoxIncluded,
    tableRows,
    gridColumns,
    gridRows,
    mobileViewConfiguration,
    tableClassName,
    layoutsEnabled,
    layouts,
    gridLayoutConfiguration,
    layoutView,
    gridLayoutCallBack,
    listLayoutCallBack,
    headerActionBtns,
    filterBtn,
    mapDataPoints,
    renderCustomRowComponent,
    customRowComponent,
    customNoDataComponent,
    disableHeader,
    handleCallBackFromParent,
    totalCount,
    showSkeleton,
    totalCountText,
    showLoader,
    mapFocusedDataPoint,
    usedefaultMapCenter,
    pageNumber,
    tablePageSize,
    sortField,
    sortOrder,
    layoutsConfiguration,
    excludeGlobalFilters,
    filterProps,
    individualChargerMapView,
    handleFilterDataChange,
    breadCrumbs,
    referenceDataCallbacksFilters,
    navBarconfigurations,
    showRefreshButton,
    handleRefresh,
    leftPinnedColumns,
    rightPinnedColumns,
    columnPrefrences,
    handleColumnPrefrenceChange,
    filterClassName,
    screen,
    toggleSwitchConfig,
    disablePaginationFooter
  } = props

  const tableRef = useRef<HTMLDivElement>(null)
  const headerWrapRef = useRef<HTMLDivElement>(null)
  const searchWrapRef = useRef<HTMLDivElement>(null)
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const subHeaderRef = useRef<HTMLDivElement>(null)
  const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
  const [tableWrapHeight, setTableWrapHeight] = useState(0)

  const updateSize = useCallback(() => {
    setForceRefreshId(new Date().getTime())
  }, [])

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      updateSize()
    })
    if (tableRef.current != null) {
      ro.observe(tableRef.current)
    }
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])

  useEffect(() => {
    const calculateTableWrapHeight = (): void => {
      if (((headerWrapRef?.current) != null) && ((searchWrapRef?.current) != null) && ((tableWrapRef?.current) != null)) {
        const totalHeight = tableWrapRef?.current?.getBoundingClientRect?.().height
        const headerHeight = headerWrapRef?.current?.getBoundingClientRect?.().height
        const searchHeight = searchWrapRef?.current?.getBoundingClientRect().height
        const subHeaderHeight = subHeaderRef?.current?.getBoundingClientRect?.().height ?? 0
        const tableWrapHeight = totalHeight - headerHeight - searchHeight - subHeaderHeight
        setTableWrapHeight(tableWrapHeight)
      }
    }
    calculateTableWrapHeight()
    window.addEventListener('resize', calculateTableWrapHeight)
    return () => {
      window.removeEventListener('resize', calculateTableWrapHeight)
    }
  }, [])

  return (
    <ThemeProvider theme={TableDashboardTheme}>
      <div className={'rms__table__dashboard ' + tableClassName} ref={tableWrapRef}>
        {individualChargerMapView && (<div className='charger__panel-header-wrap' style={{ marginBottom: '10px' }}>
          <div className='back__btn-container'>
            <CustomBreadCrumbs breadCrumbs={breadCrumbs ?? []} />
          </div>
        </div>)}

        <div className="rms__table__dashboard-header-wrap" ref={headerWrapRef}>
          <DashboardHeader
            headerClassName={'rms__table__dashboard-header'}
            header={header ?? ''}
          />
          {showRefreshButton && !individualChargerMapView && <CustomButton buttonVariant='outlined' buttonId='refresh-btn' buttonIconClassName={!showLoader ? 'rms__dashboard__refresh__icon ' : 'rms__chargerDashboard__refresh__icon'} buttonText={'Refresh'} btnClassName='charger__panel-refresh-btn' handleClick={(e) => {
            e?.stopPropagation();
            handleRefresh?.()
          }} handleKeyPress={() => { }} />}

          {!individualChargerMapView &&
            <div className="rms__table__dashboard-sub-header-wrap">
              {
                toggleSwitchConfig && <div className='toggle__switch__config'>
                  <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={toggleSwitchConfig?.disableSwitchLabelName === 'All' ? 'All chargers\u0027 latest logs' : 'Selected charger\u0027s logs'} containerClassName='dasboard-toggle-switch-info' />
                  <CustomSwitch
                    switchId='charger__form__status-switch'
                    switchClassName='charger__form__status-switch'
                    checked={toggleSwitchConfig?.checked}
                    switchLabelName={toggleSwitchConfig?.switchLabelName}
                    disableSwitchLabelName={toggleSwitchConfig?.disableSwitchLabelName}
                    handleClick={() => { toggleSwitchConfig?.handleClick?.() }}
                    isDisabled={toggleSwitchConfig?.isDisabled ?? false}
                  />
                </div>
              }
              {headerActionBtns && <div className='rms__table__dashboard-sub-header-wrap-buttons'>
                <TableSubHeader headerActionBtns={headerActionBtns} />
              </div>}
              {(Boolean(layoutsEnabled)) && (
                <div className="rms__table__dashboard__view__options__wrap">
                  {layouts !== null && Array.isArray(layouts) && layouts?.length > 0 &&
                    layouts?.map((layout, index) => {
                      const layoutConfig = layoutsConfiguration?.find(
                        (config) => config?.layout === layout
                      )

                      if (layoutConfig != null) {
                        return (
                          <IconWithTooltip
                            key={index}
                            toolTipText={layoutConfig.toolTipText}
                            onIconClick={(event) => layoutConfig?.onIconClick?.(event)}
                            iconClassName={
                              layoutView === layoutConfig.layout
                                ? layoutConfig.iconClassName + ' selected'
                                : layoutConfig.iconClassName ?? ''
                            }
                          />
                        )
                      }
                      return null
                    })}
                </div>
              )}
            </div>}
        </div>

        {!individualChargerMapView && (searchBoxIncluded ?? false) && (
          <div
            className="rms__table__dashboard__search__wrap"
            ref={searchWrapRef}
          >
            {searchBoxConfiguration && <div className="rms__table__dashboard__search__wrap-left">
              <SearchBox
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
                handleSearch={searchBoxConfiguration?.handleSearch}
              />
            </div>}
            {filterProps && <div className={'rms__table__dashboard__search__wrap-right ' + filterClassName} style={{ marginLeft: !searchBoxConfiguration ? 'auto' : 'unset' }}>
              <FilterLayout screen={screen} requiredReferenceDataCallbacks={referenceDataCallbacksFilters ?? []/* ['location', 'status', 'softwareVersion', 'severity', 'chargerVisibleId'] */} // severity is nothing but Alarm Status only but at Charger level.
                filters={filterProps ?? []} handleFilterDataChange={handleFilterDataChange} excludeGlobalFilters={excludeGlobalFilters} />
            </div>}
            {filterBtn && <div className='rms__table__dashboard-sub-header-wrap-buttonsd'>
              <TableSubHeader headerActionBtns={filterBtn} />
            </div>
            }
          </div>
        )}

        {navBarconfigurations && <div ref={subHeaderRef} className="rms__table__dashboard-navbar-wrap">
          <HorizontalNavbar
            selectedNav={navBarconfigurations?.selectedNav}
            onNavClick={(event, val) => { event?.stopPropagation(); navBarconfigurations?.onNavClick?.(val) }}
            navList={navBarconfigurations?.navList}
          />
        </div>}

        <div
          className="rms__table__dashboard__table__wrap"
          style={{ height: `${individualChargerMapView ? tableWrapHeight + 40 : tableWrapHeight - 20}px` }}
        >

          {layoutView === 'list' ? (
            <TableLayout
              tableRows={tableRows}
              gridColumns={gridColumns}
              refreshId={forceRefreshId}
              tableId={tableId}
              tableClassName={tableClassName}
              layoutView={layoutView}
              mobileViewConfiguration={mobileViewConfiguration}
              listLayoutCallBack={listLayoutCallBack}
              renderCustomRowComponent={renderCustomRowComponent}
              disableHeader={disableHeader}
              customRowComponent={customRowComponent}
              handleCallBackFromParent={handleCallBackFromParent || false}
              totalCount={totalCount}
              showSkeleton={showSkeleton}
              totalCountText={totalCountText}
              showLoader={showLoader}
              customNoDataComponent={customNoDataComponent}
              pageNumber={pageNumber}
              tablePageSize={tablePageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              leftPinnedColumns={leftPinnedColumns}
              rightPinnedColumns={rightPinnedColumns}
              columnPrefrences={columnPrefrences}
              handleColumnPrefrenceChange={handleColumnPrefrenceChange}
              disablePaginationFooter={disablePaginationFooter}

            />
          ) : layoutView === 'grid' ? (
            <GridLayout
              tableId={tableId}
              refreshId={forceRefreshId}
              gridRows={gridRows}
              gridColumns={gridColumns}
              gridLayoutConfiguration={gridLayoutConfiguration}
              gridLayoutCallBack={gridLayoutCallBack}
              mobileViewConfiguration={mobileViewConfiguration}
            />
          ) : layoutView === 'map' ? (
            <MapLayout
              key={`map-layout-${JSON.stringify(mapDataPoints)}`}
              usedefaultMapCenter={usedefaultMapCenter}
              mapDataPoints={mapDataPoints}
              mapFocusedDataPoint={mapFocusedDataPoint}
            />
          ) : null}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default TableDashboard
